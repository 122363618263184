import React from 'react'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Form } from '../Form'
import { Input } from '../Input'

export type FormData = {
  currentPassword: string
  newPassword: string
}

export const ChangePasswordForm = (props: FormProps<FormData>) => {
  const { register, errors, onSubmit } = useGetFormHook(props)

  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <Input
        name="currentPassword"
        label="Current password"
        type="password"
        error={errors.currentPassword && errors.currentPassword.message}
        bottomSpace
        fullWidth
        formStyle
        ref={register({ required: 'Required' })}
      />
      <Input
        name="newPassword"
        label="New password"
        type="password"
        error={errors.newPassword && errors.newPassword.message}
        fullWidth
        formStyle
        ref={register({ required: 'Required' })}
      />
    </Form>
  )
}
