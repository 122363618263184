import React, { useState } from 'react'
import { PageLayout } from '../components/PageLayout'
import { CollapsableCard } from '../components/CollapsableCard'
import { IconType } from '../components/Icon'
import styled from '@emotion/styled'
import { Surface } from '../components/Surface'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { PaymentLegalCard } from '../components/PaymentLegalCard'
import { Button } from '../components/Button'

const Wrapper = styled.div`
  display: flex;
  @media(max-width:728px){
    flex-direction: column;
    gap: 2em;
  }
`
const CollapsableWrapper = styled.div`
  width: 100%;
  @media(min-width:728px){
    max-width: 60%;
    padding-right: 20px;
  }
  div:last-child {
    margin-bottom: 0;
  }
`
const SurfaceWrapper = styled(Surface)`
  margin-bottom: 0;
  margin-top: 24px;
  padding: 24px;
`
const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const Profile = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  margin-right: 16px;
`
const BackWrapper = styled.div`
  width: 100%;
  @media(min-width:728px){
    max-width: 40%;
    padding-left: 20px;
  }
`
const BodyText = styled(Body)`
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`
const BodyPay = styled(Body)`
  line-height: 20px;
  font-weight: 600;
`
const HeadingRuppay = styled(Heading)`
  font-size: 24px;
  letter-spacing: 0.95px;
  line-height: 29px;
  color: var(--independence);
  margin-top: 5px;
  margin-bottom: 20px;
  span {
    color: var(--text-secondary);
    opacity: 0.7;
  }
`
const PayNewHeading = styled(Heading)`
  color: var(--primary);
  letter-spacing: 0.92px;
  line-height: 14px;
  cursor: pointer;
  margin-bottom: 24px;
  text-transform: uppercase;
`

export const Legal = () => {
  const [activeLawyer, setActiveLawyer] = useState('visa')
  return (
    <PageLayout title="Legal">
      <Wrapper>
        <CollapsableWrapper>
          <CollapsableCard
            title="Certificate of Incorporation"
            icon={IconType.file}
            iconRight={IconType.download}
            style={{ marginBottom: '12px' }}
          />
          <CollapsableCard
            title="Document to add new member in LLC"
            icon={IconType.file}
            iconRight={IconType.download}
            style={{ marginBottom: '12px' }}
          />
          <CollapsableCard
            title="Indemnification agreement"
            icon={IconType.file}
            iconRight={IconType.download}
            style={{ marginBottom: '12px' }}
          />
          <CollapsableCard
            title="Initial Board Consent"
            icon={IconType.file}
            iconRight={IconType.download}
            style={{ marginBottom: '12px' }}
          />
          <CollapsableCard
            title="Restricted Stock Purchase Agreements"
            icon={IconType.file}
            iconRight={IconType.download}
            style={{ marginBottom: '12px' }}
          />
          <CollapsableCard
            title="Action of Incorporator"
            icon={IconType.file}
            iconRight={IconType.download}
            style={{ marginBottom: '12px' }}
          />
          <CollapsableCard
            title="Pre-filled 83(b) Election and Filing Instructions"
            icon={IconType.file}
            iconRight={IconType.download}
            style={{ marginBottom: '12px' }}
          />
        </CollapsableWrapper>
        <BackWrapper>
          <Heading size={HeadingSize.sm} color={'var(--independence)'}>
            Need a legal consultation?
          </Heading>
          <SurfaceWrapper>
            <ProfileWrapper>
              <Profile src="/images/rich_gora2.png" alt="" />
              <Heading size={HeadingSize.sm} color={'var(--independence)'}>
              Richard Gora 
              </Heading>
            </ProfileWrapper>
            <BodyText size={BodySize.md}>
            With over 13 years of experience in business, corporate, securities, management, litigation, employment and intellectual property,
</BodyText><BodyText>
Richard's specialities include Securities, Equity and Debt Raises, Corporate and Start-Up Counsel, Crowdfunding, and Intellectual Property.

            </BodyText>
            <BodyPay size={BodySize.xs}>You need to pay,</BodyPay>
            <HeadingRuppay>
              <span>$</span>100
            </HeadingRuppay>
            <PaymentLegalCard
              type="visa"
              onClick={() => setActiveLawyer('visa')}
              active={activeLawyer === 'visa'}
            />
            <PaymentLegalCard
              type="master-card"
              onClick={() => setActiveLawyer('master-card')}
              active={activeLawyer === 'master-card'}
            />
            <PayNewHeading size={HeadingSize.xs}>+ pay with a new card</PayNewHeading>
            <Button style={{ padding: '13px', width: '100%' }}>BOOK A CALL WITH RICHARD</Button>
          </SurfaceWrapper>
        </BackWrapper>
      </Wrapper>
    </PageLayout>
  )
}
