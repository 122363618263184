import React from 'react'
import styled from '../lib/styled'
import { Body } from './Typography/Body'
import { Icon, IconType } from './Icon'

const Wrapper = styled.div`
  margin-left: 8px;

  :hover {
    .tooltip {
      opacity: 1;
      pointer-events: all;
    }
  }
`

const ToolTip = styled.div`
  opacity: 0;
  pointer-events: none;

  position: absolute;
  z-index: 2;
  max-width: 226px;
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.elevated};
  border: 1px solid ${props => props.theme.color.primaryBorder};
  border-radius: 4px;
  padding: 8px 12px;

  transition: opacity 0.2s ease-in-out;
`

const InfoIcon = styled(Icon)`
  position: relative;
  top: 2px;
  cursor: pointer;
`

interface InfoToolTipProps {
  content: string
}

export const InfoToolTip = (props: InfoToolTipProps) => {
  return (
    <Wrapper>
      <InfoIcon type={IconType.moreInfo} />
      <ToolTip className="tooltip">
        <Body>{props.content}</Body>
      </ToolTip>
    </Wrapper>
  )
}
