import React, { useState } from 'react'
import { css } from '@emotion/core'
import styled from '../lib/styled'
import { Surface } from './Surface'
import { Body, BodySize } from './Typography/Body'

export enum CardButtonType {
  llc = 'llc',
  corporation = 'corporation',
  delaware = 'delaware',
  wyoming = 'wyoming',
}

const CARD_BUTTON_TYPE = {
  [CardButtonType.llc]: {
    image: '/images/llc.svg',
    selectedImage: '/images/llc-selected.svg',
    title: 'LLC',
    description: 'Owned by Individuals',
  },
  [CardButtonType.corporation]: {
    image: '/images/corporation.svg',
    selectedImage: '/images/corporation-selected.svg',
    title: 'Corporation',
    description: 'Owned by Stakeholders',
  },
  [CardButtonType.delaware]: {
    image: '/images/delaware.svg',
    selectedImage: '/images/delaware-selected.svg',
    title: 'Delaware',
    description: '',
  },
  [CardButtonType.wyoming]: {
    image: '/images/wyoming.svg',
    selectedImage: '/images/wyoming-selected.svg',
    title: 'Wyoming',
    description: '',
  },
}

const Wrapper = styled(Surface)<{ vertical?: boolean; hovered: boolean }>`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  align-items: center;
  padding: 34px 40px;
  margin-bottom: 24px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.white};
  user-select: none;
  transition: 0.2s ease-in-out;

  ${props =>
    props.hovered
      ? css`
          border-color: ${props.theme.color.primary};
          background-color: ${props.theme.color.primaryLight};

          .arrow {
            transform: translateX(0px);
            opacity: 1;
          }

          .selected-image {
            opacity: 1;
          }
        `
      : ''}
`
const ContentWrapper = styled.div<{ vertical?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  width: 100%;
`
const Image = styled.img<{ vertical?: boolean }>`
  ${props => (props.vertical ? 'margin-bottom: 42px;' : '')}
`
const SelectedImage = styled(Image)`
  position: absolute;
  opacity: 0;
  transition: 0.2s ease-in-out;
`
const Arrow = styled.img<{ vertical?: boolean }>`
  ${props =>
    props.vertical
      ? css`
          position: relative;
          top: 13px;
        `
      : ''}
  transition: 0.2s ease-in-out;
  opacity: 0;
  transform: translateX(-30px);
`

interface CardButtonProps {
  type: CardButtonType
  vertical?: boolean
  onClick: () => void
  style?: any
}

export const CardButton = (props: CardButtonProps) => {
  const { image, selectedImage, title, description } = CARD_BUTTON_TYPE[props.type]
  const [hovered, setHovered] = useState(false)
  return (
    <Wrapper
      style={props.style}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={props.onClick}
      hovered={hovered}
      vertical={props.vertical}
    >
      <Image src={image} alt={props.type} vertical={props.vertical} />
      <SelectedImage
        src={selectedImage}
        alt={props.type}
        className="selected-image"
        vertical={props.vertical}
      />
      <ContentWrapper vertical={props.vertical}>
        <div style={props.vertical ? undefined : { marginLeft: 32 }}>
          <Body size={BodySize.lg} bold dark style={{ marginBottom: props.vertical ? 12 : 4 }}>
            {title}
          </Body>
          {description ? <Body size={BodySize.lg}>{description}</Body> : null}
        </div>
        <Arrow
          src="/icons/arrow-right-blue.svg"
          className="arrow"
          alt=""
          vertical={props.vertical}
        />
      </ContentWrapper>
    </Wrapper>
  )
}
