import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Icon, IconType } from './Icon'
import { Skeleton } from './Skeleton'

const Wrapper = styled.div<{ active: boolean }>`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  border-radius: 100px 0 0 100px;
  box-sizing: border-box;
  ${props => (props.active ? `background-color: ${props.theme.color.primaryDark};` : '')}
`
const SidebarItemIcon = styled(Icon)`
  margin-right: 24px;
  width: 20px;
`

interface SidebarItemProps {
  label: string
  to: string
  icon: IconType
  includeRoutes?: string[]
  loading?: boolean
  subitems?: Array<{ to: string; label: string }>
}

export const SidebarItem = (props: SidebarItemProps) => {
  const location = useLocation()
  const open =
    location.pathname.indexOf(props.to) !== -1 ||
    (props.includeRoutes && props.includeRoutes.length
      ? props.includeRoutes.some(path => location.pathname.indexOf(path) !== -1)
      : false)
  const active =
    location.pathname === props.to ||
    (props.includeRoutes && props.includeRoutes.length
      ? props.includeRoutes.indexOf(location.pathname) !== -1
      : false)
  return props.loading ? (
    <Wrapper active={false}>
      <Skeleton primary width="168px" />
    </Wrapper>
  ) : (
    <div>
      <NavLink to={props.to}>
        <Wrapper active={active}>
          <SidebarItemIcon type={props.icon} />
          <Heading light size={HeadingSize.sm} style={{ letterSpacing: '1.33px' }}>
            {props.label}
          </Heading>
        </Wrapper>
      </NavLink>
      {open && props.subitems && props.subitems.length
        ? props.subitems.map(({ to, label }) => {
            const active = location.pathname === to
            return (
              <NavLink to={to} key={label}>
                <Wrapper active={active} style={{ height: 48 }}>
                  <Heading
                    light
                    size={HeadingSize.sm}
                    style={{ marginLeft: 44, letterSpacing: '1.33px' }}
                  >
                    {label}
                  </Heading>
                </Wrapper>
              </NavLink>
            )
          })
        : null}
    </div>
  )
}
