import React from "react";
import { useGetFormHook, FormProps } from '../../../lib/form'
import { Input } from "../../Input";
import { Select } from "../../Select";
import { Form } from '../../Form'
import styled from "@emotion/styled"
import { Controller } from "react-hook-form";
import { DatePicker } from "../../DatePicker";
import { useStartForm } from "../../OperatingAgreement/StartFormProvider";

export type FormData = {
  llc_name: string
  llc_formation_state: string
  llc_primary_address: string
  llc_city:string
  llc_zip_code:number
  llc_operating_agreement_effective_date: Date
};

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1279px){
    flex-direction: column;
    row-gap: 0.75rem;
  }
`

const Wrapper = styled.div`
  @media (max-width: 728px){
    display: flex;
    flex-direction: column;
    gap:1.5em;
  }
`
export const AboutCompanyForm = (props: FormProps<FormData>) => {
  const { register, errors, onSubmit,control } = useGetFormHook(props)
  const startForm = useStartForm();
  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <Wrapper>
      <Input
        label={'Name of your company'}
        error={errors.llc_name && errors.llc_name.message}
        fullWidth
        formStyle
        bottomSpace
        disabled={true}
        defaultValue={'StartGlobal LLC'}
        {...register('llc_name',{ 
          pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" },
          })}
      />
      <Select
          label={'State of LLC Formation'}
          error={errors.llc_formation_state && errors.llc_formation_state.message}
          formStyle
          style={{ width: '100%' }}
          defaultValue={'DELAWARE'}
          disabled={true}
          bottomSpace
          onSelectOption={(value) => {
            setValue('llc_formation_state', value)
          }}
          options={[{ label: 'Delaware', value: 'DELAWARE' }, { label: 'Wyoming', value: 'WYOMING' }]}
          {...register("llc_formation_state", {  })}
        />
      <Input
          label={"LLC's primary business address"}
          error={errors.llc_primary_address && errors.llc_primary_address.message}
          bottomSpace
          fullWidth
          disabled={true}
          defaultValue={'340 S LEMON AVE #3696'}
          formStyle
          {...register("llc_primary_address", { pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" }})}
        />
      <FormWrapper>
        <Input
            label={'City'}
            placeholder={'City'}
            error={errors.llc_city && errors.llc_city.message}
            bottomSpace
            rightSpace
            fullWidth
            disabled={true}
            defaultValue={'Walnut'}
            formStyle
            {...register("llc_city", {pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" } })}
          />
        <Input
          label={'Zip Code'}
            placeholder={'Zip Code'}
            type="text"
            error={errors.llc_zip_code && errors.llc_zip_code.message}
            fullWidth
            disabled={true}
            defaultValue={'91789'}
            formStyle
            {...register("llc_zip_code", { })}
          />
      </FormWrapper>
      <Controller
            name="issuedOn"
            control={control}
            as={
              <DatePicker
                label="When will this operating agreement take effect?"
                error={errors.llc_operating_agreement_effective_date && errors.llc_operating_agreement_effective_date.message}
                bottomSpace
                rightSpace
                formStyle
                fullWidth
                calendarWrapperStyle={{
                  position: 'absolute',
                  left: 0,
                }}
              />
            }
          />
      {/* <Controller
          name="llc_operating_agreement_effective_date"
          render={({  }) => <DatePicker
            label={'When will this operating agreement take effect?'}
            error={errors.llc_operating_agreement_effective_date && errors.llc_operating_agreement_effective_date.message}
            bottomSpace
            rightSpace
            formStyle
            fullWidth
            calendarWrapperStyle={{
              position: 'relative',
              left: 0,
              justifyContent: "flex-start"
            }}
            {...field} />} as={undefined}        /> */}
      </Wrapper>
    </Form>
  );
};
function setValue(arg0: string, value: string) {
  throw new Error("Function not implemented.");
}

