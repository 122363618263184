import React, { useState } from 'react'
import styled from '../lib/styled'
import { Surface } from '../components/Surface'
import { Heading } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from '../components/Icon'
import { RemoveBankAccountModal } from './RemoveBankAccountModal'

const SurfaceBox = styled(Surface)`
  padding: 12px 19px 12px 12px;
  margin-bottom: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`
const BankIcon = styled.div`
  background-color: var(--background);
  border-radius: 8px;
  width: 88px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`
const StripeIcon = styled.img`
  width: 26px;
  height: 26px;
`
const HeadingAccount = styled(Heading)`
  color: var(--independence);
  line-height: 22px;
  margin-bottom: 10px;
  font-size: 18px;
`
const BankDetails = styled(Body)`
  line-height: 19px;
`
const BankDetail = styled.div``
const TrashIcon = styled(Icon)`
  cursor: pointer;
  height: 18px;
  padding: 5px;
`
interface BankAccountDetailsProps {
  title: string
  acNumber: string
  date: string
}
export const BankAccountDetails = (props: BankAccountDetailsProps) => {
  const [removeBankOpen, setRemoveBankOpen] = useState(false)
  return (
    <SurfaceBox>
      <LeftWrapper>
        <BankIcon>
          <StripeIcon src="/images/corp-icon.svg" />
        </BankIcon>
        <BankDetail>
          <HeadingAccount>
            {props.title}
            <span>{props.acNumber}</span>
          </HeadingAccount>
          <BankDetails size={BodySize.md}>
            Connected on <span>{props.date}</span>
          </BankDetails>
        </BankDetail>
      </LeftWrapper>
      <TrashIcon onClick={() => setRemoveBankOpen(true)} type={IconType.trash} />
      <RemoveBankAccountModal
        title={props.title}
        acNumber={props.acNumber}
        open={removeBankOpen}
        close={() => {
          setRemoveBankOpen(false)
        }}
      />
    </SurfaceBox>
  )
}
