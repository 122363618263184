import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from '../lib/styled'
import { Sidebar } from './Sidebar'
import { PaymentProcessingModal } from '../components/Modal'
import MediaQuery from 'react-responsive'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
`
const Body = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  border-radius: 24px 0 0 50px;
  padding-left: 72px;
  padding-right: 143px;
  background-color: ${props => props.theme.color.background};
  @media (max-width: 768px) {
    border-radius: 0px 0 0 0px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const MainLayout = (props: { children: JSX.Element }) => {
  const location = useLocation()
  return (
    <Wrapper>
      <MediaQuery query="(min-width:728px)">
        <Sidebar loading={location.pathname === "/modal-test"} />
      </MediaQuery>
      {/* <Sidebar loading={location.pathname === '/modal-test'} /> */}
      <Body>{props.children}</Body>
      {location.pathname === '/modal-test' ? <PaymentProcessingModal /> : null}
    </Wrapper>
  )
}
