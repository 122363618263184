import React from 'react'
import styled from '../lib/styled'
import { Heading } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { Icon, IconType } from '../components/Icon'
import { RadioButton } from '../components/RadioButton'

const LAWYERS = {
  ryan: {
    title: 'Ryan Roberts',
    fee: '$500 / hr',
    pic: '/images/ryan.png',
    description:
      'Ryan is a corporate attorney who represents startup companies through all phases of their growth process, including over $500 million in venture transactions.',
    achievements: [
      'WizRocket Inc. in its $35,000,000 Series C Financing with Tiger Global and Sequoia',
      'FreshDesk Inc. in its $31,000,000 Series D Financing with Google Capital, Tiger Global and Accel Partners',
    ],
    buttonLabel: 'VIEW FULL PROFILE',
  },
  'start-global': {
    title: 'StartGlobal',
    fee: 'Free',
    pic: '/images/global-start.svg',
    description:
      'This is a standard free option where we have all standard clauses integrated with your preferences without the help from an attorney.',
    achievements: [
      'Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor.',
      'Dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
    ],
    buttonLabel: 'KNOW MORE',
  },
}

const Wrapper = styled.div<{ active: boolean }>`
  background-color: ${props =>
    props.active ? props.theme.color.primaryLight : props.theme.color.white};
  border: 1px solid ${props => (props.active ? props.theme.color.primary : props.theme.color.white)};
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: 12px;
  min-width: 300px;
  padding: 20px;
  height: fit-content;
  min-width: 300px;
  cursor: pointer;

  height: 100%;
  box-sizing: border-box;
`
const Fee = styled.div`
  background-color: ${props => props.theme.color.white};
  border: 2px solid ${props => props.theme.color.primaryLight};
  border-radius: 4px;
  padding: 4px 8px;
  margin-top: 7px;
`

interface LawyerCardProps {
  active: boolean
  type: 'ryan' | 'start-global'
  onClick: () => void
}

export const LawyerCard = (props: LawyerCardProps) => {
  const lawyer = LAWYERS[props.type]
  return (
    <Wrapper active={!!props.active} onClick={props.onClick}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <img src={lawyer.pic} alt={lawyer.title} width="62px" height="62px" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginLeft: 16,
              }}
            >
              <Heading>{lawyer.title}</Heading>
              <Fee>
                <Body bold color="var(--primary)">
                  {lawyer.fee}
                </Body>
              </Fee>
            </div>
          </div>
          <RadioButton selected={props.active} />
        </div>
        <Body style={{ marginTop: 20, marginBottom: 12 }}>{lawyer.description}</Body>
        <Body bold dark style={{ marginBottom: 19 }}>
          Achievements
        </Body>
        {lawyer.achievements.map((achievement, i) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: 19,
            }}
          >
            <Icon type={IconType.doneMedium} style={{ marginRight: 12 }} />
            <Body>{achievement}</Body>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: 31 }}>
        <Body size={BodySize.sm} bold color="var(--primary)">
          {lawyer.buttonLabel}
        </Body>
        <Icon type={IconType.arrowRightBlueSmall} style={{ marginLeft: 13 }} />
      </div>
    </Wrapper>
  )
}
