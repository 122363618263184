
import React, {useState, useEffect, useRef, useContext} from 'react'
import { useForm } from 'react-hook-form'
import styled from '@emotion/styled'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Icon, IconType } from '../components/Icon'
import { OperatingAgreementForm, FormData } from './Forms/OperatingAgreementForm'

const SideCardWrapper = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => `rgba(255,255,255,${props.open ? '0.5' : 0})`};
  ${props => (props.open ? '' : 'pointer-events: none;')};
  transition: background 0.2s ease;
`

const SideCard = styled.div<{ open: boolean }>`
  background-color: white;
  box-shadow: -4px -2px 1.25em 0 rgba(0, 0, 0, 0.06);
  width: 620px;
  height: 100%;
  float: right;
  transition: transform 0.2s ease;
  transform: translateX(${props => (props.open ? '0px' : '640px')});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 728px) {
    width:100%;
  }
`
const SideCardTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5em;
  user-select: none;
`
const SideCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #d4ddeb;
  @media (max-width: 728px) {
    display:none;
  }
`
const ButtonWrap = styled.div`
    display: flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
`
const Button = styled.div<{ selected?: boolean }>`
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 19px;
    ${props => (props.selected ? 'color: var(--white);' : 'color: var(--text-secondary)')};
    ${props => (props.selected ? 'background: var(--primary);' : 'background: var(--white);')};
    ${props => (props.selected ? 'border: 1px solid var(--primary);' : 'border: 1px solid var(--default-border);')};
    border-radius: 1em;
    padding: 0.5em 1em;
    cursor: pointer;
`
const FormWrapper = styled.div<{ disabled: boolean }>`
  padding: 1em 2em;
  overflow: auto;
  ${props => (props.disabled ? 'opacity: 0.5;' : '')}
`
const SaveButton = styled.div`
display: inline-block;
background-color: var(--primary);
border-radius: 6px;
border-radius: 3px;
padding: 11px 17px;
text-align: center;
cursor: pointer;
border: 1px solid var(--primary);
white-space: nowrap;
-webkit-transition: All 0.2s;
transition: All 0.2s;
`
interface OperatingAgreementSideCardProps {
  open: boolean
  close: () => void
  selectedAgreement?: any
  editable:boolean
  createMode:boolean
  confirmationSuccess : boolean
}
export enum Buttons {
    BASIC = 'Basic',
    MEMBERS = 'Members',
    DECISION_MAKING = 'Decision-making',
    CHANGES = 'Changes'
}
const OperatingAgremtnSideCardContent = (props: OperatingAgreementSideCardProps) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(props.selectedAgreement?.status);
    const formHook = useForm<FormData>({
        defaultValues: {
          oaEffectiveDate:''
,          ...(props.selectedAgreement || {}),
        },
      })
    const isEditable = () => {
        return status === undefined || status==='DRAFT';
      }
      const onSubmit = formHook.handleSubmit(values => {
      })
      if(loading) {
       // props.onLoading(true);
      }
    return (
      <>
      {/* during view mode form is disabled and issue is not able to handle members data need to check */}
        <FormWrapper disabled={false}>
          <OperatingAgreementForm
            initialValues={props.selectedAgreement}
            onSubmit={onSubmit}
            formHookProps={formHook}
            disabled={!props.editable}
            open={props.open}
            createMode={props.createMode}
            publicMode={false}
          />
        </FormWrapper>
        {/* <SaveButton onClick={onSubmit}>{loading ? "Saving.." : "Save" } </SaveButton> */}
      </>
    )
  }

export const OperatingAgreementSideCard = (props: OperatingAgreementSideCardProps) => {
    const [showClickToCopy, setShowClicktoCopy] = useState(false);
    const [showCopied, setShowCopied] = useState(false);
    const [payLink, setPaylink] = useState('');
    const [selectedButton, setSelectedButton] = useState(Buttons.BASIC);
    const myRef = React.createRef()

    function onButtonClick(button:Buttons) {
      if(button== Buttons.BASIC){
        setSelectedButton(Buttons.BASIC);
      }
    }
    return (
      <SideCardWrapper open={props.open} onClick={props.close}>
        <SideCard open={props.open} onClick={e => e.stopPropagation()}>
          <div style={{ padding: 32, paddingBottom: 0 }}>
            <SideCardTop>
              <Heading>{props.editable ? 'Edit Agreement' : 'View Agreement' }</Heading>
              <Icon type={IconType.close} style={{ cursor: 'pointer' }} onClick={props.close} />
            </SideCardTop>
          </div>
          <SideCardHeader>
            {!!props.selectedAgreement && 
              <ButtonWrap>
                  {selectedButton == Buttons.BASIC?(<Button selected ={true} onClick={() => onButtonClick(Buttons.BASIC)}>Basic</Button>):(<Button selected ={false}  onClick={() => onButtonClick(Buttons.BASIC)}>Basic</Button>)}
                  {selectedButton == Buttons.MEMBERS?(<Button selected ={true} onClick={() => setSelectedButton(Buttons.MEMBERS)}>Members</Button>):(<Button selected ={false}  onClick={() => setSelectedButton(Buttons.MEMBERS)}>Members</Button>)}
                  {selectedButton == Buttons.DECISION_MAKING?(<Button selected ={true} onClick={() => setSelectedButton(Buttons.DECISION_MAKING)}>Decision-Making</Button>):(<Button selected ={false}  onClick={() => setSelectedButton(Buttons.DECISION_MAKING)}>Decision-Making</Button>)}
                  {selectedButton == Buttons.CHANGES?(<Button selected ={true} onClick={() => setSelectedButton(Buttons.CHANGES)}>Changes</Button>):(<Button selected ={false}  onClick={() => setSelectedButton(Buttons.CHANGES)}>Changes</Button>)}
              </ButtonWrap>
            }
          </SideCardHeader>
          {props.open ? <OperatingAgremtnSideCardContent {...props} /> : null}
        </SideCard>
      </SideCardWrapper>
    )
  }

