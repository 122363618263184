import React from 'react'
import { Button, ButtonProps } from './Button'

interface ArrowButtonProps extends ButtonProps {
  arrowLeftSpace?: number
}

export const ArrowButton = (props: ArrowButtonProps) => (
  <Button {...props}>
    {props.children}
    <img
      src="/icons/arrow-right.svg"
      alt="arrow"
      style={{ marginLeft: props.arrowLeftSpace ? props.arrowLeftSpace : 13 }}
    />
  </Button>
)
