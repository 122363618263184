import React, { useEffect } from 'react'
import anime from 'animejs'
import { useForm } from 'react-hook-form'
import styled from '../../lib/styled'
import { StartFormLayout, StartFormProps } from '../StartFormLayout'
import { AboutCompanyForm, FormData } from '../Forms/AboutCompanyForm'
import { useStartForm } from '../StartFormProvider'

const contentId = 'about-company-content'
const cornerImageId = 'about-company-corner-image'
const cornerImageBuilding1Id = 'about-company-corner-image-building-1'
const cornerImageBuilding2Id = 'about-company-corner-image-building-2'
const cornerImageBuilding3Id = 'about-company-corner-image-building-3'

const BuildingImage = styled.img`
  position: absolute;

  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -o-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
`

export const AboutCompany = (props: StartFormProps<FormData>) => {
  const startForm = useStartForm()
  const formHook = useForm<FormData>({ defaultValues: startForm.aboutCompany })
  const onSubmit = formHook.handleSubmit(values => {
    startForm.setAboutCompany(values)
    console.log(values)
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart',
    })
    setTimeout(() => props.onSubmit(values), 500)
  })
  const goBack = () => {
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart',
    })
    setTimeout(() => props.goBack(), 500)
  }

  useEffect(() => {
    anime
      .timeline({ easing: 'easeOutQuart' })
      .add(
        {
          targets: `#${contentId}`,
          translateY: [50, 0],
          opacity: [0, 1],
          duration: 800,
        },
        300
      )
      .add(
        {
          targets: `#${cornerImageId}`,
          opacity: [0, 1],
          duration: 800,
        },
        300
      )
      .add(
        {
          targets: `#${cornerImageBuilding2Id}`,
          scaleY: [0, 1],
          duration: 200,
        },
        300
      )
      .add(
        {
          targets: `#${cornerImageBuilding3Id}`,
          scaleY: [0, 1],
          duration: 200,
        },
        500
      )
      .add(
        {
          targets: `#${cornerImageBuilding1Id}`,
          scaleY: [0, 1],
          duration: 200,
        },
        700
      )
    return () => {}
  }, []);



  return (
    <StartFormLayout
      title="About your company"
      description="Details about your company’s name and description."
      contentId={contentId}
      onNextClick={onSubmit}
      onPrevClick={goBack}
      sidebarImage={
        <div
          id={cornerImageId}
          style={{ position: 'absolute', left: 70, bottom: 100, zIndex: 1, opacity: 0 }}
        >
          <BuildingImage
            id={cornerImageBuilding1Id}
            src="/images/about-company-corner/building-1.svg"
            alt=""
            style={{ left: 18, bottom: 42 }}
          />
          <BuildingImage
            id={cornerImageBuilding2Id}
            src="/images/about-company-corner/building-2.svg"
            alt=""
            style={{ zIndex: 1, bottom: 0, left: 0 }}
          />
          <BuildingImage
            id={cornerImageBuilding3Id}
            src="/images/about-company-corner/building-3.svg"
            alt=""
            style={{ bottom: 0, left: 36 }}
          />
        </div>
      }
    >
      <AboutCompanyForm onSubmit={onSubmit} formHookProps={formHook} />
    </StartFormLayout>
  )
}
