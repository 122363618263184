import React, { useState } from 'react'
import styled from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { PaymentLegalCard } from './PaymentLegalCard'
import { NewPaymentCard } from './NewPaymentCard'

const RuppayWrapper = styled.div`
  color: var(--text-secondary);
  font-size: 16px;
  letter-spacing: 0.95px;
  line-height: 29px;
  margin-top: 10px;
  margin-bottom: 24px;
`
const RuppaySimbol = styled.span`
  color: var(--text-secondary);
  font-size: 24px;
  letter-spacing: 0.95px;
  line-height: 29px;
  font-weight: 700;
  opacity: 0.7;
`
const Ruppay = styled.span`
  color: var(--independence);
  font-size: 24px;
  letter-spacing: 0.95px;
  line-height: 29px;
  font-weight: 700;
`
const PayNewHeading = styled(Heading)`
  color: var(--primary);
  letter-spacing: 0.92px;
  line-height: 14px;
  cursor: pointer;
  margin: 24px 0;
  text-transform: uppercase;
`

export const PaymentGateway = () => {
  const [activeLawyer, setActiveLawyer] = useState('visa')
  const [showCard, setShowCard] = useState(false)

  return (
    <>
      <Body size={BodySize.xs}>Pay only</Body>
      <RuppayWrapper>
        <RuppaySimbol>$</RuppaySimbol>
        <Ruppay>10</Ruppay>/month
      </RuppayWrapper>
      <PaymentLegalCard
        type="visa"
        onClick={() => setActiveLawyer('visa')}
        active={activeLawyer === 'visa'}
      />
      {showCard ? (
        <NewPaymentCard
          type="new-card"
          onClick={() => setActiveLawyer('new-card')}
          active={activeLawyer === 'new-card'}
        />
      ) : (
        <PayNewHeading
          size={HeadingSize.xs}
          onClick={() => {
            setShowCard(true)
          }}
        >
          + pay with a new card
        </PayNewHeading>
      )}
    </>
  )
}
