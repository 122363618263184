import React, { useState, useRef } from "react";
import useOnClickOutside from "use-onclickoutside";
import styled from "@emotion/styled";
import { Heading, HeadingSize } from "../Typography/Heading";
import { Body, BodySize } from "../Typography/Body";
import { StartSidebar } from "./StartSidebar";
import { StartFormFooter } from "./StartFormFooter";
import { StartStepper } from "./StartStepper";
import { HelpOverlay } from "../HelpOverlay";
import MediaQuery from "react-responsive";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
  background-color: #f6f8fa;
  @media (max-width: 1279px){
    flex-direction: column;
    row-gap: 0.75rem;
    margin-bottom: 2em;
    min-height: 100%;
  }

`;
const Sidebar = styled(StartSidebar)`
  background-color: white;
`;
const LogoWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 1279px){
    margin: auto;
    background-color: var(--primary);
    width: 100%;
    padding:1em;
    top: 0;
    left: 0;
    position: fixed;
    z-index:999;
  }
`;


const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-left: 48px;
  @media (max-width: 1279px){
    padding: 0;
    //margin-top: calc(40px + 2em);//40px height of logo, 1em padding top+bottom
    //padding: 1em;
    min-height:100%;
  }
`;
const ContentInnerWrapper = styled.div`
  max-width: 650px;
  padding-bottom: 50px;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1279px){
    max-width: 100vw;
    padding: 0;
    justify-content: flex-start;
    min-height: 100%;
  }
`;
const HelpButtonWrapper = styled.div`
  position: absolute;
  top: 64px;
  right: 64px;
  cursor: pointer;
`;
const ContentInner = styled.div`
  // padding-top: 124px;
  @media (max-width: 1279px){
    padding : 1rem 0rem;
  }
`;
const Description = styled(Body)`
  margin-top: 16px;
  margin-bottom: 52px;
`;
const BottomCornerImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
`

export interface StartFormProps<FD> {
  onSubmit: (values: FD) => void;
  goBack: () => void;
}

interface StartFormLayout {
  title: string;
  description: string;
  contentId?: string
  logoId?: string
  sidebarContentId?: string
  bottomCornerImageId?: string
  sidebar?: JSX.Element;
  children: JSX.Element | JSX.Element[];
  onNextClick?: () => void;
  onPrevClick?: () => void;
  nextButtonText?: string;
  sidebarImage?: JSX.Element
  hideBottomCornerImage?: boolean
  nextButtonDisabled?:boolean;
  source?:string;
  hidePrevBtn?:boolean
  hideHelpOverLay?:boolean
  hideHeaderContent?:boolean
  hideNextBtn?:boolean
  isError?:boolean
  errorMsg?:string;
}
declare global {
  interface Window {
    updatePage : (page_path:string) => void;
    trackFb : (event:string, _:any) => void;
    trackQuora:(event:string) =>void;
    trackFbCustom : (event:string, _:any) => void;
    trackGTag: (event:string, _:any) => void;
    triggerAnalytics : (franchise : string) => void;
    chat: (franchise:string, action:string, _?:any) => void;
  }
}

/**
 * All the logic for Sidebar component is moved here.
 * 
 */
const SidebarComponent = (props: StartFormLayout) => {
  return props.sidebar ? (
    props.sidebar
  ) : (
    <Sidebar>
      {/* <LogoWrapper id={props.logoId}>
        <img src={ConfigurationUtil.get("logo-dark")} alt={ConfigurationUtil.get("displayName")} style={{maxWidth: '175px', maxHeight: '55px'}}/>
      </LogoWrapper> */}
      <div id={props.sidebarContentId}>
        <StartStepper />
      </div>
      {props.sidebarImage ? props.sidebarImage : null}
      {/* {props.hideBottomCornerImage ? null : (
        // <BottomCornerImage id={props.bottomCornerImageId} src={ThemeUtil.get("/images/bottom-left-corner.svg")} alt="" />
      )} */}
    </Sidebar>
  )
}

/**
 * Extracting Help Button logic external from StartFormLayout.
 * But the onclick item is tightly coupled with Help Overlay.
 * For the time being, i am leaving this as it is.
 */
const HelpButtonComponent = (props: StartFormLayout & {handleClick: (event:React.MouseEvent<HTMLElement> , source?:string) => void}) => {
  return <HelpButtonWrapper onClick={(e) => props.handleClick(e, props.source)}>
  <Body size={BodySize.lg}>
  {/* {LanguageUtil.get("having-trouble")}{" "} */}
    <span style={{ color: "var(--primary)", fontWeight: 700 }}>
    {/* {LanguageUtil.get("here-is-help")} */}
    </span>
  </Body>
</HelpButtonWrapper>
}

interface ContentComponent {
  title : string;
  description : string;
  children: JSX.Element | JSX.Element[];
}

const ContentComponent = (props : ContentComponent) => {
  return <ContentInner>
  <Heading size={HeadingSize.xl} style={{ lineHeight: "50px" }}>
    {props.title}
  </Heading>
  {/* <MediaQuery query="(min-width:1280px)"> */}
      <Description size={BodySize.lg}>{props.description}</Description>
  {/* </MediaQuery> */}

  {props.children}
</ContentInner>
}
const ContentInnerComponent = (props: StartFormLayout) => {
    return <ContentInnerWrapper>
      {!props.hideHeaderContent &&
      <ContentComponent
      title={props.title}
      description={props.description}>
        
      </ContentComponent>} 
      {props.children}
        {props.onNextClick && props.onPrevClick && (
          <StartFormFooter
            onNextClick={props.onNextClick}
            onPrevClick={props.onPrevClick}
            nextButtonText={props.nextButtonText}
            disabled={props.nextButtonDisabled}
            hidePrevBtn={props.hidePrevBtn}
            hideNextBtn={props.hideNextBtn}
          />
        )}
    </ContentInnerWrapper>
}
/**
 * This is the basic wrapper component in all the pages of app.
 * Everything is inside this component.
 * There could be a ScriptInjector outside, everything else includign sidebar, forms animations are inside this component.
 * It seems this component is too complex, May be I will refactor it a little
 * The direct children for most of the times are HelpPlayButton and a Form/Card components
 * 
 * The problem with this component is,
 * StartFormLayout is too complext.
 * If it wanted to be a wrapper for everything, then it should be a dump componenet.
 * But here we have a lot of logic.
 */
 export const ErrorMessage = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--error);
  margin: 12px;
  margin-left: 0px;
  position: absolute;
  bottom: 0px;
 `;
export const StartFormLayout = (props: StartFormLayout) => {
  const [helpOpen, setHelpOpen] = useState(false)
  const helpOverlayRef = useRef<any>()
  const handleClick = (event:React.MouseEvent<HTMLElement> , source?:string)=> {
    event.preventDefault();
    /**
     * May be in the near future, we will have help docs for all the pages. But as now, we have docs for the first 2-3 pages.
     * So i am doing a sort-of workaround. By default, it will open chat window
     * If you have provided specific pages, then you have to pass source property.
     */
    if(source) {
      setHelpOpen(true);
      return;
    }
    // window.chat(ConfigurationUtil.getCurrentFranchise(), 'show');
  }
  useOnClickOutside(helpOverlayRef, () => setHelpOpen(false));

  return (
  <Wrapper>
    {/* <MediaQuery query="(max-width:1279px)">
    {ConfigurationUtil.get("logo") && <LogoWrapper id={props.logoId}>
              <img style={{display: "block", margin:"auto", maxHeight: '45px'}}  src={ConfigurationUtil.get("logo")}  alt ={ConfigurationUtil.get("displayName")} />
            </LogoWrapper>}
    </MediaQuery> */}
     <MediaQuery query="(min-width:1280px)">
        <SidebarComponent {...props} />
    </MediaQuery>
    {<ContentWrapper id={props.contentId}>
        {/* <MediaQuery query="(min-width:1280px)">
            {ConfigurationUtil.get("app-enable-help-docs") && !props.hideHelpOverLay && <HelpButtonComponent {...props} handleClick={handleClick}/>}
        </MediaQuery> */}
        {<ContentInnerComponent {...props} />}
        {props.isError && <ErrorMessage>{"Sorry, something went wrong. Please try again later."}</ErrorMessage>}
    </ContentWrapper>}
    
    {/* <MediaQuery query="(min-width:1280px)">
       <HelpOverlay  source={props.source} ref={helpOverlayRef} open={helpOpen} close={() => setHelpOpen(false)} />
    </MediaQuery> */}
    </Wrapper>
  )
};
