import React from 'react'
import styled from '../lib/styled'
import { HeadingSize } from './Typography/Heading'
import { Body } from './Typography/Body'
import { CardBadge } from './CardBadge'
import { CardStatus } from './Card'
import { Icon, IconType } from './Icon'
import { MoneyAmount } from './MoneyAmount'

const ROW_COLS = '1fr 2fr 5fr 1fr 2fr'

const TableRow = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.default};
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.white};
  display: grid;
  padding: 16px 0px;
  grid-template-columns: ${ROW_COLS};
  margin-bottom: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  .copy-link-button {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    right: -42px;
    top: 8px;
    padding: 12px;

    :hover {
      opacity: 1;
    }
  }

  :hover {
    border: 1px solid ${props => props.theme.color.defaultBorder};
    box-shadow: ${props => props.theme.shadow.elevated};

    .copy-link-button {
      opacity: 1;
    }
  }
`

const TableCell = styled.div`
  border-color: #fff;
  color: ${props => props.theme.color.text.primary};
  padding: 0px 20px;
  display: flex;
  align-items: center;
`

const TableHead = (props: { children: string; style?: any }) => (
  <Body style={{ fontWeight: 'bold', padding: '0px 20px', ...props.style }}>{props.children}</Body>
)

interface PaymentsTableProps {
  setup?: boolean
  onClickRow: (item: any) => void
  items: Array<{
    id: string
    issuedOn: string
    client: string
    items: Array<{ price: number; quantity: number }>
    status: CardStatus
  }>
}

export const PaymentsTable = (props: PaymentsTableProps) => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'grid', gridTemplateColumns: ROW_COLS, marginBottom: 12 }}>
        <TableHead>No.</TableHead>
        <TableHead>Date</TableHead>
        <TableHead>Client</TableHead>
        <TableHead style={{ textAlign: 'end' }}>Amount</TableHead>
        <TableHead style={{ textAlign: 'end' }}>Status</TableHead>
      </div>
      {props.items.map(item => (
        <TableRow onClick={() => props.onClickRow(item)}>
          <TableCell style={{ fontWeight: 'bold' }}>{item.id}</TableCell>
          <TableCell>{item.issuedOn}</TableCell>
          <TableCell>{item.client}</TableCell>
          <TableCell style={{ justifyContent: 'flex-end' }}>
            <MoneyAmount size={HeadingSize.sm}>
              {`$${item.items
                .reduce((total, i) => (total += (i.price || 0) * (i.quantity || 0)), 0)
                .toFixed(2)}`}
            </MoneyAmount>
          </TableCell>
          <TableCell style={{ justifyContent: 'flex-end' }}>
            <CardBadge
              status={item.status}
              style={{ display: 'inline-flex' }}
              label={item.status === CardStatus.success ? 'Paid' : undefined}
            />
          </TableCell>
          <div className="copy-link-button" onClick={e => e.stopPropagation()}>
            <Icon type={IconType.copyLink} />
          </div>
        </TableRow>
      ))}
    </div>
  )
}
