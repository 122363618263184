import React from 'react'
import styled from '../lib/styled'
import { Heading } from './Typography/Heading'
import { Body } from './Typography/Body'
import { Button } from './Button'

const DetailsWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.backgroundDark};
  padding: 24px;
  border-radius: 16px;
  box-sizing: border-box;
`

export const ConnectStripe = () => (
  <DetailsWrapper>
    <Heading>Connect your Stripe account</Heading>
    <Body style={{ marginTop: 8, marginBottom: 20, maxWidth: 620 }}>
      To start managing payments with Quickpay you need to connect your stripe account. Click on the
      button to connect to stripe.
    </Body>
    <Button>CONNECT TO STRIPE</Button>
  </DetailsWrapper>
)
