import React, { createContext, useContext, useState } from "react";
import { FormData as AboutCompanyFormData } from "../Forms/OperatingAgreement/AboutCompanyForm";
import { FormData as AboutCompanyMoreDetailsFormData } from "../Forms/OperatingAgreement/AboutCompanyMoreDetailsForm";
import { FormData as MemberFormData } from "../Forms/OperatingAgreement/MemberForm";
import { FormData as MemberSettingsFormData} from "../Forms/OperatingAgreement/MemberSettingsForm";
import { FormData as DecisionMakingFormData} from "../Forms/OperatingAgreement/DecisionMakingForm";
import { FormData as ChangesFormData } from "../Forms/OperatingAgreement/ChangesForm";


export type CompanyType = "LLC" | "CORPORATION";
export type StateType = "DELAWARE" | "WYOMING";
export type CompanyRegistrationType = "NEW" | "MANAGE";
export type TokenObj = {
  access_token :string,
  expires_in : number,
  expires_at : number,
  first_issued_at : number,
  id_token : string,
  idpId : string,
  login_hint :string,
  scope :string,
}
const INIT_DATA = {
  currentPage: 0,
  aboutCompany: {} as AboutCompanyFormData,
  aboutCompanyMoreDetail: {} as AboutCompanyMoreDetailsFormData,
  memberForm :{} as MemberFormData,
  memberSettingsForm :{} as MemberSettingsFormData,
  decisionMakingForm : {} as DecisionMakingFormData,
  changesForm : {} as ChangesFormData,
};

const StartFormContext = createContext({
  ...INIT_DATA,
  setCurrentPage: (value: number) => {},
  setAboutCompany: (value: AboutCompanyFormData) => {},
  setAboutCompanyMoreDetails : (value: AboutCompanyMoreDetailsFormData) => {},
  setMemberForm :(value: MemberFormData) => {},
  setMemberSettingsForm :(value: MemberSettingsFormData) => {},
  setDecisionMakingForm :(value: DecisionMakingFormData) => {},
  setChangesForm :(value: ChangesFormData) => {},
  updateCompanyMoreDetailsAndMembers : (company : AboutCompanyMoreDetailsFormData, members : MemberFormData) => {}
});




export const useStartForm = () => useContext(StartFormContext);

export const StartFormProvider = (props: { children: JSX.Element }) => {
  const [formData, setFormData] = useState(INIT_DATA);
  const setCurrentPage = (value: number) =>
    setFormData({ ...formData, currentPage: value });
  const setAboutCompany = (value: AboutCompanyFormData) =>
    setFormData({ ...formData, aboutCompany: value });
  const setAboutCompanyMoreDetails = (value: AboutCompanyMoreDetailsFormData) =>
    setFormData({ ...formData, aboutCompanyMoreDetail: value });
  const setMemberForm = (value: MemberFormData) =>
    setFormData({ ...formData, memberForm: value });
  const setMemberSettingsForm = (value: MemberSettingsFormData) =>
    setFormData({ ...formData, memberSettingsForm: value });
  const setDecisionMakingForm = (value: DecisionMakingFormData) =>
    setFormData({ ...formData, decisionMakingForm: value });
  const setChangesForm = (value: ChangesFormData) =>
    setFormData({ ...formData, changesForm: value });
  const updateCompanyMoreDetailsAndMembers = (company : AboutCompanyMoreDetailsFormData, members : MemberFormData) => 
    setFormData({ ...formData, aboutCompanyMoreDetail: company, memberForm : members});
  return (
    <StartFormContext.Provider
      value={{
        ...formData,
        setCurrentPage,
        setAboutCompany,
        setAboutCompanyMoreDetails,
        setMemberForm,
        setMemberSettingsForm,
        setDecisionMakingForm,
        setChangesForm,
        updateCompanyMoreDetailsAndMembers
      }}
    >
      {props.children}
    </StartFormContext.Provider>
  );
};
