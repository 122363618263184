import React from 'react'
import styled from '../lib/styled'
import { HeadingSize } from './Typography/Heading'
import { Body } from './Typography/Body'
import { Icon, IconType } from './Icon'
import { MoneyAmount } from './MoneyAmount'
import { CardListItemType, CardList } from './CardList'

const ROW_COLS = '2fr 1fr 1fr 1fr 0.2fr'

const TableRow = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.default};
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.white};
  display: grid;
  padding: 16px 0px;
  grid-template-columns: ${ROW_COLS};
  margin-bottom: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  .copy-link-button {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    right: -42px;
    top: 8px;
    padding: 12px;

    :hover {
      opacity: 1;
    }
  }

  :hover {
    border: 1px solid ${props => props.theme.color.defaultBorder};
    box-shadow: ${props => props.theme.shadow.elevated};

    .copy-link-button {
      opacity: 1;
    }
  }
`

const TableCell = styled.div`
  border-color: #fff;
  color: ${props => props.theme.color.text.primary};
  padding: 0px 20px;
  display: flex;
  align-items: center;
`

const TableHead = (props: { children: string; style?: any }) => (
  <Body style={{ fontWeight: 'bold', padding: '0px 20px', ...props.style }}>{props.children}</Body>
)

interface PaymentsTableProps {
  setup?: boolean
  onClickRow: (item: any) => void
  items: Array<{
    name: string
    email: string
    dateAdded: string
    totalAmount: string
  }>
}

export const ClientsTableSM = (props: PaymentsTableProps) => {
  let rows: any = props?.items?.map(item => {
    let row = [];
    row.push({
      name : 'name',
      displayName : 'Name',
      value : `${item.name}`,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'email',
      displayName : 'Email',
      value : item.email,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'total',
      displayName : 'Total',
      value : `$${item.totalAmount}`,
      type : CardListItemType.TEXT
    });
    return {row};
  });
  return <CardList rows={rows}/>
}
