import React from 'react'
import styled from '../lib/styled'
import { Header } from '../components/Header'
import { Skeleton } from '../components/Skeleton'
import MediaQuery from 'react-responsive'
import { BurgerMenu } from './BurgerMenu'

const LoadingContentWrapper = styled.div`
  margin-top: 120px;
`
const Wrapper = styled.div`
`;
interface PageLayoutProps {
  children: JSX.Element | JSX.Element[]
  title: string
  loading?: boolean
}
const LoadingContent = () => {
  return (<>
    <MediaQuery query="(max-width:728px)">
      <LoadingContentWrapper>
          <Skeleton big width="100vw" style={{ marginBottom: 55 }} />
          <Skeleton big width="100vw" style={{ marginBottom: 23 }} />
          <Skeleton big width="100vw" style={{ marginBottom: 23 }} />
        </LoadingContentWrapper>
    </MediaQuery>
    <MediaQuery query="(min-width:728px)">
      <LoadingContentWrapper>
        <Skeleton big width="345px" style={{ marginBottom: 55 }} />
        <Skeleton big width="528px" style={{ marginBottom: 23 }} />
        <Skeleton big width="528px" style={{ marginBottom: 23 }} />
      </LoadingContentWrapper>
  </MediaQuery></>

  )

}
export const PageLayout = (props: PageLayoutProps) => (
  <Wrapper>
    <MediaQuery query="(max-width:728px)">
      <BurgerMenu/>
    </MediaQuery>
    <Header title={props.title} loading={props.loading} />
    {props.loading ? (
      <LoadingContent/>
    ) : (
      <div>
        {props.children}
      </div>
    )}
  </Wrapper>
);
