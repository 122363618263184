import React from "react";
import { useGetFormHook, FormProps } from '../../../lib/form'
import { Select } from "../../Select";
import { Form } from '../../Form'
import styled from "@emotion/styled"
import { watch } from "fs";
import { useStartForm } from "../../OperatingAgreement/StartFormProvider";
import { RadioList } from "../../RadioList";

export type FormData = {
  llcType : string
  profitDistributionFreq: string
  llcFiscalYear: string
  taxElectionTreatment: string
  membersLength : number
};

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1279px){
    flex-direction: column;
    row-gap: 0.75rem;
  }
`

const FormRowWrapper = styled(FormWrapper)`
  margin-top: 32px;
  @media (max-width: 1279px){
    margin-top: none;
  }
`
const Wrapper = styled.div`
  @media (max-width: 728px){
    display: flex;
    flex-direction: column;
    gap:1.5em;
  }
`
export const MemberSettingsForm = (props: FormProps<FormData>) => {
  const { register,  errors , onSubmit, setValue } = useGetFormHook(props)
  const startForm = useStartForm()
  const onLLCTypeSelection = (value: string) => {
    setValue("llcType", value)
  }
  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <Wrapper>
       <Select
        label={'Frequency of LLC distribution of profits'}
        style={{ width: '100%' }}
        formStyle
        topSpace
        bottomSpace
        error={errors.profitDistributionFreq &&
          errors.profitDistributionFreq?.message}
        disabled={false}
        onSelectOption={(value) => {
          setValue(`profitDistributionFreq`, value)
        }}
        options={[{ label: 'Board decides', value: 'BOARD_DECIDES' },{ label: 'Anytime member decides/ Anytime all members unanimously decide', value: 'ANYTIME_MEMBER_DECIDES' },
          { label: 'Monthly', value: 'MONTHLY' },
          { label: 'Quarterly', value: 'QUARTERLY' },
          { label: 'Semi-annually', value: 'SEMI_ANNUALLY' },
          { label: 'Annually', value: 'ANNUALLY' }]}
        {...register(`profitDistributionFreq`,)}
      />
      <Select
        label={"End of LLC's fiscal year"}
        style={{ width: '100%' }}
        formStyle
        bottomSpace
        error={errors.llcFiscalYear &&
          errors.llcFiscalYear?.message}
        disabled={false}
        onSelectOption={(value) => {
          setValue(`llcFiscalYear`, value)
        }}
        options={[{ label: 'January', value: 'JANUARY' },
          { label: 'February', value: 'FEBRUARY' },
          { label: 'March', value: 'MARCH' },
          { label: 'April', value: 'APRIL' },
          { label: 'May', value: 'MAY' },
          { label: 'June', value: 'JUNE' },
          { label: 'July', value: 'JULY' },
          { label: 'August', value: 'AUGUST' },
          { label: 'September', value: 'SEPTEMBER' },
          { label: 'October', value: 'OCTOBER' },
          { label: 'November', value: 'NOVEMBER' },
          { label: 'December', value: 'DECEMBER' },]}
        {...register(`llcFiscalYear`,)}
      />
      <Select
        label={"Tax election treatment from the IRS"}
        style={{ width: '100%' }}
        formStyle
        bottomSpace
        error={errors.taxElectionTreatment &&
          errors.taxElectionTreatment?.message}
        disabled={false}
        onSelectOption={(value) => {
          setValue(`taxElectionTreatment`, value)
        }}
        options={[{ label: 'None', value: 'NONE' }, 
                  { label: 'C-corp', value: 'C_CORP' },
                  { label: 'S-corp', value: 'S_CORP' },
                ]}
        {...register(`taxElectionTreatment`, )}
      />
      </Wrapper>
    </Form>
  );
};
