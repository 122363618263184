import React from 'react'
import styled from '../lib/styled'
import { Heading } from './Typography/Heading'
import { Surface } from './Surface'
import { Button } from './Button'
import { NavLink } from 'react-router-dom'

const Wrapper = styled.div`
  padding: 0 16px 32px 16px;
  flex: 0 1 33.33%;
  box-sizing: border-box;
`
const WrapperBlock = styled(Surface)`
  min-height: 230px;
  max-height: 230px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin: 0;
  justify-content: space-between;
`
const CardWrapper = styled.div`
  margin-bottom: 10px;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  height: 80px;
`

interface ReferFriendsCardProps {
  title: string
  image: string
  to?: any
  style?: any
  button?: string
}

export const ReferFriendsCard = (props: ReferFriendsCardProps) => (
  <Wrapper style={props.style}>
    <WrapperBlock>
      <CardWrapper>
        <LogoWrapper>
          <img src={props.image} alt="" />
        </LogoWrapper>
        <Heading>{props.title}</Heading>
      </CardWrapper>
      <NavLink to={props.to}>
        <Button variant="aliceBlue">{props.button}</Button>
      </NavLink>
    </WrapperBlock>
  </Wrapper>
)
