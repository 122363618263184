import React, { useEffect } from 'react'
import { find } from 'lodash'
import anime from 'animejs'
import styled from '../../lib/styled'
import { useStartStep } from '../../pages/StartOA'
import { Body, BodySize } from '../Typography/Body'
import { Icon, IconType } from '../Icon'

const getSubItemsListId = (title: string) => `sub-items-list-${title.split(' ').join('-')}`

const START_ITEMS = [
  {
    title: 'BASIC',
    indexs: [1,2],
    subItems: [
      {
        title: 'Company',
        index: 1,
      },
      {
        title: 'More details',
        index: 2,
      },
    ],
  },
  {
    title: 'MEMBERS',
    indexs: [3,4],
    subItems: [
      {
        title: 'Add members',
        index: 3,
      },
      {
        title: 'Settings',
        index: 4,
      },
    ],
  },
  {
    title: 'Decision-making',
    indexs: [5],
  },
  {
    title: 'CHANGES',
    indexs: [6],
  },
]

const StepItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ItemNumber = styled.div<{ isCurrent: boolean }>`
  border: ${props =>
    props.isCurrent
      ? `2px solid ${props.theme.color.primary};`
      : `1px solid ${props.theme.color.text.disabled};`};
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`
const SubItemWrapper = styled.div<{ last?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => (!props.last ? 'margin-bottom: 32px;' : '')}
`
const SubItemList = styled.div`
  height: 0px;
  overflow: hidden;
  margin-left: 56px;
`

interface StepItemProps {
  title: string
  indexs: number[]
  pageNumber: number
  subItems?: Array<{ title: string; index: number }>
  currentPageIndex: number
}

const StepItem = (props: StepItemProps) => {
  const isCompleted = props.indexs[props.indexs.length - 1] < props.currentPageIndex
  const isCurrent = props.indexs.some(i => i === props.currentPageIndex)
  const numberColor = isCurrent ? 'var(--primary)' : undefined
  const titleColor = isCurrent
    ? 'var(--primary)'
    : props.indexs[props.indexs.length - 1] > props.currentPageIndex
    ? 'var(--text-secondary)'
    : 'var(--text-disabled)'

  return (
    <div style={{ marginBottom: 40 }}>
      <StepItemWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isCompleted ? (
            <Icon type={IconType.doneXL} />
          ) : (
            <ItemNumber isCurrent={isCurrent}>
              <Body bold style={{ fontSize: 18 }} color={numberColor}>
                {props.pageNumber}
              </Body>
            </ItemNumber>
          )}
          <Body bold style={{ fontSize: 18, marginLeft: 24 }} color={titleColor}>
            {props.title}
          </Body>
        </div>
        {isCurrent && !props.subItems ? <Icon type={IconType.grayArrow} /> : null}
      </StepItemWrapper>
      {props.subItems ? (
        <SubItemList id={getSubItemsListId(props.title)}>
          {props.subItems.map(subItem => (
            <SubItemWrapper>
              <Body bold dark size={BodySize.lg}>
                {subItem.title}
              </Body>
              {subItem.index === props.currentPageIndex ? <Icon type={IconType.grayArrow} /> : null}
            </SubItemWrapper>
          ))}
        </SubItemList>
      ) : null}
    </div>
  )
}

export const StartStepper = () => {
  const { currentPage, lastPage } = useStartStep()

  useEffect(() => {
    const lastItem = find(START_ITEMS, ({ indexs }) => indexs.indexOf(lastPage) !== -1)
    const currentItem = find(START_ITEMS, ({ indexs }) => indexs.indexOf(currentPage) !== -1)
    const tl = anime.timeline({ easing: 'easeOutQuart' })
    if (lastItem && lastItem.subItems && (!currentItem || lastItem.title !== currentItem.title)) {
      tl.add({
        targets: `#${getSubItemsListId(lastItem.title)}`,
        opacity: [1, 0],
        duration: 300,
      }).add(
        {
          targets: `#${getSubItemsListId(lastItem.title)}`,
          marginTop: [35, 0],
          height: [`${56 * lastItem.subItems.length - 32}px`, '0px'],
          duration: 500,
        },
        '-=300'
      )
    }
    if (
      currentItem &&
      currentItem.subItems &&
      (!lastItem || currentItem.title !== lastItem.title)
    ) {
      tl.add({
        targets: `#${getSubItemsListId(currentItem.title)}`,
        marginTop: [0, 35],
        height: ['0px', `${56 * currentItem.subItems.length - 32}px`],
        duration: 500,
      }).add(
        {
          targets: `#${getSubItemsListId(currentItem.title)}`,
          opacity: [0, 1],
          duration: 300,
        },
        '-=200'
      )
    } else if (
      lastItem &&
      currentItem &&
      currentItem.subItems &&
      currentItem.title === lastItem.title
    ) {
      tl.add({
        targets: `#${getSubItemsListId(currentItem.title)}`,
        marginTop: [35, 35],
        height: [
          `${56 * currentItem.subItems.length - 32}px`,
          `${56 * currentItem.subItems.length - 32}px`,
        ],
        duration: 0,
      }).add({
        targets: `#${getSubItemsListId(currentItem.title)}`,
        opacity: [1, 1],
        duration: 0,
      })
    }
    return () => {}
  }, [currentPage, lastPage])

  return (
    <div>
      {START_ITEMS.map((item, i) => (
        <StepItem key={item.title} {...item} pageNumber={i + 1} currentPageIndex={currentPage} />
      ))}
    </div>
  )
}
