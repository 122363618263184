import React from 'react'
import styled from "@emotion/styled"

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 40px 32px;
  min-width: 369px;
  max-width: 369px;
  min-height: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const ImageWrapper = styled.div`
  background-color: white;
  box-shadow: 4px 2px 24px 0 rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  padding: 0px 60px;
  min-width: 400px;
  max-width: 400px;
  min-height: 100%;
  position: fixed;
`
export const StartSidebar = (props: { id?: string, style?: any, children?: JSX.Element | Array<JSX.Element | null> }) => (
    <Wrapper id={props.id} style={props.style}>
      {props.children}
    </Wrapper>
)
export const StartImageSidebar = (props: { id?: string, style?: any, children?: JSX.Element | Array<JSX.Element | null> }) => (
  <>
    <ImageWrapper id={props.id} style={props.style}>
      {props.children}
    </ImageWrapper>
    <div style={{ width: 400, minWidth: 400 }} />
  </>
)