import React from 'react'
import styled from '../lib/styled'
import { Icon, IconType } from './Icon'
import { Body, BodySize } from './Typography/Body'

export enum NotificationType {
  success = 'SUCCESS',
  failure = 'FAILURE',
}

const NOTIFICATION_ICONS = {
  [NotificationType.success]: IconType.doneSmall,
  [NotificationType.failure]: IconType.error,
}

const Wrapper = styled.div<{ type: NotificationType; last?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 260px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadow.default};
  margin-bottom: ${props => (props.last ? 0 : 8)}px;
  background-color: ${props => {
    switch (props.type) {
      case NotificationType.success:
        return props.theme.color.secondaryLight
      case NotificationType.failure:
        return props.theme.color.errorLight
      default:
        return ''
    }
  }};
`

interface NotificationMenuItemProps {
  type: NotificationType
  last?: boolean
  children: string
}

export const NotificationMenuItem = (props: NotificationMenuItemProps) => (
  <Wrapper type={props.type} last={props.last}>
    <Icon type={NOTIFICATION_ICONS[props.type]} style={{ marginRight: 8 }} />
    <Body dark size={BodySize.sm}>
      {props.children}
    </Body>
  </Wrapper>
)
