import React from "react";
import styled from "@emotion/styled"
import { Body } from '../Typography/Body'
import { ArrowButton } from '../ArrowButton'
import {LeftArrowButton} from '../LeftArrowButton'
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  @media (max-width: 1279px){
    justify-content: space-between
    
  }
  & > * {
    @media (max-width: 1279px){
      flex-basis: 33%;
    }
  }
  
`

interface StartFormFooterProps {
  onNextClick: () => void
  onPrevClick: () => void
  nextButtonText?: string
  disabled?:boolean
  hidePrevBtn?:boolean
  hideNextBtn?:boolean
}

export const StartFormFooter = (props: StartFormFooterProps) => (
  <Wrapper>
  {/* <MediaQuery query="(min-width:1280px)"> */}
    {props.hidePrevBtn ? (<Body bold style={{ cursor: 'pointer' ,visibility:'hidden' }} onClick={props.onPrevClick}>PREVIOUS</Body>):(<Body bold style={{ cursor: 'pointer'}} onClick={props.onPrevClick}>PREVIOUS</Body>)}
  {/* </MediaQuery> */}
  {/* <MediaQuery query="(max-width:1279px)">
    {props.hidePrevBtn ? (<LeftArrowButton style={{visibility:'hidden'}} disabled={props.disabled} onClick={props.onPrevClick}>PREVIOUS</LeftArrowButton>) : (<LeftArrowButton disabled={props.disabled} onClick={props.onPrevClick}>PREVIOUS</LeftArrowButton>)}
  </MediaQuery> */}
  {!props.hideNextBtn && <ArrowButton disabled={props.disabled} onClick={props.onNextClick}>{props.nextButtonText || 'NEXT'}</ArrowButton>}
  </Wrapper>
)
