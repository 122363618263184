import React from 'react'
import { css } from '@emotion/core'
import styled from '../lib/styled'
import { Body, BodySize } from './Typography/Body'
import { InfoToolTip } from './InfoToolTip'
import { RadioButton } from './RadioButton'

const Wrapper = styled.div<{
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
}>`
  ${props => css`
    ${props.fullWidth ? 'width: 100%;' : ''}
    ${props.leftSpace ? 'margin-left: 12px;' : ''}
    ${props.rightSpace ? 'margin-right: 12px;' : ''}
    ${props.topSpace ? 'margin-top: 26px;' : ''}
    ${props.bottomSpace ? 'margin-bottom: 26px;' : ''}
  `}
`
const LabelWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`
const ErrorMessage = styled(Body)`
  color: ${props => props.theme.color.error};
  margin-top: 6px;
`

interface InputProps {
  label?: string
  name: string
  dark?: boolean
  info?: string
  error?: string
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  wrapperStyle?: any
  value: any
  options: Array<{ label: string; value: any }>
  onSelect: (value: any) => void
  rowSpan?:boolean
}

// export const RadioList = (props: InputProps) => (
//   <Wrapper
//     leftSpace={props.leftSpace}
//     rightSpace={props.rightSpace}
//     topSpace={props.topSpace}
//     bottomSpace={props.bottomSpace}
//     fullWidth={props.fullWidth}
//     style={props.wrapperStyle}
//   >
//     {props.label ? (
//       <LabelWrapper>
//         <Body dark>{props.label}</Body>
//         {props.info ? <InfoToolTip content={props.info} /> : null}
//       </LabelWrapper>
//     ) : null}
//     <div style={{ display: 'flex', flexDirection: 'column' }}>
//       {props.options.map(({ label, value }, i) => (
//         <div
//           onClick={() => props.onSelect(value)}
//           style={{
//             display: 'flex',
//             alignItems: 'center',
//             width: 200,
//             cursor: 'pointer',
//             marginBottom: i === props.options.length - 1 ? 0 : 16,
//           }}
//         >
//           <RadioButton selected={value === props.value} />
//           <Body dark size={BodySize.lg} style={{ marginLeft: 16 }}>
//             {label}
//           </Body>
//         </div>
//       ))}
//     </div>
//     {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
//   </Wrapper>
// )
export const RadioList = (props: InputProps) => (
  <Wrapper
    leftSpace={props.leftSpace}
    rightSpace={props.rightSpace}
    topSpace={props.topSpace}
    bottomSpace={props.bottomSpace}
    fullWidth={props.fullWidth}
    style={props.wrapperStyle}
  >
    {props.label ? (
      <LabelWrapper>
        <Body dark>{props.label}</Body>
        {props.info ? <InfoToolTip content={props.info} /> : null}
      </LabelWrapper>
    ) : null}
    {props.rowSpan ? (<div style={{ display: 'flex', flexDirection: 'row' ,gap:'2em'}}>
      {props.options.map(({ label, value }, i) => (
        <div
          onClick={() => props.onSelect(value)}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: 200,
            cursor: 'pointer',
            marginBottom: i === props.options.length - 1 ? 16 : 16,
          }}
        >
          <RadioButton selected={value === props.value} />
          <Body dark size={BodySize.md} style={{ marginLeft: 16 }}>
            {label}
          </Body>
        </div>
      ))}
    </div>):
     (<div style={{ display: 'flex', flexDirection: 'column' }}>
     {props.options.map(({ label, value }, i) => (
       <div
         onClick={() => props.onSelect(value)}
         style={{
           display: 'flex',
           alignItems: 'center',
          //  width: 200,
           cursor: 'pointer',
           marginBottom: i === props.options.length - 1 ? 0 : 16,
         }}
       >
         <RadioButton selected={value === props.value} />
         <Body dark size={BodySize.md} style={{ marginLeft: 16 }}>
           {label}
         </Body>
       </div>
     ))}
   </div>)
    }
    {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
  </Wrapper>
)