import { BaseSyntheticEvent } from 'react'
import { useForm, FormContextValues } from 'react-hook-form'

export interface FormProps<Data> {
  onSubmit?:
    | ((values: Data) => void)
    | ((e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>)
  formHookProps?: FormContextValues<Data>
  initialValues?: Data
}

type GetFormHook<Data> = FormContextValues<Data> & {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

export const useGetFormHook = <Data>(args: FormProps<Data>): GetFormHook<Data> => {
  const formHook = useForm<Data>(
    args.initialValues ? { defaultValues: args.initialValues } : undefined
  )
  if (args.formHookProps) {
    const getFormHook = { ...args.formHookProps } as GetFormHook<Data>
    getFormHook.onSubmit = (args.onSubmit as unknown) as (
      e?: BaseSyntheticEvent<object, any, any> | undefined
    ) => Promise<void>
    return getFormHook
  } else {
    const onSubmit = formHook.handleSubmit(values => {
      console.log(values)
      if (args.onSubmit) {
        ;(args.onSubmit as (values: Data) => void)(values)
      }
    })
    return { ...formHook, onSubmit }
  }
}
