import React from 'react'
import styled from '../lib/styled'

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: 4px 2px 24px 0 rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  padding: 0px 60px;
  min-width: 500px;
  max-width: 500px;
  min-height: 100%;
  position: fixed;
`

export const StartSidebar = (props: {
  id?: string
  style?: any
  children?: JSX.Element | Array<JSX.Element | null>
}) => (
  <>
    <Wrapper id={props.id} style={props.style}>
      {props.children}
    </Wrapper>
    <div style={{ width: 500, minWidth: 500 }} />
  </>
)
