import React, { useEffect } from 'react'
import anime from 'animejs'
import { useForm } from 'react-hook-form'
import styled from '../../lib/styled'
import { Heading, HeadingSize } from '../Typography/Heading'
import { Body, BodySize } from '../Typography/Body'
import { Input } from '../Input'
import { Button } from '../Button'
import { TestimonialCard, TestimonialType } from '../TestimonialCard'
import { StartSidebar } from '../StartSidebar'
import { useStartForm } from '../StartFormProvider'

const getWrapperId = (type: TestimonialType) => `testimonial-card-wrapper--${type}`
const getContentId = (type: TestimonialType) => `testimonial-card-content--${type}`
const getContentMetaId = (type: TestimonialType) => `testimonial-card-content-meta--${type}`
const getQuoteIconId = (type: TestimonialType) => `testimonial-card-quote-icon--${type}`
const getBackgroundImageId = (type: TestimonialType) => `testimonial-background-image--${type}`
const getTestimonialStepId = (type: TestimonialType) => `testimonial-step--${type}`
const sidebarId = 'enter-email-sidebar'
const contentId = 'enter-email-content'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
`
const Content = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-left: 106px;
  padding-right: 106px;
  background-color: ${props => props.theme.color.background};
`
const LogoWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 210px;
`
const EmailWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`
const TestimonialStepWrapper = styled.div`
  position: absolute;
  bottom: 36px;
  right: 40px;
  display: flex;
`
const TestimonialStep = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  height: 6px;
  width: 70px;
  border-radius: 100px;
  margin-left: 30px;
`
const TestimonialStepInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  opacity: 0;
  background-color: ${props => props.theme.color.white};
`
const TestimonialImage = styled.div<{ img: string }>`
  height: 100%;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: top;
  background-size: cover;
  position: absolute;
`

export type FormData = {
  email: string
}

export const EnterEmail = (props: { onSubmit: (values: FormData) => void }) => {
  const startForm = useStartForm()
  const { register, errors, handleSubmit } = useForm<FormData>({
    defaultValues: startForm.email,
  })
  const onSubmit = handleSubmit(values => {
    startForm.setEmail(values)
    anime({
      targets: `#${sidebarId}, #${contentId}`,
      opacity: [1, 0],
      easing: 'easeOutQuad',
      delay: anime.stagger(100, { direction: 'reverse' }),
      duration: 500,
    })
    setTimeout(() => props.onSubmit(values), 500)
  })

  useEffect(() => {
    anime
      .timeline({
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true,
        delay: 5000,
        endDelay: 5000,
      })
      .add({
        targets: `#${getContentId('brew')}, #${getContentMetaId('brew')}`,
        opacity: [1, 0],
        duration: 600,
      })
      .add(
        {
          targets: `#${getQuoteIconId('brew')}`,
          opacity: [1, 0],
          scale: [1, 0.5],
          duration: 300,
        },
        350
      )
      .add(
        {
          targets: `#${getWrapperId('brew')}`,
          opacity: [1, 0],
          duration: 350,
        },
        650
      )
      .add(
        {
          targets: `#${getBackgroundImageId('brew')}`,
          opacity: [1, 0],
          duration: 1050,
        },
        650
      )
      .add(
        {
          targets: `#${getWrapperId('twitter')}`,
          opacity: [0, 1],
          duration: 350,
        },
        1350
      )
      .add(
        {
          targets: `#${getQuoteIconId('twitter')}`,
          opacity: [0, 1],
          scale: [0.5, 1],
          duration: 300,
        },
        1650
      )
      .add(
        {
          targets: `#${getContentId('twitter')}, #${getContentMetaId('twitter')}`,
          opacity: [0, 1],
          duration: 600,
        },
        1950
      )

    anime
      .timeline({
        easing: 'easeInOutQuad',
        loop: true,
        endDelay: 5650,
      })
      .add(
        {
          targets: `#${getTestimonialStepId('brew')}`,
          opacity: [1, 0],
          width: ['100%', '100%'],
          duration: 1000,
        },
        5650
      )
      .add(
        {
          targets: `#${getTestimonialStepId('twitter')}`,
          width: ['0%', '100%'],
          opacity: [1, 1],
          duration: 1000,
        },
        5850
      )
      .add(
        {
          targets: `#${getTestimonialStepId('twitter')}`,
          opacity: [1, 0],
          duration: 1000,
        },
        18500
      )
      .add(
        {
          targets: `#${getTestimonialStepId('brew')}`,
          width: ['0%', '100%'],
          opacity: [1, 1],
          duration: 1000,
        },
        18500
      )

    return () => {}
  }, [])

  return (
    <Wrapper>
      <StartSidebar id={sidebarId} style={{ padding: 0 }}>
        <TestimonialImage id={getBackgroundImageId('twitter')} img="/images/biz.png" />
        <TestimonialImage id={getBackgroundImageId('brew')} img="/images/jijo.png" />
        <div style={{ padding: '0px 60px' }}>
          <TestimonialCard
            content="Started my dream company in US while drinking coffee. Lightning fast!"
            name="Jijo Sunny"
            subtitle="FOUNDER, BREW"
            type="brew"
          />
          <TestimonialCard
            content="I could have really used the product back when I was a freelancer. I was a good designer but terrible at running a studio."
            name="Biz Stone"
            subtitle="FOUNDER, TWITTER"
            type="twitter"
          />
          <TestimonialStepWrapper>
            <TestimonialStep>
              <TestimonialStepInner id={getTestimonialStepId('brew')} />
            </TestimonialStep>
            <TestimonialStep>
              <TestimonialStepInner id={getTestimonialStepId('twitter')} />
            </TestimonialStep>
          </TestimonialStepWrapper>
        </div>
      </StartSidebar>
      <Content id={contentId}>
        <div style={{ maxWidth: 650, paddingBottom: 50 }}>
          <LogoWrapper>
            <img src="/images/logo-dark.svg" alt="Start Global" />
          </LogoWrapper>
          <Heading size={HeadingSize.xxl} style={{ marginBottom: 25 }}>
            Start your business now.
          </Heading>
          <Body dark size={BodySize.xl} style={{ marginBottom: 72 }}>
            Creating your company in US is just a few steps away, Enter your email address to
            continue.
          </Body>
          <form onSubmit={onSubmit}>
            <EmailWrapper>
              <Input
                name="email"
                label="Email address"
                type="email"
                fullWidth
                style={{ height: 55 }}
                error={errors.email && errors.email.message}
                ref={register({ required: 'Required' })}
              />
              <Button
                style={{ minWidth: 185, marginLeft: 24, marginTop: 30, padding: '18px 24px' }}
                type="submit"
              >
                GET STARTED
                <img src="/icons/arrow-right.svg" alt="arrow" style={{ marginLeft: 13 }} />
              </Button>
            </EmailWrapper>
          </form>
        </div>
      </Content>
    </Wrapper>
  )
}
