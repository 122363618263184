import React from 'react'
import { useGetFormHook, FormProps } from '../../lib/form'
import { useTheme } from 'emotion-theming'
import styled, { Theme } from '../../lib/styled'
import { Body } from '../Typography/Body'
import { Form } from '../Form'
import { Input } from '../Input'

const AreaCode = styled(Body)`
  color: ${props => props.theme.color.primary};
`

export type FormData = {
  firstName: string
  lastName: string
  phone: string
  email: string
}

export const PersonalDetailsForm = (props: FormProps<FormData>) => {
  const theme = useTheme<Theme>()
  const { register, errors, onSubmit } = useGetFormHook(props)

  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <div style={{ display: 'flex', width: '100%' }}>
        <Input
          name="firstName"
          label="First name"
          error={errors.firstName && errors.firstName.message}
          rightSpace
          bottomSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
        <Input
          name="lastName"
          label="Last name"
          error={errors.lastName && errors.lastName.message}
          leftSpace
          bottomSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
      </div>
      <Input
        name="phone"
        label="Phone number"
        type="tel"
        error={errors.phone && errors.phone.message}
        fullWidth
        bottomSpace
        formStyle
        addonStart={{
          content: <AreaCode bold>+91</AreaCode>,
          style: {
            backgroundColor: theme.color.primaryLight,
          },
        }}
        ref={register({ required: 'Required' })}
      />
      <Input
        name="email"
        label="Email address"
        type="email"
        error={errors.email && errors.email.message}
        fullWidth
        formStyle
        ref={register({ required: 'Required' })}
      />
    </Form>
  )
}
