import React from 'react'
import styled from '../lib/styled'
import { PageLayout } from '../components/PageLayout'
import { Surface } from '../components/Surface'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { PaymentsFeatures } from '../components/PaymentsFeatures'
import { ConnectStripe } from '../components/ConnectStripe'

const CornerImage = styled.img`
  margin-right: 32px;
`
const Divider = styled.div`
  margin-top: 30px;
  margin-bottom: 36px;
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.color.defaultBorder};
`
const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

export const PaymentSetup = () => (
  <PageLayout title="Payments">
    <Surface style={{ borderRadius: 16, position: 'relative', padding: 32 }}>
      <HeaderWrapper>
        <CornerImage src="/images/payments-corner.svg" alt="" />
        <div>
          <Heading size={HeadingSize.lg} style={{ marginBottom: 12, lineHeight: '50px' }}>
            Manage payments with Quickpay.
          </Heading>
          <Body size={BodySize.lg} style={{ lineHeight: '30px', maxWidth: 626 }}>
            Quickpay makes it easy to manage all your payments without having to go to a separate
            page or app.
          </Body>
        </div>
      </HeaderWrapper>
      <Divider />
      <PaymentsFeatures />
      <ConnectStripe />
    </Surface>
  </PageLayout>
)
