import React from 'react'
import styled from '../lib/styled'
import { PageLayout } from '../components/PageLayout'
import { Surface } from '../components/Surface'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { PurchaseForm } from '../components/Forms/PurchaseForm'
import { ProductDetails } from '../components/ProductDetails'

const Wrapper = styled.div`
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`
const SurfaceBox = styled(Surface)`
  border-radius: 16px;
  box-shadow: none;
  border: 1px solid var(--default-border);
`
const BlueWrapper = styled.div`
  background-color: var(--alice-blue);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  margin-bottom: 16px;
`
const ContentWrapper = styled.div`
  padding-left: 20px;
`
const ContentBox = styled.div`
  width: 100%;
  max-width: 60%;
`
const Box = styled.div`
  width: 100%;
  max-width: 30%;
`
const HeadingFree = styled(Heading)`
  margin-bottom: 6px;
`
const SecondaryWrapper = styled.div`
  background-color: var(--secondary-light);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  margin-bottom: 16px;
`
const BodyInternational = styled(Body)`
  margin-left: 12px;
`
const InternationalWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`

export const Purchase = () => (
  <PageLayout title="Home">
    <SurfaceBox>
      <Wrapper>
        <ContentBox>
          <div>
            <Heading size={HeadingSize.lg} style={{ marginBottom: 12, lineHeight: '50px' }}>
              Make payment to start Incorporation.
            </Heading>
            <Body size={BodySize.lg} style={{ lineHeight: '30px', marginBottom: 44 }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore.
            </Body>
          </div>
          <PurchaseForm />
          <InternationalWrapper>
            <img src="/images/global.svg" alt="" />
            <BodyInternational size={BodySize.xs}>
              International customers Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incididun.
            </BodyInternational>
          </InternationalWrapper>
        </ContentBox>
        <Box>
          <BlueWrapper>
            <div>
              <img src="/images/compliance-icon.svg" alt="" />
            </div>
            <ContentWrapper>
              <HeadingFree size={HeadingSize.sx} color={'var(--independence)'}>
                Worry-Free Compliance
              </HeadingFree>
              <Body size={BodySize.xs}>All your filings will be taken care by us.</Body>
            </ContentWrapper>
          </BlueWrapper>
          <SecondaryWrapper>
            <div>
              <img src="/images/money-shield.svg" alt="" />
            </div>
            <ContentWrapper>
              <HeadingFree size={HeadingSize.sx} color={'var(--independence)'}>
                100% Cashback Guarantee
              </HeadingFree>
              <Body size={BodySize.xs}>We promise you 100% cashback guarantee.</Body>
            </ContentWrapper>
          </SecondaryWrapper>
        </Box>
      </Wrapper>
      <ProductDetails />
    </SurfaceBox>
  </PageLayout>
)
