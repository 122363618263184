import React, { useState, useEffect } from 'react'
import styled from '../lib/styled'
import { PageLayout } from '../components/PageLayout'
import { Heading } from '../components/Typography/Heading'
import { Body } from '../components/Typography/Body'
import { CreateClientForm } from '../components/Forms/CreateClientForm'
import { Icon, IconType } from '../components/Icon'
import { Button } from '../components/Button'
import { PaymentsHeader } from '../components/PaymentsHeader'
import { PaymentsTable } from '../components/PaymentsTable'
import { CardStatus } from '../components/Card'
import { Input } from '../components/Input'
import { Select } from '../components/Select'
import { PaymentSideCard } from '../components/PaymentSideCard'
import { Surface } from '../components/Surface'
import { InvoicePreview } from '../components/InvoicePreview'
import MediaQuery from 'react-responsive'
import { PaymentsTableSM } from '../components/PaymentsTableSM'

const paymentItems = [
  {
    id: '#BCS101',
    issuedOn: 'Jun 21, 2020',
    dueOn: 'Jun 21, 2020',
    client: 'Alexander Parkinson',
    email: 'alex@email.com',
    items: [{ title: 'something', quantity: 2, price: 627.25 }],
    status: CardStatus.pending,
  },
  {
    id: '#BCS101',
    issuedOn: 'Jun 21, 2020',
    dueOn: 'Jun 21, 2020',
    client: 'Alexander Parkinson',
    email: 'alex@email.com',
    items: [{ title: 'something', quantity: 2, price: 627.25 }],
    status: CardStatus.draft,
  },
  {
    id: '#BCS101',
    issuedOn: 'Jun 21, 2020',
    dueOn: 'Jun 21, 2020',
    client: 'Alexander Parkinson',
    email: 'alex@email.com',
    items: [{ title: 'something', quantity: 2, price: 627.25 }],
    status: CardStatus.success,
  },
  {
    id: '#BCS101',
    issuedOn: 'Jun 21, 2020',
    client: 'Alexander Parkinson',
    email: 'alex@email.com',
    items: [{ title: 'something', quantity: 2, price: 627.25 }],
    status: CardStatus.success,
  },
  {
    id: '#BCS101',
    issuedOn: 'Jun 21, 2020',
    dueOn: 'Jun 21, 2020',
    client: 'Alexander Parkinson',
    email: 'alex@email.com',
    items: [{ title: 'something', quantity: 2, price: 627.25 }],
    status: CardStatus.success,
  },
]

const ModalWrapper = styled.div<{ open?: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: ${props => (props.open ? 'flex' : 'none')};
`

export const Payments = () => {
  const [newInvoiceOpen, setNewInvoiceOpen] = useState(false)
  const [createClientOpen, setCreateClientOpen] = useState(false)
  const [invoicePreviewOpen, setInvoicePreviewOpen] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const onClickInvoiceItem = (item: any) => {
    setSelectedInvoice(item)
    setNewInvoiceOpen(true)
  }

  useEffect(() => {
    if (newInvoiceOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {}
  }, [newInvoiceOpen])

  return (
    <PageLayout title="Payments">
      <div style={{ marginBottom: 32 }}>
        <PaymentsHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <div>
            <Heading style={{ marginBottom: 12 }}>Invoices</Heading>
            <Body>List of all of your recent transactions.</Body>
          </div>
          <Button onClick={() => setNewInvoiceOpen(true)}>+ NEW INVOICE</Button>
        </div>
        <div style={{ width: '100%', display: 'flex', margin: '32px 0px' }}>
          <Input
            fullWidth
            placeholder="Search an Invoice"
            addonStart={{ content: <Icon type={IconType.search} /> }}
          />
          <Select
            width="155px"
            wrapperStyle={{ marginLeft: 20 }}
            options={[{ label: 'Show all', value: 'ALL' }]}
          />
        </div>
        <MediaQuery query="(min-width:728px)">
          <PaymentsTable items={paymentItems} onClickRow={onClickInvoiceItem} />
        </MediaQuery>
        <MediaQuery query="(max-width:728px)">
          <PaymentsTableSM items={paymentItems} onClickRow={onClickInvoiceItem} />
        </MediaQuery>
        <PaymentSideCard
          open={newInvoiceOpen}
          selectedInvoice={selectedInvoice}
          onCreateClient={() => setCreateClientOpen(true)}
          onPreview={() => setInvoicePreviewOpen(true)}
          close={() => {
            setNewInvoiceOpen(false)
            setSelectedInvoice(null)
          }}
        />
      </div>
      <ModalWrapper open={createClientOpen} onClick={() => setCreateClientOpen(false)}>
        <Surface style={{ padding: 32, width: 600 }} onClick={e => e.stopPropagation()}>
          <Heading>Add new Client</Heading>
          <CreateClientForm onCancel={() => setCreateClientOpen(false)} />
        </Surface>
      </ModalWrapper>
      <ModalWrapper open={invoicePreviewOpen} onClick={() => setInvoicePreviewOpen(false)}>
        <InvoicePreview close={() => setInvoicePreviewOpen(false)} />
      </ModalWrapper>
    </PageLayout>
  )
}
