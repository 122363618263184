import React from 'react'
import styled from '../lib/styled'
import { HeadingSize } from './Typography/Heading'
import { Body } from './Typography/Body'
import { Icon, IconType } from './Icon'
import { MoneyAmount } from './MoneyAmount'

const ROW_COLS = '2fr 1fr 1fr 1fr 0.2fr'

const TableRow = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.default};
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.white};
  display: grid;
  padding: 16px 0px;
  grid-template-columns: ${ROW_COLS};
  margin-bottom: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  .copy-link-button {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    right: -42px;
    top: 8px;
    padding: 12px;

    :hover {
      opacity: 1;
    }
  }

  :hover {
    border: 1px solid ${props => props.theme.color.defaultBorder};
    box-shadow: ${props => props.theme.shadow.elevated};

    .copy-link-button {
      opacity: 1;
    }
  }
`

const TableCell = styled.div`
  border-color: #fff;
  color: ${props => props.theme.color.text.primary};
  padding: 0px 20px;
  display: flex;
  align-items: center;
`

const TableHead = (props: { children: string; style?: any }) => (
  <Body style={{ fontWeight: 'bold', padding: '0px 20px', ...props.style }}>{props.children}</Body>
)

interface PaymentsTableProps {
  setup?: boolean
  onClickRow: (item: any) => void
  items: Array<{
    name: string
    email: string
    dateAdded: string
    totalAmount: string
  }>
}

export const ClientsTable = (props: PaymentsTableProps) => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'grid', gridTemplateColumns: ROW_COLS, marginBottom: 12 }}>
        <TableHead>Name</TableHead>
        <TableHead>Email</TableHead>
        <TableHead>Date added</TableHead>
        <TableHead style={{ textAlign: 'end' }}>Total amount</TableHead>
        <div />
      </div>
      {props.items.map(item => (
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' }}>{item.name}</TableCell>
          <TableCell>{item.email}</TableCell>
          <TableCell>{item.dateAdded}</TableCell>
          <TableCell style={{ justifyContent: 'flex-end' }}>
            <MoneyAmount size={HeadingSize.sm}>{`$${item.totalAmount}`}</MoneyAmount>
          </TableCell>
          <TableCell onClick={() => props.onClickRow(item)}>
            <Icon type={IconType.menu} />
          </TableCell>
        </TableRow>
      ))}
    </div>
  )
}
