import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import styled from '../../lib/styled'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Body } from '../Typography/Body'
import { Icon, IconType } from '../Icon'
import { Input } from '../Input'
import { Form } from '../Form'

const AddButton = styled.div`
  padding: 5px;
  cursor: pointer;
  width: 140px;
  margin-top: 27px;
`
const RemoveButton = styled(Icon)`
  position: absolute;
  right: -44px;
  top: 38px;
  cursor: pointer;
`
const RowWrapper = styled.div`
  display: flex;
  margin-top: 26px;
  width: 100%;
  position: relative;
`

type Director = { firstName: string; lastName: string }

export type FormData = {
  directors: Array<Director>
}

export const BoardOfDirectorsForm = (props: FormProps<FormData>) => {
  const { register, errors, control, onSubmit } = useGetFormHook(props)
  const { fields, append, remove, insert } = useFieldArray<Director>({ control, name: 'directors' })

  useEffect(() => {
    if (fields.length === 0) {
      append({ firstName: '', lastName: '' })
    }
  }, [fields.length, append])

  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        {fields.map((field, i) => (
          <RowWrapper key={field.id}>
            <Input
              name={`directors[${i}].firstName`}
              label="First name"
              error={
                errors.directors &&
                errors.directors[i] &&
                errors.directors[i].firstName &&
                errors.directors[i].firstName?.message
              }
              rightSpace
              fullWidth
              formStyle
              ref={register({ required: 'Required' })}
            />
            <Input
              name={`directors[${i}].lastName`}
              label="Last name"
              error={
                errors.directors &&
                errors.directors[i] &&
                errors.directors[i].lastName &&
                errors.directors[i].lastName?.message
              }
              leftSpace
              fullWidth
              formStyle
              ref={register({ required: 'Required' })}
            />
            {i > 0 ? <RemoveButton type={IconType.deleteButton} onClick={() => remove(i)} /> : null}
          </RowWrapper>
        ))}
        <AddButton onClick={() => insert(fields.length, { firstName: '', lastName: '' })}>
          <Icon type={IconType.add} />
          <Body style={{ marginLeft: 12, display: 'inline-block' }} color="var(--primary)" bold>
            ADD DIRECTOR
          </Body>
        </AddButton>
      </div>
    </Form>
  )
}
