import React, { useEffect, useState } from 'react'
import styled from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from './Icon'
import { ProgressBar } from './ProgressBar'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  body {
    overflow-x: hidden;
  }
`
const Backdrop = styled.div`
  background-color: ${props => props.theme.color.white};
  opacity: 0.8;
  width: 100%;
  height: 100%;
`
const ModalWrapper = styled.div`
  background-color: ${props => props.theme.color.white};
  border-radius: 16px;
  box-shadow: ${props => props.theme.shadow.elevated2};
  width: 500px;
  padding: 52px 40px;
  box-sizing: border-box;
  position: absolute;
  top: 30%;
  left: calc(50% - (500px / 2));
`

interface ModalProps {
  icon?: IconType
  title: string
  description?: string
  progress?: number
  style?: any
  open?: boolean
}

export const Modal = (props: ModalProps) => {
  const [open] = useState(props.open == null ? true : props.open)

  useEffect(() => {
    if (open) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {}
  }, [open])

  return (
    <Wrapper style={props.style}>
      <Backdrop />
      <ModalWrapper>
        {props.icon ? <Icon type={props.icon} /> : null}
        <Heading size={HeadingSize.lg} style={{ marginTop: 12, marginBottom: 12 }}>
          {props.title}
        </Heading>
        {props.description ? <Body size={BodySize.lg}>{props.description}</Body> : null}
        {props.progress != null ? (
          <div style={{ marginTop: 40 }}>
            <ProgressBar progress={props.progress} />
          </div>
        ) : null}
      </ModalWrapper>
    </Wrapper>
  )
}

export const AccountCreationModal = () => (
  <Modal
    icon={IconType.doneLarge}
    title="Success!"
    description="Your account has been created, please wait while we load your dashboard."
    progress={66}
  />
)

export const PaymentProcessingModal = () => (
  <Modal
    icon={IconType.dollarBill}
    title="Payment processing"
    description="Please wait while we recieve money from your bank account."
    progress={66}
  />
)

export const PaymentDoneModal = () => (
  <Modal
    icon={IconType.doneLarge}
    title="Payment done"
    description="Your payment is successfully completed."
  />
)
