import React from 'react'
import { css } from '@emotion/core'
import styled from '../lib/styled'
import { Surface } from './Surface'
import { Icon, IconType } from './Icon'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'

export const Stepper = styled(Surface)`
  width: 100%;
  height: 88px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 8px 0px;
`

const StepperItemWrapper = styled.div<{ active: boolean; first?: boolean; last?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  border-radius: 3px;
  margin: 0px 4px;
  ${props => (props.first ? 'margin-left: 8px;' : '')}
  ${props => (props.last ? 'margin-right: 8px;' : '')}
  ${props =>
    props.active
      ? css`
          background-color: ${props.theme.color.secondaryLight};
          border: 1px solid ${props.theme.color.secondaryBorder};
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04);
        `
      : ''}
`
const StepperItemIcon = styled(Icon)`
  margin-left: 26px;
  margin-right: 20px;
  margin-bottom: 15px;
`

interface StepperItemProps {
  active: boolean
  icon: IconType
  title: string
  subtitle: string
  first?: boolean
  last?: boolean
}

export const StepperItem = (props: StepperItemProps) => (
  <StepperItemWrapper active={props.active} first={props.first} last={props.last}>
    <StepperItemIcon type={props.active ? IconType.done : props.icon} />
    <div>
      <Heading size={HeadingSize.sm}>{props.title}</Heading>
      <Body size={BodySize.sm}>{props.subtitle}</Body>
    </div>
  </StepperItemWrapper>
)
