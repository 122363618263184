import React from 'react'

export enum IconType {
  alert = 'alert',
  alertWarning = 'alert-warning',
  addressBook = 'address-book',
  chat = 'chat',
  company = 'company',
  document = '',
  dollar = 'dollar',
  done = 'done',
  help = 'help',
  home = 'home',
  money = 'money',
  perks = 'perks',
  winner = 'winner',
  success = 'success',
  completed = 'completed',
  upload = 'upload',
  creditCard = 'credit-card',
  dollarBill = 'dollar-bill',
  doneLarge = 'complete-large',
  moreInfo = 'more-info',
  infoClip = 'info-clip',
  profile = 'profile',
  logout = 'logout',
  error = 'error',
  doneSmall = 'done-small',
  chevronDownLarge = 'chevron-down-large',
  chevronUpLargePrimary = 'chevron-up-large-primary',
  selected = 'selected',
  selectedRound = 'selected-round',
  add = 'add',
  deleteButton = 'delete-button',
  quote = 'quote',
  doneXL = 'done-med',
  doneMedium = 'done-md',
  grayArrow = 'gray-arrow',
  close = 'close',
  play = 'play',
  legal = 'legal',
  file = 'file',
  pendingLarge = 'pending-lg',
  reminder = 'reminder',
  profileLarge = 'profile-lg',
  addLarge = 'add-lg',
  arrowRightBlueSmall = 'arrow-right-blue-sm',
  trash = 'trash',
  payments = 'payments',
  edit = 'edit',
  copy = 'copy',
  search = 'search',
  copyLink = 'copy-link',
  menu = 'menu',
  download = 'download',
  stateIcon = 'state-icon',
  listIcon = 'list-icon',
  federalTax = 'federal-tax',
  taxIcon = 'tax-icon',
  chatIcon = 'chat-send-icon',
  removeIcon = 'remove-icon',
  accounting = 'accounting-icon',
  ReferFriends = 'refer-friends',
  setting = 'settings-icon',
  marketplace = 'marketplace',
  communication = 'communication',
}

interface IconProps extends React.HTMLAttributes<HTMLImageElement> {
  type?: IconType
  size?: number
  src?: string
}

export const Icon = (props: IconProps) => (
  <img
    id={props.id}
    src={`/icons/${props.type}.svg`}
    alt="icon"
    className={props.className}
    width={props.size}
    height={props.size}
    onClick={props.onClick}
    style={props.style}
  />
)

export const Image = (props: IconProps) => (
  <img
    id={props.id}
    src={props.src}
    alt="icon"
    className={props.className}
    width={props.size}
    height={props.size}
    onClick={props.onClick}
    style={props.style}
  />
)
