import React from 'react'
import styled from '../lib/styled'
import { Surface } from './Surface'
import { Heading } from './Typography/Heading'
import { Body } from './Typography/Body'
import MediaQuery from 'react-responsive'

export enum CardStatus {
  success = 'SUCCESS',
  failure = 'FAILURE',
  pending = 'PENDING',
  draft = 'DRAFT',
  agreementSigned = 'SIGNED',
  agreementPending = 'SIGN_PENDING'
}

const Wrapper = styled(Surface)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  ${props => (props.disabled ? 'opacity: 0.75;' : '')}
  @media (max-width: 768px){
    flex-direction: column;
  }
`

const TitleWrapper = styled.div`
  padding: 8px;
  @media (min-width: 768px){
    width: 25%;
  }
`
const CardItemsWrapper = styled.div`
  width: 60%;
  @media (max-width: 768px){
    width: 100%;
  }
`

interface CardProps {
  children: JSX.Element | JSX.Element[]
  title: string
  disabled?: boolean
  subtitle?: string
}

export const Card = (props: CardProps) => (
  <Wrapper disabled={props.disabled}>
    <TitleWrapper>
      <Heading style={{ marginBottom: 12 }}>{props.title}</Heading>
      <MediaQuery query="(min-width:728px)">
        {props.subtitle ? <Body>{props.subtitle}</Body> : null}
      </MediaQuery>
    </TitleWrapper>
    <CardItemsWrapper>{props.children}</CardItemsWrapper>
  </Wrapper>
)
