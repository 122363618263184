import React from 'react'
import styled from '../lib/styled'
import { HeadingSize } from './Typography/Heading'
import { Body } from './Typography/Body'
import { CardBadge } from './CardBadge'
import { CardStatus } from './Card'
import { Icon, IconType } from './Icon'
import { MoneyAmount } from './MoneyAmount'
import { CardList, CardListItemType } from './CardList'

const ROW_COLS = '1fr 2fr 5fr 1fr 2fr'

const TableRow = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.default};
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.white};
  display: grid;
  padding: 16px 0px;
  grid-template-columns: ${ROW_COLS};
  margin-bottom: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  .copy-link-button {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    right: -42px;
    top: 8px;
    padding: 12px;

    :hover {
      opacity: 1;
    }
  }

  :hover {
    border: 1px solid ${props => props.theme.color.defaultBorder};
    box-shadow: ${props => props.theme.shadow.elevated};

    .copy-link-button {
      opacity: 1;
    }
  }
`

const TableCell = styled.div`
  border-color: #fff;
  color: ${props => props.theme.color.text.primary};
  padding: 0px 20px;
  display: flex;
  align-items: center;
`

const TableHead = (props: { children: string; style?: any }) => (
  <Body style={{ fontWeight: 'bold', padding: '0px 20px', ...props.style }}>{props.children}</Body>
)

interface PaymentsTableProps {
  setup?: boolean
  onClickRow: (item: any) => void
  items: Array<{
    id: string
    issuedOn: string
    client: string
    items: Array<{ price: number; quantity: number }>
    status: CardStatus
  }>
}

export const PaymentsTableSM = (props: PaymentsTableProps) => {
  let rows: any = props?.items?.map(item => {
    let row = [];
    row.push({
      name : 'invoiceNumber',
      displayName : 'Invoice No.',
      value : item.id,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'issuedOn',
      displayName : 'Issued On',
      value : item.issuedOn,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'client',
      displayName : 'Client ',
      value : item.client,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'amount',
      displayName : 'Amount',
      value : `$${item.items
        .reduce((total, i) => (total += (i.price || 0) * (i.quantity || 0)), 0)
      .toFixed(2)}`,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'status',
      displayName : '',
      value : item.status,
      type : CardListItemType.TEXT
    })
    return {row};
  });
  return <CardList rows={rows}/>
}
