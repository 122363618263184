import React from "react";
import { useGetFormHook, FormProps } from '../../../lib/form'
import { Form } from '../../Form'
import styled from "@emotion/styled"
import { RadioList } from "../../RadioList";
import { useStartForm } from "../../OperatingAgreement/StartFormProvider";

export type FormData = {
  votingRights: string
  decisionType: string
  decisionToAddNewMember: string
  decisionToRemoveMember: string
  decisionToAddContribution : string
  decisionToDissolveLLC : string
  decisionToAppointManager : string
  decisionToMakeBoard : string
};

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1279px){
    flex-direction: column;
    row-gap: 0.75rem;
  }
`

const FormRowWrapper = styled(FormWrapper)`
  margin-top: 32px;
  @media (max-width: 1279px){
    margin-top: none;
  }
`
const Wrapper = styled.div`
  @media (max-width: 728px){
    display: flex;
    flex-direction: column;
    gap:1.5em;
  }
`
export const DecisionMakingForm = (props: FormProps<FormData>) => {
  const startForm = useStartForm();
  const { register,  errors , onSubmit, watch, setValue } = useGetFormHook(props)
  const onDecisionTypeSelection = (value: string) => {
    setValue("decisionType", value)
    setValue("decisionToAddNewMember", value)
    setValue("decisionToRemoveMember", value)
    setValue("decisionToAddContribution", value)
    setValue("decisionToDissolveLLC", value)
    setValue("decisionToAppointManager", value)
    setValue("decisionToMakeBoard", value)
  }
  const onDecisionToAddNewMember = (value: string) => {
    setValue("decisionToAddNewMember", value)
  }
  const onDecisionToRemoveMember = (value: string) => {
    setValue("decisionToRemoveMember", value)
  }
  const onDecisionToAddContribution= (value: string) => {
    setValue("decisionToAddContribution", value)
  }
  const onDecisionToDissolveLLC = (value: string) => {
    setValue("decisionToDissolveLLC", value)
  }
  const onDecisionToAppointManager = (value: string) => {
    setValue("decisionToAppointManager", value)
  }
  const onDecisionToMakeBoard = (value: string) => {
    setValue("decisionToMakeBoard", value)
  }
  const onSelectionVotingRights =(value: string) =>{
    setValue("votingRights", value)
  }
  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <Wrapper>
            {/* <RadioList
              name="decisionToAppointManager"
              label={'To appoint a manager, decision by member should be:'}
              bottomSpace
              options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
              value={watch("decisionToAppointManager")}
              onSelect={onDecisionToAppointManager}
              rowSpan={true}
              error={errors.decisionToAppointManager && errors.decisionToAppointManager.message}
              {...register("decisionToAppointManager", )} />
            <RadioList
              name="decisionToMakeBoard"
              label={'What vote will be required to dissolve the LLC?'}
              bottomSpace
              options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
              value={watch("decisionToMakeBoard")}
              onSelect={onDecisionToMakeBoard}
              rowSpan={true}
              error={errors.decisionToMakeBoard && errors.decisionToMakeBoard.message}
              {...register("decisionToMakeBoard", )} />
            </> */}
            <RadioList
              name="votingRights"
              label={'What is required to make amendments to OA?'}
              bottomSpace
              options={[{ label: 'Proportional to the ownership interest (Recommended)', value: 'PROPORTIONAL' }, { label: 'Every member gets a vote', value: 'MEMBER_GETS_VOTE' }]}
              value={'PROPORTIONAL'}
              onSelect={onSelectionVotingRights}
              error={errors.votingRights && errors.votingRights.message}
              {...register("votingRights", )} />
            <RadioList
              name="decisionType"
              label={'In general, how are decisions made by the members?'}
              bottomSpace
              options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
              value={'UNANIMOUS'}
              onSelect={onDecisionTypeSelection}
              rowSpan={true}
              error={errors.decisionType && errors.decisionType.message}
              {...register("decisionType", )}
            />
            <RadioList
              name="decisionToAddNewMember"
              label={'To add additional members to the LLC, decision by remaining members should be'}
              bottomSpace
              options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
              value={'UNANIMOUS'}
              onSelect={onDecisionToAddNewMember}
              rowSpan={true}
              error={errors.decisionToAddNewMember && errors.decisionToAddNewMember.message}
              {...register("decisionToAddNewMember", )}
            />
            <RadioList
              name="decisionToRemoveMember"
              label={'To remove a member from the LLC, decision by remaining members should be'}
              bottomSpace
              options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
              value={'UNANIMOUS'}
              onSelect={onDecisionToRemoveMember}
              rowSpan={true}
              error={errors.decisionToRemoveMember && errors.decisionToRemoveMember.message}
              {...register("decisionToRemoveMember", )}
            />
            <RadioList
              name="decisionToAddContribution"
              label={'Whether to make members obligated to make additional contributions, decision by members should be'}
              bottomSpace
              options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
              value={'UNANIMOUS'}
              onSelect={onDecisionToAddContribution}
              rowSpan={true}
              error={errors.decisionToAddContribution && errors.decisionToAddContribution.message}
              {...register("decisionToAddContribution", )}
            />
            <RadioList
              name="decisionToDissolveLLC"
              label={'What vote will be required by members to dissolve the LLC?'}
              bottomSpace
              options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
              value={'UNANIMOUS'}
              onSelect={onDecisionToDissolveLLC}
              rowSpan={true}
              error={errors.decisionToDissolveLLC && errors.decisionToDissolveLLC.message}
              {...register("decisionToDissolveLLC", )}
            />
              <RadioList
                name="decisionToAppointManager"
                label={'To appoint a manager, decision by members should be:'}
                bottomSpace
                options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
                value={'UNANIMOUS'}
                onSelect={onDecisionToAppointManager}
                rowSpan={true}
                error={errors.decisionToAppointManager && errors.decisionToAppointManager.message}
                {...register("decisionToAppointManager", )} />
              <RadioList
                name="decisionToMakeBoard"
                label={'What vote will be required to dissolve the LLC?'}
                bottomSpace
                options={[{ label: 'Unanimous vote', value: 'UNANIMOUS' }, { label: 'Majority vote ', value: 'MAJORITY' }]}
                value={'UNANIMOUS'}
                onSelect={onDecisionToMakeBoard}
                rowSpan={true}
                error={errors.decisionToMakeBoard && errors.decisionToMakeBoard.message}
                {...register("decisionToMakeBoard", )} />
      </Wrapper>
    </Form>
  );
};
