import React from 'react'
import styled from '../lib/styled'
import { Heading } from './Typography/Heading'
import { HeaderDropdownMenu } from './HeaderDropdownMenu'
import { NotificationsButton } from './NotificationsButton'
import { Skeleton } from './Skeleton'

const Wrapper = styled.div`
  @media (min-width: 768px){
    width: 105%;
  }
  margin-top: 40px;
  margin-bottom: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
`

interface HeaderProps {
  title: string
  loading?: boolean
}

export const Header = (props: HeaderProps) =>
  props.loading ? (
    <Wrapper>
      <Skeleton width="168px" />
      <Skeleton width="168px" />
    </Wrapper>
  ) : (
    <Wrapper>
      <Heading>{props.title}</Heading>
      <div style={{ display: 'flex' }}>
        <NotificationsButton unread={true} />
        <HeaderDropdownMenu label="Jonnathan Doe" />
      </div>
    </Wrapper>
  )
