import React from 'react'
import Dropzone from 'react-dropzone'
import { Icon, IconType } from '../components/Icon'
import { TextButton } from '../components/TextButton'
import { Body, BodySize } from '../components/Typography/Body'
import { Heading } from '../components/Typography/Heading'
import styled from '../lib/styled'
import { Button } from '../components/Button'
import { ChatReplayBox } from './ChatReplayBox'

const ChatWrapper = styled.div`
  margin-right: 20px;
  width: 100%;
  max-width: 100%;
  background-color: ${props => props.theme.color.white};
  border-radius: 16px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04);
  height: calc(100vh - 190px);
  position: relative;
`
const ChatTopBlock = styled.div`
  padding: 8px;
`
const ChatWrapperTop = styled.div`
  background-image: linear-gradient(180deg, #f6f8fa 0%, #ffffff 100%);
  border-radius: 12px 12px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
`
const ConsultantBlock = styled.div``
const ConsultantImage = styled.div`
  background-color: ${props => props.theme.color.white};
  border-radius: 100%;
  width: 67px;
  height: 67px;
  padding: 6px;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, 0%);
`
const ConsultantImages = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
`
const ConsultantTextBlock = styled.div`
  padding-top: 35px;
  text-align: center;
`
const BorderWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.defaultBorder};
  opacity: 0.64;
  margin: 20px 32px 0 32px;
`
const ChatCenterWrapper = styled.div`
  padding: 32px 32px 20px 40px;
  height: calc(100% - 241px);
  overflow: auto;
`
const ChatBoxBlock = styled.div`
  margin-top: 65px;
`
const BottomInputWrapper = styled.div`
  background-color: ${props => props.theme.color.white};
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 3;
`
const ChatInput = styled.input`
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 8px;
  height: 46px;
  width: -webkit-fill-available;
  outline: none;
  padding: 0 16px;
  color: ${props => props.theme.color.text.secondary};
  font-size: 16px;
  line-height: 19px;
  position: relative;
  ::placeholder {
    opacity: 0.64;
  }
`
const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(-40%, -45%);
  cursor: pointer;
  cursor: pointer;
  padding: 5px;
`

export const ChatBox = () => {
  const onDrop = async (isDragActive: File[]) => {}
  const messages = [
    {
      avatar: '/images/adrian-media-min.jpg',
      message:
        'Hey I am Sam. I will be your CPA for this tax season. You can ask any questions you have about filing here.',
      time: '9:40 pm',
      isMyMsg: false,
    },
    {
      avatar: '/images/adrian-media-min.jpg',
      message: 'Thank you!',
      isMyMsg: true,
    },
    {
      avatar: '/images/adrian-media-min.jpg',
      message: 'Hii',
      time: '9:45 pm',
      isMyMsg: true,
      date: new Date(),
    },
    {
      avatar: '/images/adrian-media-min.jpg',
      message: 'Thanks, I’ve recieved your statements',
      isMyMsg: false,
    },
    {
      message: 'Give me a couple of days to prepate estimate',
      time: '9:40 pm',
      isMyMsg: false,
    },
    {
      avatar: '/images/adrian-media-min.jpg',
      message: 'Ok that’s great, thank you!!',
      time: '9:45 pm',
      isMyMsg: true,
    },
  ]
  return (
    <ChatWrapper>
      <ChatTopBlock>
        <ChatWrapperTop>
          <ConsultantBlock>
            <ConsultantImage>
              <ConsultantImages src="/images/adrian-media-min.jpg" alt="profile" />
            </ConsultantImage>
            <ConsultantTextBlock>
              <Heading style={{ color: '#444C66', marginBottom: 6 }}>Sam Thomas</Heading>
              <Body size={BodySize.sm}>Expert Tax Consultant</Body>
            </ConsultantTextBlock>
          </ConsultantBlock>
        </ChatWrapperTop>
        <BorderWrapper></BorderWrapper>
      </ChatTopBlock>
      <ChatCenterWrapper>
        <div>
          <Heading style={{ color: '#444C66', marginBottom: 12 }}>
            {' '}
            Tax season 2020-21 is here!{' '}
          </Heading>
          <Body size={BodySize.sm} style={{ lineHeight: '20px' }}>
            To get started upload a copy of your bank statement from last financial year. After that
            we’ll get started with the estimation for your tax filing.
          </Body>
          <TextButton style={{ fontSize: 13, marginTop: 20, textTransform: 'uppercase' }}>
            {' '}
            Read guide{' '}
          </TextButton>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  style={{
                    padding: '15px 20px 15px 20px',
                    marginTop: 24,
                    textTransform: 'uppercase',
                  }}
                >
                  {' '}
                  upload statement
                </Button>
              </div>
            )}
          </Dropzone>
        </div>
        <ChatBoxBlock>
          {messages &&
            messages.map((item, i) => {
              return <ChatReplayBox key={`${i}`} item={item} />
            })}
        </ChatBoxBlock>
      </ChatCenterWrapper>
      <BottomInputWrapper>
        <ChatInput placeholder="Start typing your message.."></ChatInput>
        <IconWrapper>
          <Icon type={IconType.chatIcon} />
        </IconWrapper>
      </BottomInputWrapper>
    </ChatWrapper>
  )
}
