import React, { useState, useRef } from 'react'
import styled, { theme } from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Icon, IconType } from './Icon'
import { Button } from './Button'
import { Surface } from './Surface'
import { Body, BodySize } from './Typography/Body'
import { Input } from './Input'

const Wrapper = styled.div`
@media(min-width:728px){
  max-width: 45%;
  margin-left: 16px;
}
  width: 100%;
`
const PayLinkInput = styled.input`
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.theme.color.text.primary};
  border: none;
  outline: none;
  padding: 0px;
  border-bottom: 1px solid ${props => props.theme.color.defaultBorder};
  width: 40%;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`
const IconWrapper = styled.div`
  position: absolute;
  right: 16px;
`
const SurfaceBox2 = styled(Surface)`
  padding: 24px;
  margin-bottom: 0;
`
const SurWrapper = styled.div``
const Yourrefferal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid var(--default-border);
  padding: 13px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
`
const PayLink = styled.span`
  border-bottom: 1px solid transparent;
`
const HeadingSpan = styled.span`
  opacity: 0.7;
  border-bottom: 1px solid transparent;
`
const BodyLink = styled(Body)`
  letter-spacing: 1.13px;
  font-weight: 700;
  margin-bottom: 12px;
  opacity: 0.7;
`
const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  button {
    width: 100%;
    img {
      height: 13px;
      margin-right: 12px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--independence);
    }
  }
`
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const ReferFriendsHeader = (props: { setup?: boolean }) => {
  const [payLink, setPaylink] = useState('john')
  const [edittingPayLink, setEdittingPayLink] = useState(false)
  const inputRef = useRef()
  return (
    <Wrapper>
      <SurfaceBox2>
        <SurWrapper>
          <BodyLink size={BodySize.xs}>YOUR REFFERAL LINk</BodyLink>
          <Yourrefferal>
            <Heading size={HeadingSize.sm}>
              <HeadingSpan style={{ color: theme.color.text.secondary }}>
                startglobal.co/refferal/
              </HeadingSpan>
              {edittingPayLink ? (
                <PayLinkInput
                  ref={inputRef as any}
                  id="pay-link-input"
                  value={payLink}
                  onChange={e => setPaylink(e.target.value)}
                />
              ) : (
                <PayLink>{payLink}</PayLink>
              )}
            </Heading>
            <IconWrapper>
              {edittingPayLink ? (
                <Button variant="primary" onClick={() => setEdittingPayLink(false)}>
                  SAVE
                </Button>
              ) : (
                <div>
                  <Icon
                    type={IconType.edit}
                    style={{ cursor: 'pointer', marginRight: 24 }}
                    onClick={() => {
                      setEdittingPayLink(true)
                      setTimeout(() => document.getElementById('pay-link-input')?.focus(), 1)
                    }}
                  />
                  <Icon type={IconType.copy} style={{ cursor: 'pointer' }} />
                </div>
              )}
            </IconWrapper>
          </Yourrefferal>
          <ButtonWrapper>
            <Button variant="aliceBlue">
              <img src="/images/twitter.svg" alt="" />
              Share on twitter
            </Button>
          </ButtonWrapper>
          <InputWrapper>
            <Input
              name="shareEmail"
              label="Invite via email"
              type="email"
              placeholder="tim@example.com"
              rightSpace
              fullWidth
              formStyle
            />
            <Button type="submit" style={{ marginTop: 'auto' }}>
              SEND
            </Button>
          </InputWrapper>
        </SurWrapper>
      </SurfaceBox2>
    </Wrapper>
  )
}
