import React from 'react'
import styled from '../lib/styled'

const Wrapper = styled.div<{ active: boolean }>`
  background-color: ${props =>
    props.active ? props.theme.color.primary : props.theme.color.white};
  border: 1px solid
    ${props => (props.active ? props.theme.color.primary : props.theme.color.primaryBorder)};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  cursor: pointer;
  transition: 0.15s ease;

  :hover {
    border: 1px solid ${props => props.theme.color.primary};
  }
`

//TODO: add icon
export const Checkbox = (props: { active: boolean; onClick?: () => void }) => (
  <Wrapper active={props.active} onClick={props.onClick}>
    <img src="/images/check.png" alt="check" />
  </Wrapper>
)
