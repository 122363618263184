import React from 'react'
import { Button } from './Button'

interface FormProps {
  children: JSX.Element | JSX.Element[] | any
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  hideSubmit?: boolean
  fullWidthButton?: boolean
  style?: any
}

export const Form = (props: FormProps) => (
  <form onSubmit={props.onSubmit} style={props.style}>
    {props.children}
    {props.hideSubmit ? null : (
      <Button
        type="submit"
        style={{ marginTop: 26, width: props.fullWidthButton ? '100%' : undefined }}
      >
        SAVE CHANGES
      </Button>
    )}
  </form>
)
