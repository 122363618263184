import React, { useState, useEffect } from 'react'
import { PhoneInput as ReactPhoneInput } from 'react-phonenr-input'
import { css } from '@emotion/core'
import styled from '../lib/styled'
import { Body } from './Typography/Body'
import { InfoToolTip } from './InfoToolTip'

const Wrapper = styled.div<{
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  dark?: boolean
  formStyle?: boolean
  inputFocused: boolean
}>`
  ${props => css`
    ${props.fullWidth ? 'width: 100%;' : ''}
    ${props.leftSpace ? 'margin-left: 12px;' : ''}
    ${props.rightSpace ? 'margin-right: 12px;' : ''}
    ${props.topSpace ? 'margin-top: 26px;' : ''}
    ${props.bottomSpace ? 'margin-bottom: 26px;' : ''}
  `}

  .phone-input {
    height: 40px;
    border: 1px solid;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0px 16px;
    box-sizing: border-box;
    padding-left: 9px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
    position: relative;
    right: 2px;

    ${({ theme: { color, shadow }, dark, fullWidth, formStyle }) => css`
        ${fullWidth ? 'width: 100%;' : ''}
        ${formStyle ? '' : `box-shadow: ${shadow.default};`}
        background-color: ${dark ? color.backgroundDark : color.white};
        border-color: ${color.defaultBorder};
        color: ${color.text.primary};
        border-radius: ${formStyle ? 3 : 6}px;
      `}
      :focus {
      outline: none;
      border-color: ${props => props.theme.color.primary};
    }

    ::placeholder {
      opacity: 0.5;
    }
  }

  .flag-wrapper {
    background-color: ${props => props.theme.color.primaryLight};
    border: 1px solid ${props => props.theme.color.defaultBorder};
    border-radius: 3px 0 0 3px;
    height: 40px;
    padding: 0px 22px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    ${props => (props.inputFocused ? `border-color: ${props.theme.color.primary};` : '')}
  }

  .country-list-item {
    padding: 8px 16px;
    padding-left: 38px;
    cursor: pointer;
    line-height: 24px;
    font-size: 16px;
    color: ${props => props.theme.color.text.primary};

    :hover {
      background-color: ${props => props.theme.color.background};
    }
  }

  .country-list-item.active-country {
    background-color: ${props => props.theme.color.background};
    color: ${props => props.theme.color.primary};
  }

  .country-list {
    background-color: ${props => props.theme.color.white};
    top: 45px;
    border: 1px solid ${props => props.theme.color.defaultBorder};
    border-radius: 3px;
    box-shadow: ${props => props.theme.shadow.elevated};
    padding: 3px 0px;
  }
`
const LabelWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`
const ErrorMessage = styled(Body)`
  color: ${props => props.theme.color.error};
  margin-top: 6px;
`

interface PhoneInputProps {
  value: string
  onChange: (phone: string) => void
  label?: string
  name?: string
  dark?: boolean
  error?: string
  info?: string
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  formStyle?: boolean
  wrapperStyle?: any
  initialValue?: string
}

export const PhoneInput = (props: PhoneInputProps) => {
  const [inputFocused, setInputFocused] = useState(false)
  useEffect(() => {
    const input = document.getElementsByClassName('phone-input')[0]
    input.addEventListener('focus', () => setInputFocused(true))
    input.addEventListener('blur', () => setInputFocused(false))
    return () => {
      input.removeEventListener('focus', () => setInputFocused(true))
      input.removeEventListener('blur', () => setInputFocused(false))
    }
  }, [])

  return (
    <Wrapper
      leftSpace={props.leftSpace}
      rightSpace={props.rightSpace}
      topSpace={props.topSpace}
      bottomSpace={props.bottomSpace}
      fullWidth={props.fullWidth}
      dark={props.dark}
      formStyle={props.formStyle}
      style={props.wrapperStyle}
      inputFocused={inputFocused}
    >
      {props.label ? (
        <LabelWrapper>
          <Body dark>{props.label}</Body>
          {props.info ? <InfoToolTip content={props.info} /> : null}
        </LabelWrapper>
      ) : null}
      <ReactPhoneInput
        name={props.name}
        className="phone-input"
        value={props.value}
        onChange={props.onChange}
        defaultCountry="us"
        initialValue={props.initialValue}
      />
      {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
    </Wrapper>
  )
}
