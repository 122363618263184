import React from 'react'
import styled from '../lib/styled'
import { Heading } from './Typography/Heading'
import { Body } from './Typography/Body'
import { Icon, IconType } from './Icon'

export const Item = styled.div`
  margin-bottom: 36px;
  max-width: 220px;
`
export const ItemDetail = styled(Body)`
  margin-bottom: 12px;
`
const ItemIcon = styled(Icon)`
  position: absolute;
  left: -42px;
  top: -2px;
`

const ItemColumnIcon = styled(Icon)`
  margin-bottom: 20px;
`

export const ItemHeading = (props: { children: string; style?: any }) => (
  <Heading style={{ position: 'relative', marginBottom: 21, ...props.style }}>
    <ItemIcon type={IconType.done} size={28} />
    {props.children}
  </Heading>
)

export const ItemColumnHeading = (props: { children: string; style?: any }) => (
  <Heading style={{ display: 'flex', flexDirection: 'column', marginBottom: 21, ...props.style }}>
    <ItemColumnIcon type={IconType.done} size={28} />
    {props.children}
  </Heading>
)

interface FeatureItemProps {
  heading: string
  column?: boolean
  children: JSX.Element | JSX.Element[]
  headingStyle?: any
}

export const FeatureItem = (props: FeatureItemProps) => (
  <Item>
    {props.column ? (
      <ItemColumnHeading style={props.headingStyle}>{props.heading}</ItemColumnHeading>
    ) : (
      <ItemHeading style={props.headingStyle}>{props.heading}</ItemHeading>
    )}
    {props.children}
  </Item>
)
