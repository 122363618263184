import React from 'react'
import styled from '../lib/styled'
import { FeatureItem, ItemDetail } from './FeatureItem'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0px 8px;
`

export const PaymentsFeatures = () => (
  <Wrapper>
    <FeatureItem heading="Easy Invoicing" column headingStyle={{ marginBottom: 6 }}>
      <ItemDetail>A short supporting summary about this feature.</ItemDetail>
    </FeatureItem>
    <FeatureItem heading="Custom Pay Link" column headingStyle={{ marginBottom: 6 }}>
      <ItemDetail>A short supporting summary about this feature.</ItemDetail>
    </FeatureItem>
    <FeatureItem heading="Share Quickly" column headingStyle={{ marginBottom: 6 }}>
      <ItemDetail>A short supporting summary about this feature.</ItemDetail>
    </FeatureItem>
  </Wrapper>
)
