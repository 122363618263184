import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { Card } from '../components/Card'
import { Button } from '../components/Button'
import { InfoCard } from '../components/InfoCard'
import { StateForm } from '../components/Forms/StateForm'
import { BusinessDetailsForm } from '../components/Forms/BusinessDetailsForm'
import { AboutCompanyForm } from '../components/Forms/AboutCompanyForm'

// const UpdateDetailsCard = () => (
//   <InfoCard
//     title="You are editing your details for incorporation."
//     subtitle="Click on the button to go back and confirm the details again."
//   >
//     <Button>UPDATE DETAILS</Button>
//   </InfoCard>
// )

const ProcessStartedCard = () => (
  <InfoCard
    title="Your process has started, you cannot change any details now."
    subtitle="Please get in touch if you have any questions"
  >
    <Button>CONTACT US</Button>
  </InfoCard>
)

export const Company = () => (
  <PageLayout title="Company Information">
    {/*<UpdateDetailsCard />*/}
    <ProcessStartedCard />
    <Card title="State" subtitle="The state where your company gets inncorporated.">
      <StateForm />
    </Card>
    <Card title="Business details" subtitle="Details about your business corporation and shares.">
      <BusinessDetailsForm />
    </Card>
    <Card title="About Company" subtitle="Details about your company’s name and description.">
      <AboutCompanyForm />
    </Card>
  </PageLayout>
)
