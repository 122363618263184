import React from 'react'
import { PageLayout } from '../components/PageLayout'
import styled from '../lib/styled'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { IconType } from '../components/Icon'
import { TaxCardItem } from '../components/TaxCard'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(375px, 1fr) minmax(375px, 1fr);
  grid-column-gap: 32px;
  margin-top: 32px;
  @media(max-width:728px){
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
`

export const Tax = () => {
  return (
    <PageLayout title="Tax Filing">
      <div>
        <Heading size={HeadingSize.lg} style={{ marginBottom: 12 }}>
          Pay your State and Federal Tax
        </Heading>
        <Body size={BodySize.lg} style={{ width: '100%', maxWidth: 649, lineHeight: '30px' }}>
          In a US business, you are responsible to pay both state and federal taxes. We’ll help you
          file both in a timely manner.
        </Body>
      </div>
      <Wrapper>
        <TaxCardItem
          icon={IconType.stateIcon}
          title="State tax"
          subtitle="Direct tax levied by a state"
          text={` State income tax is a direct tax levied by a state on your income. Income is what you
            earned in or from the state. In your state of residence, it may mean all your income
            everywhere.`}
          options={['$300 fixed fee for Delaware', '$300 fixed fee for Delaware']}
          dueDate="Jun 01, 2021"
        />
        <TaxCardItem
          icon={IconType.federalTax}
          title="federal tax"
          subtitle="Levied by the Internal Revenue Service"
          text={` The federal income tax is the tax levied by the Internal Revenue Service (IRS) on the
            annual earnings of individuals, corporations, trusts, and any other legal entities with
            your company.`}
          options={['Computer by CPA', 'Point number two']}
          dueDate="Jun 01, 2021"
        />
      </Wrapper>
    </PageLayout>
  )
}
