import React from 'react'
import styled from '../lib/styled'
import { Icon, IconType } from './Icon'
import { Body } from './Typography/Body'

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.primaryLight};
  border: 1px solid ${props => props.theme.color.primaryBorder};
  border-radius: 3px;
  padding: 12px 16px 12px 16px;
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
`
const Text = styled(Body)`
  margin-left: 16px;
  margin-top: 2px;
  color: ${props => props.theme.color.text.info};
`

export const InfoBox = (props: { children: string; style?: any }) => (
  <Wrapper style={props.style}>
    <Icon type={IconType.infoClip} />
    <Text>{props.children}</Text>
  </Wrapper>
)
