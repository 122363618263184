import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import { Router } from './Router'
import { theme } from './lib/styled'

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  )
}

export default App

