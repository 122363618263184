import styled from '../../lib/styled'

export enum HeadingSize {
  xs = 12,
  sx = 14,
  sm = 16,
  md = 20,
  lg = 28,
  xl = 32,
  xxl = 44,
}

interface HeadingProps {
  size?: HeadingSize
  light?: boolean
  color?: string
}

export const Heading = styled.div<HeadingProps>`
  font-size: ${props => props.size || HeadingSize.md}px;
  font-weight: 700;
  color: ${props =>
    props.color || (props.light ? props.theme.color.white : props.theme.color.text.primary)};
`
