import styled from '@emotion/styled'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from '../components/Button'
import { PageLayout } from '../components/PageLayout'
import { ReferFriendsCard } from '../components/ReferFriendsCard'
import { Surface } from '../components/Surface'
import { Body, BodySize } from '../components/Typography/Body'
import { Heading, HeadingSize } from '../components/Typography/Heading'
const ReferWrapper = styled.div``
const ReferHeading = styled(Heading)`
  line-height: 50px;
`
const ReferBody = styled(Body)`
  width: 100%;
  @media(min-width:728px){
    max-width: 40%;
  }
  margin-top: 4px;
  margin-bottom: 48px;
  line-height: 30px;
`
const Wrapper = styled.div`
  padding: 0 16px 32px 16px;
  flex: 0 1 33.33%;
  box-sizing: border-box;
`
const WrapperBlock = styled(Surface)`
  min-height: 230px;
  max-height: 230px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin: 0;
  justify-content: space-between;
  position: relative;
  z-index: 2;
`
const CardWrapper = styled.div`
  margin-bottom: 6px;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  height: 80px;
`
const CornerImage = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
`
const OurBody = styled(Body)`
  width: 100%;
  max-width: 70%;
  line-height: 26px;
  margin-top: 17px;
`
const OurWrapper = styled(Wrapper)`
  padding: 27px 16px 32px 21px;
`

const ReferFriendsCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -16px;
  @media(max-width:728px){
    flex-direction: column;
  }
`


export const GetHelp = () => (
  <PageLayout title="Get Help">
    <ReferWrapper>
      <ReferHeading size={HeadingSize.lg}>We’re here to help you all along the way!</ReferHeading>
      <ReferBody size={BodySize.lg} color="var(--text-secondary)">
        A dummy description text can be shown here to explain about this page and article links.
      </ReferBody>
    </ReferWrapper>
    <ReferFriendsCardWrapper style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -16px' }}>
      <ReferFriendsCard
        title="Got any questions?"
        image="/images/call-volume-icon.svg"
        button="read our guide"
        to="/refer-friends/step1"
      />
      <Wrapper>
        <WrapperBlock>
          <CornerImage src="/images/purchase-corner.svg" alt="" />
          <CardWrapper>
            <LogoWrapper>
              <img src="/images/woman-lead-man-icon.svg" alt="" />
            </LogoWrapper>
            <Heading>Give us some feedback?</Heading>
          </CardWrapper>
          <NavLink to="/refer-friends/step2">
            <Button variant="aliceBlue">Email to us</Button>
          </NavLink>
        </WrapperBlock>
      </Wrapper>
      <ReferFriendsCard
        title="Need more help?"
        image="/images/group-conversation-icon.svg"
        button="Chat with us"
        to="/refer-friends/step2"
      />
      <ReferFriendsCard
        title="Want to meet other StartGlobal companies?"
        image="/images/read-message-icon.svg"
        button="Join our support group"
        to="/"
      />
      <ReferFriendsCard
        title="Anything that can’t wait?"
        image="/images/bookmark-icon.svg"
        button="Call us"
        to="/"
      />
      <OurWrapper>
        <Heading>Our mailing address</Heading>
        <OurBody size={BodySize.md}>
          First floor, 241 Newton Avenue Los Angeles , CA United States - 887979
        </OurBody>
      </OurWrapper>
    </ReferFriendsCardWrapper>
  </PageLayout>
)
