import React, { useState, useEffect, useContext } from 'react'
import { PageLayout } from '../components/PageLayout'
import { Start } from './Start'
import { StartOA } from './StartOA'


export const OperatingAgreementDetailCreate = () => {
    return (
    <PageLayout title={'Operating Agreement'}>
        <StartOA/>
        </PageLayout>
        )

}