import React, { useEffect } from 'react'
import anime from 'animejs'
import { useForm } from 'react-hook-form'
import styled from '../../lib/styled'
import { StartFormLayout, StartFormProps } from '../StartFormLayout'
import { PersonalDetailsExtendedForm, FormData } from '../Forms/PersonalDetailsExtendedForm'
import { useStartForm } from '../StartFormProvider'

const contentId = 'about-you-content'
const cornerImageId = 'about-you-corner-image'
const cornerImageUserId = 'about-you-corner-image-user'
const cornerImageLine1Id = 'about-you-corner-image-line-1'
const cornerImageLine2Id = 'about-you-corner-image-line-2'

const LineImage = styled.img`
  position: absolute;
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -o-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
`

export const AboutYou = (props: StartFormProps<FormData>) => {
  const startForm = useStartForm()
  const formHook = useForm<FormData>({ defaultValues: startForm.aboutYou })
  const onSubmit = formHook.handleSubmit(values => {
    startForm.setAboutYou(values)
    console.log(values)
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart',
    })
    setTimeout(() => props.onSubmit(values), 500)
  })
  const goBack = () => {
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart',
    })
    setTimeout(() => props.goBack(), 500)
  }

  useEffect(() => {
    anime
      .timeline()
      .add(
        {
          targets: `#${contentId}`,
          translateY: [50, 0],
          opacity: [0, 1],
          duration: 800,
          easing: 'easeOutQuart',
        },
        300
      )
      .add(
        {
          targets: `#${cornerImageId}`,
          opacity: [0, 1],
          duration: 500,
          easing: 'easeOutQuart',
        },
        300
      )
      .add(
        {
          targets: `#${cornerImageLine1Id}`,
          scaleX: [0, 1],
          duration: 500,
          easing: 'easeOutQuart',
        },
        800
      )
      .add(
        {
          targets: `#${cornerImageLine2Id}`,
          scaleX: [0, 1],
          duration: 500,
          easing: 'easeOutQuart',
        },
        1200
      )
      .add(
        {
          targets: `#${cornerImageUserId}`,
          scale: [0, 1],
          duration: 500,
          easing: 'easeOutQuart',
        },
        1200
      )
    return () => {}
  }, [])

  return (
    <StartFormLayout
      title="About you"
      description="A bit of personal details about you."
      contentId={contentId}
      onNextClick={onSubmit}
      onPrevClick={goBack}
      sidebarImage={
        <div
          id={cornerImageId}
          style={{ position: 'absolute', left: 70, bottom: 100, zIndex: 1, opacity: 0 }}
        >
          <img src="/images/about-you-corner/paper.svg" alt="" />
          <LineImage
            id={cornerImageLine1Id}
            src="/images/about-you-corner/line-1.svg"
            alt=""
            style={{ top: 28, left: 22 }}
          />
          <LineImage
            id={cornerImageLine2Id}
            src="/images/about-you-corner/line-2.svg"
            alt=""
            style={{ top: 46, left: 22 }}
          />
          <img
            id={cornerImageUserId}
            src="/images/about-you-corner/user.svg"
            alt=""
            style={{ position: 'absolute', top: 59, left: 53 }}
          />
        </div>
      }
    >
      <PersonalDetailsExtendedForm onSubmit={onSubmit} formHookProps={formHook} />
    </StartFormLayout>
  )
}
