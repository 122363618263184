import React, { useState } from 'react'
import { PageLayout } from '../components/PageLayout'
import { StripeSettings } from '../components/StripeSettings'
import { BrandSettings } from '../components/BrandSettings'

const STRIPE_USER = {
  firstName: 'Jonnathan',
  lastName: 'Doe',
  email: 'John@company.com',
}
const LOGO_SRC = '/images/logo-dark.svg'

export const PaymentSettings = () => {
  const [accounts, setAccounts] = useState([] as any)
  const connectStripe = () => setAccounts([STRIPE_USER])
  return (
    <PageLayout title="Payment Settings">
      <StripeSettings accounts={accounts} onConnect={connectStripe} />
      <BrandSettings logoSrc={LOGO_SRC} />
    </PageLayout>
  )
}
