import React, { forwardRef, useRef, useEffect, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { css } from '@emotion/core'
import styled from '../lib/styled'
import { Body } from './Typography/Body'
import { InfoToolTip } from './InfoToolTip'
import { Icon, IconType } from './Icon'

const SelectWrapper = styled.div`
  display: flex;
  position: relative;

  select {
    display: none;
  }
`

const Wrapper = styled.div<{
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  width?: string
}>`
  ${props => css`
    ${props.width ? `width: ${props.width};` : props.fullWidth ? 'width: 100%;' : ''}
    ${props.leftSpace ? 'margin-left: 12px;' : ''}
    ${props.rightSpace ? 'margin-right: 12px;' : ''}
    ${props.topSpace ? 'margin-top: 26px;' : ''}
    ${props.bottomSpace ? 'margin-bottom: 26px;' : ''}
  `}
`
const AddonStart = styled.span<{ open: boolean }>`
  background-color: ${props => props.theme.color.background};
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 3px 0 0 3px;
  height: 40px;
  padding: 0px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.open ? `border-color: ${props.theme.color.primary};` : '')}
`
const SelectField = styled.div<{
  dark?: boolean
  formStyle?: boolean
  addonStart: boolean
  open: boolean
}>`
  height: 40px;
  border: 1px solid;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;

  ${({ theme: { color, shadow }, dark, addonStart, formStyle }) => css`
      ${formStyle ? '' : `box-shadow: ${shadow.default};`}
      background-color: ${dark ? color.backgroundDark : color.white};
      border-color: ${color.defaultBorder};
      color: ${color.text.primary};
      border-radius: ${formStyle ? 3 : 6}px;

      ${
        addonStart
          ? css`
              padding-left: 7px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              border-left: none;
            `
          : ''
      }
    `}

  ${props => (props.open ? `border-color: ${props.theme.color.primary};` : '')}

  ::placeholder {
    opacity: 0.5;
  }
`
const LabelWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`
const ErrorMessage = styled(Body)`
  color: ${props => props.theme.color.error};
  margin-top: 6px;
`
const ArrowDown = styled(Icon)`
  position: absolute;
  top: 8px;
  right: 2px;
  padding: 9px 14px;
  border-left: 1px solid ${props => props.theme.color.defaultBorder};
  pointer-events: none;
  user-select: none;
`
const OptionsList = styled.div<{ open: boolean }>`
  ${props => (!props.open ? 'display: none;' : '')}
  background-color: ${props => props.theme.color.white};
  position: absolute;
  top: 45px;
  left: 0;
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 3px;
  box-shadow: ${props => props.theme.shadow.elevated};
  width: 100%;
  max-height: 200px;
  overflow: auto;
  padding: 3px 0px;
  z-index: 1;
`
const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  :hover {
    background-color: ${props => props.theme.color.background};
  }
`

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  dark?: boolean
  info?: string
  error?: string
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  formStyle?: boolean
  disabled?: boolean
  width?: string
  onSelectOption?: (value: string) => void
  addonStart?: {
    content: any
    style?: any
  }
  wrapperStyle?: any
  labelStyle?: any
  options: Array<{ label: string | JSX.Element; value: string }>
}

export const Select = forwardRef((props: SelectProps, ref: any) => {
  const selectRef = useRef<HTMLSelectElement | null>()
  const menuRef = useRef<any>()
  const [selectOptions, setSelectOptions] = useState<JSX.Element[]>([])
  const [optionListOpen, setOptionListOpen] = useState(false)

  useOnClickOutside(menuRef, () => setOptionListOpen(false))

  useEffect(() => {
    const setOptions = () => {
      const options = [] as JSX.Element[]
      const value = selectRef?.current?.value

      const addOption = (i: number) => {
        const o = selectRef.current?.options[i]
        if (o) {
          const isSelected = value === o.value
          if (!o.disabled) {
            options.push(
              <Option
                onClick={() => {
                  if (selectRef.current) {
                    selectRef.current.value = o.value as string
                    setOptions()
                    if (props.onSelectOption) {
                      props.onSelectOption(selectRef.current.value)
                    }
                  }
                }}
              >
                <Body
                  dark
                  color={isSelected ? 'var(--primary)' : undefined}
                  style={{ lineHeight: '24px', ...props.labelStyle }}
                >
                  {o.label}
                </Body>
                {isSelected ? <Icon type={IconType.selected} /> : null}
              </Option>
            )
          }
        }
      }

      for (var i = 0; i < (selectRef.current?.options?.length || 0); i++) {
        addOption(i)
      }

      setSelectOptions(options)
    }
    setOptions()
  }, [selectRef, props.options, props])

  return (
    <Wrapper
      leftSpace={props.leftSpace}
      rightSpace={props.rightSpace}
      topSpace={props.topSpace}
      bottomSpace={props.bottomSpace}
      fullWidth={props.fullWidth}
      width={props.width}
      style={props.wrapperStyle}
    >
      {props.label ? (
        <LabelWrapper>
          <Body dark style={props.labelStyle}>
            {props.label}
          </Body>
          {props.info ? <InfoToolTip content={props.info} /> : null}
        </LabelWrapper>
      ) : null}
      <SelectWrapper style={{ display: 'flex', position: 'relative' }}>
        <select
          {...props}
          ref={r => {
            selectRef.current = r
            if (typeof ref === 'function') {
              ref(r)
            } else {
              ref = r
            }
          }}
        >
          {props.placeholder ? (
            <option disabled selected value="">
              {props.placeholder}
            </option>
          ) : null}
          {props.options.map(({ label, value }) => (
            <option value={value}>{label}</option>
          ))}
        </select>
        {props.addonStart ? (
          <AddonStart open={optionListOpen} style={props.addonStart.style}>
            {props.addonStart.content}
          </AddonStart>
        ) : null}
        <SelectField
          ref={menuRef}
          dark={props.dark}
          formStyle={props.formStyle}
          addonStart={!!props.addonStart}
          open={optionListOpen}
          onClick={() => (props.disabled ? null : setOptionListOpen(!optionListOpen))}
        >
          {props.placeholder && selectRef?.current?.selectedOptions[0]?.disabled ? (
            <Body style={{ lineHeight: '24px', padding: '8px 0px' }}>{props.placeholder}</Body>
          ) : (
            <Body dark style={{ lineHeight: '24px', padding: '8px 0px' }}>
              {selectRef?.current?.selectedOptions[0]?.label}
            </Body>
          )}
          <OptionsList open={optionListOpen}>{selectOptions}</OptionsList>
        </SelectField>
        <ArrowDown type={IconType.chevronDownLarge} />
      </SelectWrapper>
      {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
    </Wrapper>
  )
})
