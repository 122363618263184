import React from 'react'
import styled from '../lib/styled'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { Icon, IconType } from '../components/Icon'
import { Button } from '../components/Button'

const StripeBox = styled.div`
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 6px;
  @media(max-width:728px){
    gap: 1em;
    flex-direction: column;
    align-items: flex-start;
  }
`
const StripeIcon = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 8px;
  margin-right: 12px;
`

interface StripeAccountProps {
  noAccount?: boolean
  firstName?: string
  lastName?: string
  email?: string
  onConnect?: () => void
}

export const StripeAccount = (props: StripeAccountProps) => (
  <StripeBox>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StripeIcon src="/images/stripe.png" />
      {props.noAccount ? (
        <div>
          <Heading size={HeadingSize.sm}>No account connected</Heading>
        </div>
      ) : (
        <div style={{ marginBottom: 3 }}>
          <Heading style={{ marginBottom: 2 }} size={HeadingSize.sm}>
            {`${props.firstName} ${props.lastName}`}
            <Icon type={IconType.doneMedium} style={{ position: 'relative', left: 8, top: 4 }} />
          </Heading>
          <Body size={BodySize.sm}>{props.email}</Body>
        </div>
      )}
    </div>
    {props.noAccount ? (
      <Button
        outline
        small
        variant="primary"
        style={{ height: 'fit-content' }}
        onClick={props.onConnect}
      >
        CONNECT
      </Button>
    ) : (
      <Button light variant="error" small style={{ height: 'fit-content' }}>
        DISCONNECT
      </Button>
    )}
  </StripeBox>
)
