import React, { forwardRef } from 'react'
import { css } from '@emotion/core'
import styled from '../lib/styled'
import { Body } from './Typography/Body'
import { InfoToolTip } from './InfoToolTip'

const Wrapper = styled.div<{
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
}>`
  ${props => css`
    ${props.fullWidth ? 'width: 100%;' : ''}
    ${props.leftSpace ? 'margin-left: 12px;' : ''}
    ${props.rightSpace ? 'margin-right: 12px;' : ''}
    ${props.topSpace ? 'margin-top: 26px;' : ''}
    ${props.bottomSpace ? 'margin-bottom: 26px;' : ''}
  `}
`

const TextAreaField = styled.textarea<{
  dark?: boolean
  fullWidth?: boolean
  formStyle?: boolean
}>`
  border: 1px solid;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
  box-sizing: border-box;

  ${({ theme: { color, shadow }, dark, fullWidth, formStyle }) => css`
      ${fullWidth ? 'width: 100%;' : ''}
      ${formStyle ? '' : `box-shadow: ${shadow.default};`}
      background-color: ${dark ? color.backgroundDark : color.white};
      border-color: ${color.defaultBorder};
      color: ${color.text.primary};
      border-radius: ${formStyle ? 3 : 6}px;
    `}
    :focus {
    outline: none;
    border-color: ${props => props.theme.color.primary};
  }

  ::placeholder {
    opacity: 0.5;
  }
`
const LabelWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`
const ErrorMessage = styled(Body)`
  color: ${props => props.theme.color.error};
  margin-top: 6px;
`

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  dark?: boolean
  info?: string
  error?: string
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  formStyle?: boolean
  wrapperStyle?: any
}

export const TextArea = forwardRef((props: TextAreaProps, ref: any) => (
  <Wrapper
    leftSpace={props.leftSpace}
    rightSpace={props.rightSpace}
    topSpace={props.topSpace}
    bottomSpace={props.bottomSpace}
    fullWidth={props.fullWidth}
    style={props.wrapperStyle}
  >
    {props.label ? (
      <LabelWrapper>
        <Body dark>{props.label}</Body>
        {props.info ? <InfoToolTip content={props.info} /> : null}
      </LabelWrapper>
    ) : null}
    <TextAreaField ref={ref} {...props} fullWidth={props.fullWidth} formStyle={props.formStyle} />
    {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
  </Wrapper>
))
