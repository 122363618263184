import React from 'react'
import { theme } from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'

export const MoneyAmount = (props: { children: string; size: HeadingSize; style?: any }) => (
  <Heading size={props.size} style={props.style}>
    <span>{props.children.slice(0, -3)}</span>
    <span style={{ color: theme.color.text.secondary, opacity: '0.7' }}>
      {props.children.slice(-3)}
    </span>
  </Heading>
)
