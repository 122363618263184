import React from 'react'
import { useForm } from 'react-hook-form'
import styled from '../lib/styled'
import { Heading } from '../components/Typography/Heading'
import { Icon, IconType } from '../components/Icon'
import { Button } from '../components/Button'
import { CreateClientForm, FormData } from '../components/Forms/CreateClientForm'

const SideCardWrapper = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => `rgba(255,255,255,${props.open ? '0.5' : 0})`};
  ${props => (props.open ? '' : 'pointer-events: none;')};
  transition: background 0.2s ease;
`

const SideCard = styled.div<{ open: boolean }>`
  background-color: ${props => props.theme.color.white};
  box-shadow: -4px -2px 20px 0 rgba(0, 0, 0, 0.06);
  @media (min-width: 728px){
    width: 620px;
  }
  height: 100%;
  float: right;
  transition: transform 0.2s ease;
  transform: translateX(${props => (props.open ? '0px' : '640px')});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const SideCardTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  user-select: none;
`
const FormWrapper = styled.div`
  padding: 16px 32px;
  overflow: auto;
  height: 100%;
`
const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 728px){
    justify-content: space-between;
  }
  padding: 16px 32px;
  border-top: 1px solid ${props => props.theme.color.defaultBorder};
`

interface ClientSideCardProps {
  open: boolean
  close: () => void
  selectedClient?: any
}

const ClientSideCardContent = (props: ClientSideCardProps) => {
  const formHook = useForm<FormData>({
    defaultValues: props.selectedClient || {},
  })
  const onSubmit = formHook.handleSubmit(values => {
    console.log('VALUES', values)
  })
  return (
    <FormWrapper>
      <CreateClientForm
        initialValues={props.selectedClient}
        onSubmit={onSubmit}
        formHookProps={formHook}
        onCancel={props.close}
        noActions
      />
    </FormWrapper>
  )
}

export const ClientSideCard = (props: ClientSideCardProps) => (
  <SideCardWrapper open={props.open} onClick={props.close} style={{ height: '100%' }}>
    <SideCard open={props.open} onClick={e => e.stopPropagation()}>
      <div style={{ padding: 32, paddingBottom: 0 }}>
        <SideCardTop>
          <Heading>{props.selectedClient ? 'Edit client' : 'Create client'}</Heading>
          <Icon type={IconType.close} style={{ cursor: 'pointer' }} onClick={props.close} />
        </SideCardTop>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div style={{ height: '100%' }}>
          {props.open ? <ClientSideCardContent {...props} /> : null}
        </div>
        <Actions>
          <Button light style={{ marginRight: 16 }} type="button" onClick={props.close}>
            CANCEL
          </Button>
          <Button>CREATE CLIENT</Button>
        </Actions>
      </div>
    </SideCard>
  </SideCardWrapper>
)
