import React, { forwardRef } from 'react'
import styled from '../lib/styled'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from './Icon'

const Wrapper = styled.div<{ open: boolean }>`
  background-color: ${props => props.theme.color.white};
  width: 400px;
  min-width: 400px;
  height: 100vh;
  box-sizing: border-box;
  padding: 50px 40px;
  box-shadow: -4px -2px 20px 0 rgba(0, 0, 0, 0.06);
  transform: translateX(${props => (props.open ? 0 : 400)}px);
  transition: 0.3s ease-in-out;
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: scroll;
`
const Divider = styled.div`
  margin: 24px 0px;
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.color.defaultBorder};
`
const Title = styled(Body)`
  margin-bottom: 16px;
`

export const HelpOverlay = forwardRef((props: { open: boolean; close: () => void }, ref: any) => (
  <Wrapper ref={ref} open={props.open}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Body bold color="var(--primary)" style={{ fontSize: 18 }}>
        HELP GUIDE
      </Body>
      <Icon type={IconType.close} onClick={props.close} style={{ cursor: 'pointer' }} />
    </div>
    <Divider />
    <Title bold dark size={BodySize.lg}>
      Choosing the right state for incorporation
    </Title>
    <Body style={{ marginBottom: 32 }}>
      Delaware acquired its status as a corporate haven in the early 20th century. Following the
      example of New Jersey, which enacted corporate-friendly laws at the end of the 19th century to
      attract businesses from New York, Delaware adopted on March 10, 1899, a general incorporation
      act aimed at attracting more businesses.
    </Body>
    <Title bold dark size={BodySize.lg}>
      What Is a Delaware Corporation?
    </Title>
    <Body style={{ marginBottom: 32 }}>
      A Delaware corporation is a company that is legally registered in the state of Delaware but
      may conduct business in any state.
      <br />
      <br />
      Delaware first began to adapt its laws in the late 19th century, making changes that would
      attract businesses away from other states such as New York.
      <br />
      <br />
      Delaware became a respected state in which to incorporate, even if the majority of a company's
      business was conducted outside the state.
    </Body>
    <Title bold dark size={BodySize.lg}>
      What Is a Wyoming Corporation?
    </Title>
    <Body style={{ marginBottom: 32 }}>
      Wyoming LLC fees and other expenses are less than most other states. Wyoming has no business
      license fees or officer filing fees. When you form a Wyoming LLC, we can deliver a quality
      company package for much less than you would pay in Nevada or Delaware.
    </Body>
  </Wrapper>
))
