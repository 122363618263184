import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import styled from '../lib/styled'
import { Surface } from './Surface'
import { Heading } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from './Icon'
import { CardStatus } from './Card'
import { CardBadge } from './CardBadge'
import MediaQuery from 'react-responsive'

export { CardStatus } from './Card'

const Wrapper = styled(Surface)<{ disabled?: boolean; style?: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  ${props => (props.disabled ? 'opacity: 0.75;' : '')}
  ${props => props.style}
  .Collapsible {
    width: 100%;
  }

  .Collapsible__contentOuter {
    width: 100%;
  }
`

const TitleWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => (props.disabled ? '' : 'cursor: pointer;')}
  @media(max-width:728px){
    // flex-direction: column;
    // gap: 1em;
    // align-items: flex-start;
  }
`
const CardItemsWrapper = styled.div`
  width: 100%;
`

interface CardProps {
  children?: JSX.Element | JSX.Element[]
  title: string
  description?: string
  icon?: IconType
  iconRight?: IconType
  status?: CardStatus
  badgeLabel?: string
  disabled?: boolean
  style?: any
  onDelete?: () => void
}

const statusLabels = {
  [CardStatus.success]: 'Completed',
  [CardStatus.failure]: 'Failed',
  [CardStatus.pending]: 'Pending',
  [CardStatus.draft]: 'Draft',
}

export const CollapsableCard = (props: CardProps) => {
  const [open, setOpen] = useState(false)
  return (
    <Wrapper disabled={props.disabled} style={props.style}>
      <TitleWrapper disabled={props.disabled} onClick={() => setOpen(!open)}>
        <div style={{ display: 'flex' }}>
          {props.icon ? <Icon type={props.icon} style={{ marginRight: 16 }} /> : null}
          <Heading>{props.title}</Heading>
        </div>
        <MediaQuery query='(min-width:728px'>
          <div style={{ display: 'flex' }}>
            {props.status ? (
              <CardBadge
                status={props.status}
                label={props.badgeLabel }
                // || statusLabels[props.status]
              />
            ) : null}
            {props.disabled ? null : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.onDelete && open ? (
                  <div
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={props.onDelete}
                  >
                    <Icon type={IconType.trash} style={{ marginRight: 8, marginBottom: 2 }} />
                    <Body size={BodySize.sm}>Remove</Body>
                  </div>
                ) : null}
                {props.iconRight ? (
                  <div>{props.icon ? <Icon type={props.iconRight} /> : null}</div>
                ) : (
                  <Icon
                    type={open ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge}
                    style={{ marginLeft: 24, userSelect: 'none' }}
                  />
                )}
              </div>
            )}
          </div>
        </MediaQuery>
        <MediaQuery query='(max-width:728px'>
        <div style={{ display: 'flex',justifyContent:'space-between'}}>
          {props.status ? (
            <CardBadge
              status={props.status}
              label={props.badgeLabel }
              // || statusLabels[props.status]
            />
          ) : null}
          {props.disabled ? null : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.onDelete && open ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={props.onDelete}
                >
                  <Icon type={IconType.trash} style={{ marginRight: 8, marginBottom: 2 }} />
                  <Body size={BodySize.sm}>Remove</Body>
                </div>
              ) : null}
              {props.iconRight ? (
                <div>{props.icon ? <Icon type={props.iconRight} /> : null}</div>
              ) : (
                <Icon
                  type={open ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge}
                  style={{ marginLeft: 24, userSelect: 'none' }}
                />
              )}
            </div>
          )}
        </div>
         </MediaQuery>
      </TitleWrapper>
      {props.children || props.description ? (
        <Collapsible open={open} easing="ease" trigger={''} transitionTime={150}>
          <CardItemsWrapper>
            {props.description ? (
              <Body style={{ marginTop: 21, width: '70%' }}>{props.description}</Body>
            ) : null}
            {props.children}
          </CardItemsWrapper>
        </Collapsible>
      ) : null}
    </Wrapper>
  )
}
