import React from 'react'
import MediaQuery from 'react-responsive'
import styled, { css } from '../lib/styled'
import { Button } from './Button'
import { Body } from './Typography/Body'

const ROW_COLS = '3fr 3fr 3fr'
const TransactionsTableWrapper = styled.div`
  width: 100%;
`
const TableRow = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.default};
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.white};
  display: grid;
  padding: 16px 0px;
  grid-template-columns: ${ROW_COLS};
  margin-bottom: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  .copy-link-button {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    right: -42px;
    top: 8px;
    padding: 12px;
  }

  :hover {
    border: 1px solid ${props => props.theme.color.defaultBorder};
    box-shadow: ${props => props.theme.shadow.elevated};
  }
  @media(max-width:728px){
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    padding:16px 20px;
  }
`
const TableCell = styled.div<{ textBold?: boolean; flexEnd?: boolean }>`
  border-color: #fff;
  color: ${props => props.theme.color.independence};
  @media(min-width:728px){
    padding: 0px 20px;
  }
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${props =>
    props.textBold &&
    css`
      background: var(--white-color);
      color: var(--gray-color);
      border-color: var(--gray-border-color);
    `}
  ${props =>
    props.flexEnd &&
    css`
      justify-content: flex-end;
    `}
`
const TableCellBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const TableCellBox = styled.div`
  color: var(--independence);
  font-weight: bold;
`
const TableHeadWrapper = styled.div`
  display: grid;
  grid-template-columns: ${ROW_COLS};
  margin-bottom: 12px;
`
const TableHead = (props: { children: string; style?: any; textAlign?: boolean }) => (
  <><MediaQuery query='(max-width:727px)'>
    <Body
      style={{ fontWeight: 'bold', paddingRight: '20px', ...props.style }}
      textAlign={props.textAlign}
    >
      {props.children}
    </Body>
  </MediaQuery>
  <MediaQuery query='(min-width:727px)'>
      <Body
        style={{ fontWeight: 'bold', padding: '0px 20px', ...props.style }}
        textAlign={props.textAlign}
      >
        {props.children}
      </Body>
    </MediaQuery></>
  
)
const TableCellWrapper = styled.div<{ light?: boolean; secondary?: boolean; background?: boolean }>`
  background-color: var(--primary-light);
  border-radius: 13px;
  @media(min-width:728px){
    margin-left: auto;
    margin-right: 16px;
  }
  color: var(--primary);
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: fit-content;
  ${props =>
    props.light &&
    css`
      background-color: var(--warning-light);
      color: var(--warning);
    `}
  ${props =>
    props.secondary &&
    css`
      background-color: var(--secondary-light);
      color: var(--secondary);
    `}
    ${props =>
      props.background &&
      css`
        background-color: var(--background);
        color: var(--text-secondary);
      `}
`
const Dot = styled.div<{ light?: boolean; secondary?: boolean; background?: boolean }>`
  background-color: var(--primary);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-right: 6px;
  ${props =>
    props.light &&
    css`
      background-color: var(--warning);
    `}
  ${props =>
    props.secondary &&
    css`
      background-color: var(--secondary);
    `}
    ${props =>
      props.background &&
      css`
        background-color: var(--text-secondary);
      `}
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    width: fit-content;
    margin-right: 16px;
  }
  @media(max-width:728px){
    gap:1em;
  }
`

interface ReferFriendsTableProps {
  textBold?: boolean
  flexEnd?: boolean
  type?: boolean
  secondary?: boolean
  items: Array<{
    transactionsName: string
    date?: string
    status?: string
    light?: boolean
    secondary?: boolean
    background?: boolean
  }>
}

export const ReferFriendsTableSM = (props: ReferFriendsTableProps) => {
  return (
    <TransactionsTableWrapper>
      {/* <TableHeadWrapper>
        <TableHead>Invitee</TableHead>
        <TableHead>Date invited</TableHead>
        <TableHead textAlign>Status</TableHead>
      </TableHeadWrapper> */}
      {props?.items?.map(item => {
        return (
          <TableRow>
            <TableCell textBold>
            <TableHead>Invitee</TableHead>
              <TableCellBoxWrapper>
                <TableCellBox>{item.transactionsName}</TableCellBox>
              </TableCellBoxWrapper>
            </TableCell>
            <TableCell>
            <TableHead>Date invited</TableHead>
              <TableCellBox>{item.date}</TableCellBox>
            </TableCell>

            {item?.light ? (
              <TableCellWrapper light>
                <Dot light></Dot>
                {item.status}
              </TableCellWrapper>
            ) : item?.secondary ? (
              <TableCellWrapper secondary>
                <Dot secondary></Dot>
                {item.status}
              </TableCellWrapper>
            ) : item?.background ? (
              <Wrapper>
                <TableCellWrapper background>
                  <Dot background></Dot>
                  {item.status}
                </TableCellWrapper>
                <Button>RESEND</Button>
              </Wrapper>
            ) : (
              <TableCellWrapper>
                <Dot></Dot>
                {item.status}
              </TableCellWrapper>
            )}
          </TableRow>
        )
      })}
    </TransactionsTableWrapper>
  )
}
