import { css } from '@emotion/core'
import styled from '../lib/styled'

export const Skeleton = styled.div<{ primary?: boolean; big?: boolean; width: string }>`
  border-radius: 2px;

  ${props => css`
    width: ${props.width};
    height: ${props.big ? 25 : 17}px;
    background-color: ${props.primary
      ? props.theme.color.primaryDark
      : props.theme.color.defaultBorder};
  `}
`
