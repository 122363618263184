import React from 'react'
import styled from '../lib/styled'
import { Surface } from '../components/Surface'
import { Heading } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { TextButton } from '../components/TextButton'
import { StripeAccount } from '../components/StripeAccount'
import MediaQuery from 'react-responsive'

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 96px;
  @media (max-width: 768px){
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
`

interface StripeSettingsProps {
  accounts?: Array<{
    firstName: string
    lastName: string
    email: string
  }>
  onConnect?: () => void
}

export const StripeSettings = (props: StripeSettingsProps) => (
  <Surface style={{ padding: 32 }}>
    <Wrapper>
      <div>
        <Heading style={{ marginBottom: 8 }}>Stripe Account</Heading>
        <MediaQuery query='(max-width:727px)'>
          <Body style={{ lineHeight: '24px' }}>
            This is your stripe account connected to recieve all payments via Quickpay.
          </Body>
          </MediaQuery>
        <MediaQuery query='(min-width:727px)'>
          <Body style={{ maxWidth: 236, lineHeight: '24px' }}>
            This is your stripe account connected to recieve all payments via Quickpay.
          </Body>
        </MediaQuery>
      </div>
      {props.accounts && props.accounts.length ? (
        <div>
          <Body>Connected to Stripe</Body>
          {props.accounts?.map(account => (
            <StripeAccount {...account} />
          ))}
          <TextButton style={{ fontSize: BodySize.sm }}>ADD A NEW ACCOUNT</TextButton>
        </div>
      ) : (
        <div>
          <Body>Connect to Stripe</Body>
          <StripeAccount noAccount onConnect={props.onConnect} />
          <TextButton style={{ fontSize: BodySize.sm }}>ADD A NEW ACCOUNT</TextButton>
        </div>
      )}
    </Wrapper>
  </Surface>
)
