import React from 'react'
import { Heading, HeadingSize } from './Typography/Heading'

export const TransactionsAmount = (props: { children: string; size: HeadingSize; style?: any }) => {
  return (
    <Heading size={props.size} style={props.style}>
      <span>{props.children.slice(0, -3)}</span>
      <span style={{ opacity: '0.7' }}>{props.children.slice(-3)}</span>
    </Heading>
  )
}
