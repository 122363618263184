import React, { useState, useRef } from 'react'
import styled, { theme } from '../lib/styled'
import { Surface } from './Surface'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from './Icon'
import { CardBadge } from './CardBadge'
import { CardStatus } from './Card'
import { TextButton } from './TextButton'
import { MoneyAmount } from './MoneyAmount'
import { Button } from './Button'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(375px, 1fr) minmax(375px, 1.5fr);
  grid-column-gap: 32px;
  @media (max-width: 768px){
    display: flex;
    flex-direction: column;
  }
`
const DollarIcon = styled(Icon)`
  position: absolute;
  left: -20px;
  top: 12px;
`
const LearnMoreWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.primaryLight};
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 16px;
`
const PayLinkInput = styled.input`
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.color.text.primary};
  border: none;
  outline: none;
  padding: 0px;
  border-bottom: 1px solid ${props => props.theme.color.defaultBorder};

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`

export const PaymentsHeader = (props: { setup?: boolean }) => {
  const balance = '84,254.58'
  const pending = '1254.50'
  const draft = '00.00'
  const [payLink, setPaylink] = useState('publicnote')
  const [edittingPayLink, setEdittingPayLink] = useState(false)
  const inputRef = useRef()
  return (
    <Wrapper>
      <Surface style={{ padding: '30px 42px', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Body style={{ fontWeight: 'bold', opacity: '0.7' }}>TOTAL RECIEVED</Body>
          <div style={{ position: 'relative', marginBottom: 12, marginTop: 6 }}>
            <DollarIcon type={IconType.dollar} />
            <MoneyAmount size={HeadingSize.xxl}>{balance}</MoneyAmount>
          </div>
          <CardBadge
            label="+10% since last month"
            status={CardStatus.success}
            noIcon
            style={{ display: 'inline-block' }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <CardBadge label="Pending" status={CardStatus.pending} noBackgroundColor />
            <MoneyAmount
              size={HeadingSize.md}
              style={{ marginTop: 8 }}
            >{`$${pending}`}</MoneyAmount>
          </div>
          <div>
            <CardBadge label="In drafts" status={CardStatus.draft} noBackgroundColor />
            <MoneyAmount size={HeadingSize.md} style={{ marginTop: 8 }}>{`$${draft}`}</MoneyAmount>
          </div>
        </div>
      </Surface>
      <Surface
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Heading size={HeadingSize.md} style={{ marginTop: 4 }}>
            <img
              src="/images/payments-corner.svg"
              alt=""
              width={24}
              style={{ position: 'relative', top: 6, marginRight: 12 }}
            />
            <span style={{ color: theme.color.text.secondary, opacity: '0.7' }}>quickpay.to/</span>
            {edittingPayLink ? (
              <PayLinkInput
                ref={inputRef as any}
                id="pay-link-input"
                value={payLink}
                onChange={e => setPaylink(e.target.value)}
              />
            ) : (
              <span>{payLink}</span>
            )}
          </Heading>
          <div style={{ position: 'absolute', right: 0 }}>
            {edittingPayLink ? (
              <Button outline variant="primary" onClick={() => setEdittingPayLink(false)}>
                SAVE
              </Button>
            ) : (
              <div>
                <Icon
                  type={IconType.edit}
                  style={{ cursor: 'pointer', marginRight: 32 }}
                  onClick={() => {
                    setEdittingPayLink(true)
                    setTimeout(() => document.getElementById('pay-link-input')?.focus(), 1)
                  }}
                />
                <Icon type={IconType.copy} style={{ cursor: 'pointer' }} />
              </div>
            )}
          </div>
        </div>
        <LearnMoreWrapper>
          <Body size={BodySize.xs}>
            Quickpay lets you recieve payements on the fly. You can generate invoice or share the
            payment link to request the payment.
          </Body>
          <TextButton style={{ fontSize: 12, marginTop: 12 }}>LEARN MORE</TextButton>
        </LearnMoreWrapper>
      </Surface>
    </Wrapper>
  )
}
