import React, { useState, useRef } from 'react'
import styled, { theme } from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from './Icon'
import { CardBadge } from './CardBadge'
import { CardStatus } from './Card'
import { MoneyAmount } from './MoneyAmount'
import { Button } from './Button'

const Wrapper = styled.div``
const DollarIcon = styled(Icon)`
  margin-top: 7px;
`
const PayLinkInput = styled.input`
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.color.text.primary};
  border: none;
  outline: none;
  padding: 0px;
  border-bottom: 1px solid ${props => props.theme.color.defaultBorder};

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`
const WrapperBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`
const Ruppay = styled.div`
  margin-bottom: 12px;
  margin-top: 6px;
  display: flex;
  align-items: flex-start;
`
const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Yourrefferal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid var(--default-border);
  padding: 13px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
`
const PayLink = styled.span`
  border-bottom: 1px solid transparent;
`
const HeadingSpan = styled.span`
  opacity: 0.7;
  border-bottom: 1px solid transparent;
`
const BodyLink = styled(Body)`
  letter-spacing: 1.13px;
  font-weight: 700;
  margin-bottom: 12px;
  opacity: 0.7;
`
const IconWrapper = styled.div`
  position: absolute;
  right: 16px;
`
const Pay = styled.div`
  color: var(--primary);
  font-size: 12px;
  letter-spacing: 0.92px;
  line-height: 14px;
  text-align: right;
  text-transform: uppercase;
  font-weight: 700;
`

export const PaymentsRecieved = (props: { setup?: boolean }) => {
  const balance = '84,254.58'
  const pending = '1254.50'
  const draft = '00.00'
  const [payLink, setPaylink] = useState('publicnote')
  const [edittingPayLink, setEdittingPayLink] = useState(false)
  const inputRef = useRef()
  return (
    <Wrapper>
      <WrapperBox>
        <div>
          <Body style={{ fontWeight: 'bold', opacity: '0.7' }}>TOTAL RECIEVED</Body>
          <Ruppay>
            <DollarIcon type={IconType.dollar} />
            <MoneyAmount size={HeadingSize.xxl}>{balance}</MoneyAmount>
          </Ruppay>
          <CardBadge
            label="+10% since last month"
            status={CardStatus.success}
            noIcon
            style={{ display: 'inline-block' }}
          />
        </div>
        <RightBox>
          <div>
            <CardBadge label="Pending" status={CardStatus.pending} noBackgroundColor />
            <MoneyAmount
              size={HeadingSize.md}
              style={{ marginTop: 8 }}
            >{`$${pending}`}</MoneyAmount>
          </div>
          <div>
            <CardBadge label="In drafts" status={CardStatus.draft} noBackgroundColor />
            <MoneyAmount size={HeadingSize.md} style={{ marginTop: 8 }}>{`$${draft}`}</MoneyAmount>
          </div>
        </RightBox>
      </WrapperBox>
      <BodyLink size={BodySize.xs}>YOUR REFFERAL LINk</BodyLink>
      <Yourrefferal>
        <Heading size={HeadingSize.sm}>
          <HeadingSpan style={{ color: theme.color.text.secondary }}>
            startglobal.co/refferal/
          </HeadingSpan>
          {edittingPayLink ? (
            <PayLinkInput
              ref={inputRef as any}
              id="pay-link-input"
              value={payLink}
              onChange={e => setPaylink(e.target.value)}
            />
          ) : (
            <PayLink>{payLink}</PayLink>
          )}
        </Heading>
        <IconWrapper>
          {edittingPayLink ? (
            <Button variant="primary" onClick={() => setEdittingPayLink(false)}>
              SAVE
            </Button>
          ) : (
            <div>
              <Icon
                type={IconType.edit}
                style={{ cursor: 'pointer', marginRight: 24 }}
                onClick={() => {
                  setEdittingPayLink(true)
                  setTimeout(() => document.getElementById('pay-link-input')?.focus(), 1)
                }}
              />
              <Icon type={IconType.copy} style={{ cursor: 'pointer' }} />
            </div>
          )}
        </IconWrapper>
      </Yourrefferal>
      <Pay>go to payments</Pay>
    </Wrapper>
  )
}
