import React from "react";
import { useGetFormHook, FormProps } from '../../../lib/form'
import { Input } from "../../Input";
import { Select } from "../../Select";
import { Form } from '../../Form'
import styled from "@emotion/styled"
import { RadioList } from "../../RadioList";
import { useStartForm } from "../../OperatingAgreement/StartFormProvider";

export type FormData = {
  changesToAmendmentOA : string
  noOfDaysToHoldInterest : number
  withdrawlMemberInterestSplit : string
  noOfDaysForIndividualToBuyInterest : number
  removeMemberByOtherMember: string
  voteToDissolveLLC: string
  dissolutionMeetingNoticePeriod: number
};

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1279px){
    flex-direction: column;
    row-gap: 0.75rem;
  }
`

const FormRowWrapper = styled(FormWrapper)`
  margin-top: 32px;
  @media (max-width: 1279px){
    margin-top: none;
  }
`
const Wrapper = styled.div`
  @media (max-width: 728px){
    display: flex;
    flex-direction: column;
    gap:1.5em;
  }
`
export const ChangesForm = (props: FormProps<FormData>) => {
  const startForm = useStartForm();
  const { register, errors , onSubmit, watch, setValue } = useGetFormHook(props)
  const onChangesToAmendmentOA = (value: string) => {
    setValue("changesToAmendmentOA", value)
  }
  const onWithdrawlMemberInterestSplit = (value: string) => {
    setValue("withdrawlMemberInterestSplit", value)
  }
  const onRemoveMemberByOtherMember = (value: string) => {
    setValue("removeMemberByOtherMember", value)
  }
  const onVoteToDissolveLLC =(value: string) =>{
    setValue("voteToDissolveLLC", value)
  }
  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <Wrapper>
            <Select
            label={'What is required to make amendments to OA?'}
            style={{ width: '100%' }}
            formStyle
            bottomSpace
            error={errors.changesToAmendmentOA && errors.changesToAmendmentOA.message}
            disabled={false}
            onSelectOption={(value) => {
              setValue('changesToAmendmentOA', value);
            } }
            options={[{ label: 'Consent of members', value: 'MEMBER' }, { label: 'Consent of members and board of managers', value: 'MEMBER_AND_BOARD' }]}
            {...register("changesToAmendmentOA", )} />
            <Input
              label={'In the future, if a member withdraws from the LLC, how many days will the LLC have to buy the withdrawn membership interest and split it among all members?'}
              error={errors.noOfDaysToHoldInterest && errors.noOfDaysToHoldInterest.message}
              bottomSpace
              fullWidth
              formStyle
              {...register("noOfDaysToHoldInterest", )} />
            <Select
              label={"How will a withdrawing member's membership interest be split"}
              style={{ width: '100%' }}
              formStyle
              bottomSpace
              error={errors.withdrawlMemberInterestSplit && errors.withdrawlMemberInterestSplit.message}
              disabled={false}
              onSelectOption={(value) => {
                setValue('withdrawlMemberInterestSplit', value);
              } }
              options={[{ label: 'Equally between remaining members', value: 'EQUALLY' }, { label: 'Proportionally between remaining members', value: 'PROPORTIONALLY' }]}
              {...register("withdrawlMemberInterestSplit", )} />
            <Input
              label={"If the LLC members choose not to buy out the withdrawing member's interest as a group, how many days will be given for individual member(s) to buy the interest AFTER the joint buy-out period has expired?"}
              error={errors.noOfDaysForIndividualToBuyInterest && errors.noOfDaysForIndividualToBuyInterest.message}
              bottomSpace
              fullWidth
              formStyle
              {...register("noOfDaysForIndividualToBuyInterest", )} />
            <Select
              label={"Should you allow a member to be removed by other members?"}
              style={{ width: '100%' }}
              formStyle
              bottomSpace
              error={errors.removeMemberByOtherMember && errors.removeMemberByOtherMember.message}
              disabled={false}
              onSelectOption={(value) => {
                setValue('removeMemberByOtherMember', value);
              } }
              options={[{ label: 'Yes (recommended)', value: 'YES' }, { label: 'No', value: 'NO' }]}
              {...register("removeMemberByOtherMember", )} />
            <Select
              label={"What vote will be required to dissolve the LLC?"}
              style={{ width: '100%' }}
              formStyle
              bottomSpace
              error={errors.voteToDissolveLLC && errors.voteToDissolveLLC.message}
              disabled={false}
              onSelectOption={(value) => {
                setValue('voteToDissolveLLC', value);
              } }
              options={[{ label: 'Unanimous', value: 'UNANIMOUS' }, { label: 'Majority', value: 'MAJORITY' }]}
              {...register("voteToDissolveLLC", )} />
            <Input
              label={"To discuss dissolution in a meeting, how many days of notice should be given to all the members?"}
              error={errors.dissolutionMeetingNoticePeriod && errors.dissolutionMeetingNoticePeriod.message}
              bottomSpace
              fullWidth
              formStyle
              {...register("dissolutionMeetingNoticePeriod", )} />
       </Wrapper>
    </Form>
  );
};
