import React, { useContext, useEffect, useState } from "react";
import anime from 'animejs'
import { useForm } from "react-hook-form";
import styled from "@emotion/styled"
import { StartFormLayout, StartFormProps } from '../StartFormLayout'
import { AboutCompanyForm, FormData } from '../../Forms/OperatingAgreement/AboutCompanyForm'
import { useStartForm } from '../StartFormProvider'

const contentId = 'about-company-content'
const cornerImageId = 'about-company-corner-image'


const AgreementWrapper = styled.div`
  background: #F2F7FF;
  border: 1px solid rgba(0, 94, 255, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 30px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
`

const SaveButton = styled.div`
  background: #FFFFFF;
  border: 1px solid #D4DDEB;
  border-radius: 6px;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #757A89;
  cursor: pointer;
  padding: 10px 58px;
`

const PreviewButton = styled.div`
  border: #357d45;
  background: #357d45;
  border-radius: 8px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 10px 34px;
  cursor: pointer;
`
export const AboutCompany = (props: StartFormProps<FormData>) => {
  const startForm = useStartForm()
  const [company, setCompany] = useState();
  const [contact, setContact] = useState();
  const [regAgent, setRegAgent] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const formHook = useForm<FormData>({ defaultValues: startForm.aboutCompany });
  const onSubmit = formHook.handleSubmit(values => {
    startForm.setAboutCompany(values)
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart'
    })
    setTimeout(() => props.onSubmit(values), 500)
  });
  const goBack = () => {
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart'
    })
    setTimeout(() => props.goBack(), 500)
  }
    


  return (
    <>
    <StartFormLayout
      title={'Basic Details'}
      description={'Details about your company and LLC'}
      contentId={contentId}
      onNextClick={onSubmit}
      onPrevClick={goBack}
      hidePrevBtn = {true}
      sidebarImage={(
        <div id={cornerImageId}>
          <AgreementWrapper>
            <img style={{width:'30px',height:'30px',marginTop:'-37px',marginLeft:'-5px',position:'absolute'}} src="/images/agreement.svg"  />
            <Wrapper >
              <label style={{fontSize:'20px',lineHeight: '100%',color: '#444C66'}}>LLC Operating Agreement</label>
              <label style={{fontSize:'16px',lineHeight: '150%',color: '#757A89'}}>A document that customizes the terms of a
                limited liability company according to the specific needs of its owners.</label>
              <label style={{fontSize:'16px',lineHeight: '150%',color: '#357d45'}}>Know more</label>
              <PreviewButton>SEE DOCUMENT PREVIEW</PreviewButton>
            </Wrapper>
          </AgreementWrapper>
        </div>
      )}
    >
      <AboutCompanyForm onSubmit={onSubmit} formHookProps={formHook} />
    </StartFormLayout>
    </>
  )
};
