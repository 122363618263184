import React, { forwardRef } from 'react'
import { css } from '@emotion/core'
import styled from '../lib/styled'
import { Body } from './Typography/Body'
import { InfoToolTip } from './InfoToolTip'

const Wrapper = styled.div<{
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
}>`
  ${props => css`
    ${props.fullWidth ? 'width: 100%;' : ''}
    ${props.leftSpace ? 'margin-left: 12px;' : ''}
    ${props.rightSpace ? 'margin-right: 12px;' : ''}
    ${props.topSpace ? 'margin-top: 26px;' : ''}
    ${props.bottomSpace ? 'margin-bottom: 26px;' : ''}
  `}
`
const AddonStart = styled.span`
  background-color: ${props => props.theme.color.background};
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 3px 0 0 3px;
  height: 40px;
  padding: 0px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InputField = styled.input<{
  dark?: boolean
  fullWidth?: boolean
  formStyle?: boolean
  addonStart: boolean
}>`
  height: 40px;
  border: 1px solid;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 16px;
  box-sizing: border-box;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ theme: { color, shadow }, dark, fullWidth, addonStart, formStyle }) => css`
      ${fullWidth ? 'width: 100%;' : ''}
      ${formStyle ? '' : `box-shadow: ${shadow.default};`}
      background-color: ${dark ? color.backgroundDark : color.white};
      border-color: ${color.defaultBorder};
      color: ${color.text.primary};
      border-radius: ${formStyle ? 3 : 6}px;

      ${
        addonStart
          ? css`
              padding-left: 7px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              border-left: none;
            `
          : ''
      }
    `}
  :focus {
    outline: none;
    border-color: ${props => props.theme.color.primary};
    & + .addon-start {
      border-color: ${props => props.theme.color.primary};
    }
  }

  ::placeholder {
    opacity: 0.5;
  }
`
const LabelWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`
const ErrorMessage = styled(Body)`
  color: ${props => props.theme.color.error};
  margin-top: 6px;
`

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  dark?: boolean
  info?: string
  error?: string
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  formStyle?: boolean
  addonStart?: {
    content: any
    style?: any
  }
  wrapperStyle?: any
  labelStyle?: any
  style?: any
}

export const Input = forwardRef((props: InputProps, ref: any) => (
  <Wrapper
    leftSpace={props.leftSpace}
    rightSpace={props.rightSpace}
    topSpace={props.topSpace}
    bottomSpace={props.bottomSpace}
    fullWidth={props.fullWidth}
    style={props.wrapperStyle}
  >
    {props.label ? (
      <LabelWrapper>
        <Body dark style={props.labelStyle}>
          {props.label}
        </Body>
        {props.info ? <InfoToolTip content={props.info} /> : null}
      </LabelWrapper>
    ) : null}
    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      <InputField
        ref={ref}
        {...props}
        fullWidth={props.fullWidth}
        formStyle={props.formStyle}
        addonStart={!!props.addonStart}
      />
      {props.addonStart ? (
        <AddonStart className="addon-start" style={props.addonStart.style}>
          {props.addonStart.content}
        </AddonStart>
      ) : null}
    </div>
    {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
  </Wrapper>
))
