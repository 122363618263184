import React, { useState } from 'react'
import styled, { css } from '../lib/styled'
import { Surface } from './Surface'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Button } from './Button'
import { Icon, IconType } from './Icon'
import { PaymentGateway } from './PaymentGateway'
import { PaymentsRecieved } from './PaymentsRecieved'

const Wrapper = styled.div`
  padding-left: 16px;
`
const Surfacebox = styled(Surface)`
  margin: 0 0 32px 0;
  height: fit-content;
  width: 100%;
`
const Box = styled.div`
  padding: 24px;
`
const CardItem = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
`
const ErrorBox = styled.div<{ type?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  border-radius: 16px;
  width: 80px;
  height: 80px;
  ${props =>
    props.type === 'blue' &&
    css`
      background-color: var(--alice-blue);
    `}
  ${props =>
    props.type === 'primary' &&
    css`
      background-color: var(--primary);
    `}
`
const ErrorIcon = styled.img`
  height: 45px;
`
const CardContent = styled.div`
  padding-left: 16px;
  height: fit-content;
`
const CardButton = styled.div`
  button {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 0 17px;
    border-radius: 4px;
    div {
      font-size: 14px;
      line-height: 17px;
    }
  }
`
const BodyStep = styled(Body)`
  line-height: 24px;
  margin-top: 4px;
`
const Ul = styled.div`
  background-color: var(--alice-blue);
  border-radius: 12px;
  padding: 0 20px;
  margin-bottom: 24px;
`
const SignedWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  :last-child {
    padding-bottom: 24px;
  }
  :first-child {
    padding-top: 24px;
  }
`
const EasyBody = styled(Body)`
  font-weight: 700;
  color: var(--independence);
`

interface PaymentsSetupProps {
  style?: any
  items: Array<{
    lefttitle?: string
    leftbody?: string
    images?: string
    button?: string
    type?: string
    donetitle?: Array<{}>
    componentType?: string
  }>
}

export const PaymentsSetup = (props: PaymentsSetupProps) => {
  const [showData, setShowData] = useState(false)
  return (
    <Wrapper style={props?.style}>
      <Surfacebox>
        <Box>
          {props?.items?.map(item => {
            return showData && item?.componentType === 'payments' ? (
              <>
                <PaymentsRecieved />
              </>
            ) : (
              <>
                <CardItem>
                  <ErrorBox type={item?.type}>
                    <ErrorIcon src={item?.images} alt="" />
                  </ErrorBox>
                  <CardContent>
                    <Heading size={HeadingSize.md} color={'var(--independence)'}>
                      {item?.lefttitle}
                    </Heading>
                    <BodyStep size={BodySize.md}>{item?.leftbody}</BodyStep>
                  </CardContent>
                </CardItem>
                <Ul>
                  {item?.donetitle?.map(title => (
                    <SignedWrapper>
                      <Icon type={IconType.done} style={{ marginRight: 16 }} />
                      <EasyBody size={BodySize.md}>{title}</EasyBody>
                    </SignedWrapper>
                  ))}
                </Ul>
                {showData && item?.componentType === 'book' && (
                  <div>
                    <PaymentGateway />
                  </div>
                )}
                <CardButton>
                  {item?.button && (
                    <Button
                      onClick={() => {
                        setShowData(item?.componentType === 'book' ? true : !showData)
                      }}
                    >
                      {showData && item?.componentType === 'book' ? 'Subscribe Now' : item?.button}
                    </Button>
                  )}
                </CardButton>
              </>
            )
          })}
        </Box>
      </Surfacebox>
    </Wrapper>
  )
}
