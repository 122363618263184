import React from 'react'
import styled from '../lib/styled'
import { Body } from './Typography/Body'
import { ArrowButton } from './ArrowButton'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`

interface StartFormFooterProps {
  onNextClick: () => void
  onPrevClick: () => void
  nextButtonText?: string
}

export const StartFormFooter = (props: StartFormFooterProps) => (
  <Wrapper>
    <Body bold style={{ cursor: 'pointer' }} onClick={props.onPrevClick}>
      PREVIOUS STEP
    </Body>
    <ArrowButton onClick={props.onNextClick}>{props.nextButtonText || 'NEXT'}</ArrowButton>
  </Wrapper>
)
