import React from 'react'
import { useTheme } from 'emotion-theming'
import styled, { Theme } from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { CardStatus } from './Card'

const statusProps = (theme: Theme, status?: CardStatus) => {
  switch (status) {
    case CardStatus.success:
      return {
        backgroundColor: theme.color.secondaryLight,
        color: theme.color.secondary,
        label: 'Issued',
        icon: '/icons/success.svg',
      }
    case CardStatus.failure:
      return {
        backgroundColor: theme.color.errorLight,
        color: theme.color.error,
        label: 'Not available',
        icon: '/icons/failure.svg',
      }
    case CardStatus.pending:
      return {
        backgroundColor: theme.color.primaryLight,
        color: theme.color.primary,
        label: 'Processing',
        icon: '/icons/pending.svg',
      }
    case CardStatus.draft:
      return {
        backgroundColor: theme.color.warningLight,
        color: theme.color.warning,
        label: 'Draft',
        icon: '/icons/draft.svg',
      }
      case CardStatus.agreementSigned:
        return {
          backgroundColor: theme.color.secondaryLight,
          color: theme.color.secondary,
          label: 'Issued',
          icon: '/icons/signed.svg',
    }
    case CardStatus.agreementPending:
        return {
          backgroundColor: theme.color.warningLight,
          color: theme.color.warning,
          label: 'Pending',
          icon: '/icons/draft.svg',
    }
    default:
      return {
        backgroundColor: '',
        color: '',
        label: '',
        icon: '',
      }
  }
}

const Wrapper = styled.div<{ backgroundColor: string; noBackgroundColor?: boolean }>`
  display: flex;
  background-color: ${props => (props.noBackgroundColor ? 'transparent' : props.backgroundColor)};
  border-radius: 13px;
  ${props => (props.noBackgroundColor ? '' : `box-shadow: ${props.theme.shadow.default};`)}
  ${props => (props.noBackgroundColor ? '' : 'padding: 8px 12px 6px 12px;')}
`

interface CardBadgeProps {
  label?: string
  status: CardStatus
  noIcon?: boolean
  noBackgroundColor?: boolean
  style?: any
}

export const CardBadge = (props: CardBadgeProps) => {
  const theme = useTheme<Theme>()
  const { backgroundColor, color, label, icon } = statusProps(theme, props.status)
  return (
    <Wrapper
      style={props.style}
      backgroundColor={backgroundColor}
      noBackgroundColor={props.noBackgroundColor}
    >
      {props.noIcon ? null : (
        <img src={icon} alt={props.status.toLowerCase()} style={{ marginRight: 8 }} />
      )}
      <Heading size={HeadingSize.xs} color={color}>
        {props.label || label}
      </Heading>
    </Wrapper>
  )
}
