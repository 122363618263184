import React, { useState, useRef } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import useOnClickOutside from 'use-onclickoutside'
import { useGetFormHook, FormProps } from '../../lib/form'
import styled, { theme } from '../../lib/styled'
import { Body, BodySize } from '../Typography/Body'
import { Form } from '../Form'
import { Input } from '../Input'
import { TextArea } from '../TextArea'
import { ClientSelect } from '../ClientSelect'
import { Checkbox } from '../Checkbox'
import { DatePicker } from '../DatePicker'
import { TextButton } from '../TextButton'
import { Heading, HeadingSize } from '../Typography/Heading'

const EmailWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.primaryLight};
  border: 1px solid ${props => props.theme.color.primaryBorder};
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 16px;
`
const CheckboxLabel = styled(Body)`
  font-size: 18px;
  margin-left: 16px;
`
const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 1.5fr 1fr 0.3fr;
  grid-column-gap: 24px;
  margin-bottom: 16px;
`
const ItemMenuButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .icon-color-part {
    fill: #757a89;
  }

  :hover {
    .icon-color-part {
      fill: ${props => props.theme.color.primary};
    }
  }
`
const DeleteButton = styled.div<{ open: boolean }>`
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 0;
  top: 28px;
  display: ${props => (props.open ? 'inherit' : 'none')};
`

const ItemMenuButton = (props: { onClickRemove: () => void }) => {
  const wrapperRef = useRef<any>()
  const [menuOpen, setMenuOpen] = useState(false)

  useOnClickOutside(wrapperRef, () => setMenuOpen(false))

  return (
    <ItemMenuButtonWrapper onClick={() => setMenuOpen(true)}>
      <svg width="16px" height="4px" viewBox="0 0 16 4" version="1.1" className="menu-item-icon">
        <g id="Clean" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            className="icon-color-part"
            id="Remove-Item"
            transform="translate(-1408.000000, -561.000000)"
          >
            <g id="Group-7" transform="translate(932.000000, 271.000000)">
              <g id="Group-3" transform="translate(476.000000, 290.000000)">
                <circle id="Oval" cx="2" cy="2" r="2"></circle>
                <circle id="Oval" cx="8" cy="2" r="2"></circle>
                <circle id="Oval" cx="14" cy="2" r="2"></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <DeleteButton open={menuOpen} ref={wrapperRef} onClick={props.onClickRemove}>
        <Body color={theme.color.error} style={{ whiteSpace: 'nowrap' }}>
          Remove Item
        </Body>
      </DeleteButton>
    </ItemMenuButtonWrapper>
  )
}

type Item = {
  title: string
  quantity?: number
  price?: number
}
export type FormData = {
  email: string
  project: string
  issuedOn?: Date
  dueOn?: Date
  isRecurring: boolean
  items: Item[]
  notes?: string
}

export const CreateInvoiceForm = (
  props: FormProps<FormData> & { onCreateClient: () => void; disabled?: boolean }
) => {
  const { register, errors, onSubmit, control, watch, setValue } = useGetFormHook(props)
  const { fields, append, remove } = useFieldArray<Item>({ control, name: 'items' })
  const isRecurring = watch('isRecurring')
  const items = watch('items')?.length ? watch('items') : []

  return (
    <Form
      onSubmit={onSubmit}
      hideSubmit
      style={props.disabled ? { pointerEvents: 'none' } : undefined}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <EmailWrapper>
          <ClientSelect
            name="email"
            label="Recipient Email"
            error={errors.email && errors.email.message}
            fullWidth
            formStyle
            options={[
              {
                label: 'Alex Parkinson (alex@email.com)',
                value: 'alex@email.com',
              },
            ]}
            onCreateClient={props.onCreateClient}
            ref={register({ required: 'Required' })}
          />
        </EmailWrapper>
        <Input
          name="project"
          label="Project / Description"
          error={errors.project && errors.project.message}
          topSpace
          bottomSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
        <div style={{ display: 'flex' }}>
          <Controller
            name="issuedOn"
            control={control}
            as={
              <DatePicker
                label="Issued on"
                error={errors.issuedOn && errors.issuedOn.message}
                bottomSpace
                rightSpace
                formStyle
                fullWidth
                calendarWrapperStyle={{
                  position: 'absolute',
                  left: 0,
                }}
              />
            }
          />
          <Controller
            name="dueOn"
            control={control}
            as={
              <DatePicker
                label="Due on"
                error={errors.dueOn && errors.dueOn.message}
                bottomSpace
                leftSpace
                formStyle
                fullWidth
                calendarWrapperStyle={{
                  position: 'absolute',
                  right: 0,
                }}
              />
            }
          />
        </div>
        <div
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => setValue('isRecurring', !isRecurring)}
        >
          <Controller
            as={Checkbox}
            name="isRecurring"
            control={control}
            valueName="active"
            defaultValue={false}
            onChangeName="onClick"
            onChange={() => !isRecurring}
          />
          <CheckboxLabel dark>This is a recurring invoice (monthly)</CheckboxLabel>
        </div>
        <div style={{ marginTop: 40 }}>
          <ItemWrapper>
            <Body>Item</Body>
            <Body>Qty</Body>
            <Body>Price</Body>
            <Body style={{ textAlign: 'end' }}>Total</Body>
            <div />
          </ItemWrapper>
          {fields.map((item, i) => {
            const price = items[i].price
            const quantity = items[i].quantity
            return (
              <ItemWrapper>
                <Input
                  name={`items[${i}].title`}
                  error={
                    errors.items &&
                    errors.items[i] &&
                    errors.items[i].title &&
                    errors.items[i].title?.message
                  }
                  fullWidth
                  formStyle
                  ref={register({ required: 'Required' })}
                />
                <Input
                  name={`items[${i}].quantity`}
                  type="number"
                  error={
                    errors.items &&
                    errors.items[i] &&
                    errors.items[i].quantity &&
                    errors.items[i].quantity?.message
                  }
                  fullWidth
                  formStyle
                  ref={register({ required: 'Required' })}
                />
                <Input
                  name={`items[${i}].price`}
                  type="number"
                  error={
                    errors.items &&
                    errors.items[i] &&
                    errors.items[i].price &&
                    errors.items[i].price?.message
                  }
                  fullWidth
                  formStyle
                  ref={register({ required: 'Required' })}
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Body size={BodySize.md}>${((price || 0) * (quantity || 0)).toFixed(2)}</Body>
                </div>
                <ItemMenuButton onClickRemove={() => remove(i)} />
              </ItemWrapper>
            )
          })}
          <ItemWrapper>
            <TextButton
              style={{ fontSize: 14 }}
              onClick={() => append({ title: '', price: 0, quantity: 0 })}
            >
              + ADD ITEM
            </TextButton>
            <div />
            <Body style={{ textAlign: 'end' }}>Total</Body>
            <Heading size={HeadingSize.md} style={{ textAlign: 'end' }}>
              $
              {items
                .reduce((num, item) => (num += (item.price || 0) * (item.quantity || 0)), 0)
                .toFixed(2)}
            </Heading>
            <div />
          </ItemWrapper>
        </div>
        <TextArea
          name="notes"
          label="Additional Notes"
          error={errors.notes && errors.notes.message}
          topSpace
          bottomSpace
          fullWidth
          formStyle
          ref={register()}
        />
      </div>
    </Form>
  )
}
