import React, { useState } from 'react'
import styled from '../lib/styled'
import { RadioButton } from '../components/RadioButton'

const Wrapper = styled.div<{ active: boolean }>`
  border: 1px solid
    ${props =>
      props.active ? props.theme.color.defaultBorder : props.theme.color.defaultBorderTwo};
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px 16px 12px 12px;
  margin-bottom: 24px;
`
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
const Input = styled.input`
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  width: 59px;
  height: 32px;
  padding: 0;
  outline: 0;
  color: var(--text-secondary);
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 16px;
`
const InputCard = styled.input`
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  height: 32px;
  padding: 0;
  outline: 0;
  color: var(--text-secondary);
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 16px;
  margin-left: 12px;
`
const InputWrapper = styled.div`
  background-color: var(--ghost-White);
  border: 0.7px solid var(--default-border);
  border-radius: 4px;
  height: 32px;
  margin-left: 12px;
  padding-right: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input:nth-child(2) {
    margin-left: 120px;
  }
  input:nth-child(3) {
    width: 30px;
  }
`
const InputBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 24px;
  input {
    width: 16px;
    height: 16px;
  }
  label {
    color: var(--text-secondary);
    font-size: 12px;
    line-height: 14px;
    margin-left: 8px;
  }
`

interface NewPaymentCardProps {
  active: boolean
  type: 'new-card'
  onClick: () => void
}

export const NewPaymentCard = (props: NewPaymentCardProps) => {
  const [cvc, setCvc] = useState()
  const [mm, setMM] = useState()
  const [number, setNumber] = useState()

  const setInputValue = (e: any) => {
    if (e.target.value.length <= 3) {
      setCvc(e.target.value)
    }
  }
  const setInputValueMM = (e: any) => {
    if (e.target.value.length <= 4) {
      setMM(e.target.value)
    }
  }
  const setInputValueNumber = (e: any) => {
    if (e.target.value.length <= 14) {
      setNumber(e.target.value)
    }
  }

  return (
    <Wrapper active={!!props.active} onClick={props.onClick}>
      <RadioButtonWrapper>
        <RadioButton selected={props.active} />
        <InputWrapper>
          <InputCard
            placeholder="Card number"
            type="number"
            onChange={setInputValueNumber}
            value={number}
          />
          <Input placeholder="MM / YY" type="number" onChange={setInputValueMM} value={mm} />
          <Input placeholder="CVC" type="number" onChange={setInputValue} value={cvc} />
        </InputWrapper>
      </RadioButtonWrapper>
      <InputBox>
        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
        <label htmlFor="vehicle1">Securely save this card</label>
      </InputBox>
    </Wrapper>
  )
}
