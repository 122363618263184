import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { Card } from '../components/Card'
import { PersonalDetailsForm } from '../components/Forms/PersonalDetailsForm'
import { ChangePasswordForm } from '../components/Forms/ChangePasswordForm'

export const Settings = () => (
  <PageLayout title="My Account">
    <Card title="Personal details" subtitle="Details like your name, email address etc.">
      <PersonalDetailsForm />
    </Card>
    <Card title="Password" subtitle="Change your password by confirming your current one.">
      <ChangePasswordForm />
    </Card>
  </PageLayout>
)
