import React from 'react'
import styled from '../lib/styled'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from './Icon'
import { Button } from './Button'
import MediaQuery from 'react-responsive'

const SignedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90px;
`
const SignerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`

const Wrapper = styled.div`
  @media(min-width:728px){
    width:50%;
  }
`

interface SignersListProps {
  signers: Array<{ name: string; email: string; active: boolean }>
  completed: boolean
  title: string
}

export const SignersList = (props: SignersListProps) => (
  <div style={{ width: '100%' }}>
    <Wrapper>
      <Body bold dark size={BodySize.sm} style={{ marginTop: 32 }}>
        SIGNERS
      </Body>
      {props.signers.map(({ name, email, active }) => (
        <SignerWrapper>
          <div>
            <Body bold dark size={BodySize.sm}>
              {name}
            </Body>
            <Body size={BodySize.sm}>{email}</Body>
          </div>
          <SignedWrapper>
            <Icon
              type={active ? IconType.done : IconType.pendingLarge}
              style={{ marginRight: 12 }}
            />
            <Body size={BodySize.sm}>{active ? 'Signed' : 'Pending'}</Body>
          </SignedWrapper>
        </SignerWrapper>
      ))}
    </Wrapper>
    <MediaQuery query='(min-width:728px'>
    <div style={{ marginTop: 32, width: '100%' }}>
      {props.completed ? (
        <Button>{`Download ${props.title}`.toUpperCase()}</Button>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <Icon type={IconType.reminder} style={{ marginRight: 12 }} />
          <Body size={BodySize.sm} bold color="var(--primary)">
            SEND REMINDER
          </Body>
        </div>
      )}
    </div>
    </MediaQuery>
    <MediaQuery query='(max-width:728px'>
    <div style={{ marginTop: 32, width: '100%' }}>
      {props.completed ? (
        <Button small>{`Download ${props.title}`.toUpperCase()}</Button>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <Icon type={IconType.reminder} style={{ marginRight: 12 }} />
          <Body size={BodySize.sm} bold color="var(--primary)">
            SEND REMINDER
          </Body>
        </div>
      )}
    </div>
    </MediaQuery>
  </div>
)
