import React from 'react'
import styled from '../lib/styled'
import { Surface } from '../components/Surface'
import { Body, BodySize } from '../components/Typography/Body'
import { Heading } from '../components/Typography/Heading'
import { PageLayout } from '../components/PageLayout'
import { Button } from '../components/Button'

const SurfaceBox = styled(Surface)`
  padding: 12px 19px 12px 12px;
  margin-bottom: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`
const BankIcon = styled.div`
  background-color: var(--background);
  border-radius: 8px;
  width: 88px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`
const StripeIcon = styled.img`
  width: 47px;
  height: 16px;
`
const HeadingAccount = styled(Heading)`
  color: var(--independence);
  line-height: 22px;
  margin-bottom: 10px;
  font-size: 18px;
`
const BankDetails = styled(Body)`
  line-height: 19px;
`
const BankDetail = styled.div``
const TrashIcon = styled.img`
  cursor: pointer;
  height: 18px;
  padding: 5px;
`
const PrimaryWrapper = styled.div`
  background-color: var(--secondary-light);
  border-radius: 13px;
  width: fit-content;
  color: var(--secondary);
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  padding: 7px 16px;
  margin-right: 20px;
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const Make = styled.div`
  color: var(--primary);
  font-size: 12px;
  letter-spacing: 0.92px;
  line-height: 14px;
  font-weight: 700;
  margin-right: 20px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  button {
    padding: 17px 24px;
  }
`
const SurfaceWrapper = styled.div`
  margin-bottom: 48px;
`
const TextDetails = styled.div`
  width: 100%;
  max-width: 33.33%;
  color: var(--independence);
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
`
const Error = styled.div`
  color: var(--error);
  font-size: 12px;
  letter-spacing: 0.92px;
  line-height: 14px;
  font-weight: bold;
  text-transform: uppercase;
`
const TextRight = styled.div`
  color: var(--primary);
  font-size: 12px;
  letter-spacing: 0.92px;
  line-height: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  img {
    margin-right: 12px;
  }
`

export const Billing = () => {
  return (
    <PageLayout title="Payment Settings">
      <SurfaceWrapper>
        <ButtonWrapper>
          <div>
            <Heading style={{ marginBottom: 12 }}>Saved cards</Heading>
            <Body>List of all credit cards you saved</Body>
          </div>
          <Button>+ ADD NEW CARD</Button>
        </ButtonWrapper>
        <SurfaceBox>
          <LeftWrapper>
            <BankIcon>
              <StripeIcon src="/images/visa.svg" />
            </BankIcon>
            <BankDetail>
              <HeadingAccount>Visa xxxx 1657</HeadingAccount>
              <BankDetails size={BodySize.md}>Expires on 16/24</BankDetails>
            </BankDetail>
          </LeftWrapper>
          <Wrapper>
            <PrimaryWrapper>Primary</PrimaryWrapper>
            <TrashIcon src="/icons/trash.svg" />
          </Wrapper>
        </SurfaceBox>
        <SurfaceBox>
          <LeftWrapper>
            <BankIcon>
              <StripeIcon src="/images/mastercard.svg" />
            </BankIcon>
            <BankDetail>
              <HeadingAccount>Mastercard xxxx 9878</HeadingAccount>
              <BankDetails size={BodySize.md}>Expires on 20/28</BankDetails>
            </BankDetail>
          </LeftWrapper>
          <Wrapper>
            <Make>make primary</Make>
            <TrashIcon src="/icons/trash.svg" />
          </Wrapper>
        </SurfaceBox>
      </SurfaceWrapper>
      <SurfaceWrapper>
        <ButtonWrapper>
          <div>
            <Heading style={{ marginBottom: 12 }}>Subscriptions</Heading>
            <Body>List of things you’ve subscribed</Body>
          </div>
        </ButtonWrapper>
        <SurfaceBox>
          <TextDetails>Worry-free compliance</TextDetails>
          <TextDetails>$10 / month</TextDetails>
          <Error>Unsubscribe</Error>
        </SurfaceBox>
        <SurfaceBox>
          <TextDetails>Bookkeeping</TextDetails>
          <TextDetails>$99 / month</TextDetails>
          <Error>Unsubscribe</Error>
        </SurfaceBox>
      </SurfaceWrapper>
      <SurfaceWrapper>
        <ButtonWrapper>
          <div>
            <Heading style={{ marginBottom: 12 }}>Transactions</Heading>
            <Body>List of all your internal purchases</Body>
          </div>
        </ButtonWrapper>
        <SurfaceBox>
          <TextDetails>Startglobal Registration</TextDetails>
          <TextDetails>$599</TextDetails>
          <TextRight>
            <img src="/icons/download.svg" alt="" />
            reciept
          </TextRight>
        </SurfaceBox>
        <SurfaceBox>
          <TextDetails>Trademark Registration</TextDetails>
          <TextDetails>$260</TextDetails>
          <TextRight>
            <img src="/icons/download.svg" alt="" />
            reciept
          </TextRight>
        </SurfaceBox>
      </SurfaceWrapper>
    </PageLayout>
  )
}
