import React, { useContext, useState } from "react";
import { useGetFormHook, FormProps } from '../../../lib/form'
import { Input } from "../../Input";
import { Select } from "../../Select";
import { Form } from '../../Form'
import styled from "@emotion/styled"
import { RadioList } from "../../RadioList";
import { watch } from "fs";
import { InputSelect } from "../../InputSelect";
import { useStartForm } from "../../OperatingAgreement/StartFormProvider";
import { TextArea } from "../../TextArea";

export type FormData = {
  llc_purpose: string
  llcType: string
  regAgentName: string
  regAgentState : string
  regAgentAddress: string
  regAgentZipCode: number
  regAgentCity: string
  appointOfficer: string
};

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1279px){
    flex-direction: column;
    row-gap: 1.5rem;
  }
`

const FormRowWrapper = styled(FormWrapper)`
  margin-top: 32px;
  @media (max-width: 1279px){
    margin-top: none;
  }
`

const Wrapper = styled.div`
  @media (max-width: 728px){
    display: flex;
    flex-direction: column;
    gap:1.5em;
  }
`
type Member = {
  firstName: string
  lastName: string
}
export const AboutCompanyMoreDetailsForm = (props: FormProps<FormData>) => {
  const startForm = useStartForm()
  const { register, errors, onSubmit,setValue ,watch} = useGetFormHook(props)
  const [llcType, setLLCType] = useState('MEMBER_MANAGED');
  const onLLCTypeSelection = (value: string) => {
    setValue("llcType", value)
    setLLCType(value);
  }
  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <Wrapper>
        <TextArea
        label={'Purpose of LLC (100 chars)'}
        error={errors.llc_purpose && errors.llc_purpose.message}
        fullWidth
        rows={4}
        bottomSpace
        maxLength={100}
        defaultValue={'To perform or engage in any and all activities and/or businesses for which limited liability companies may be engaged under the Statutes.'}
        {...register("llc_purpose",)}
        />
       {/* <MediaQuery query="(max-width:728px)">
       <RadioList
          name="llcType"
          label={'Is the LLC Member-managed or Manager-managed?'}
          options={[{ label: 'Member-managed', value: 'MEMBER_MANAGED' }, { label: 'Manager-managed', value: 'MANAGER_MANAGED' }]}
          value={watch("llcType")}
          onSelect={onLLCTypeSelection}
          rowSpan={false}
          error={errors.llcType && errors.llcType.message}
          {...register("llcType", { required: 'Required' })}
        />
        </MediaQuery> */}
        <RadioList
          name="llcType"
          label={'Is the LLC Member-managed or Manager-managed?'}
          bottomSpace
          options={[{ label: 'Member-managed', value: 'MEMBER_MANAGED' }, { label: 'Manager-managed', value: 'MANAGER_MANAGED' }]}
          value={llcType}
          onSelect={onLLCTypeSelection}
          rowSpan={true}
          error={errors.llcType && errors.llcType.message}
        />
        <Input
          label={'Name of the Registered agent'}
          error={errors.regAgentName && errors.regAgentName.message}
          fullWidth
          bottomSpace
          disabled={true}
          defaultValue={'Harvard Business Services, Inc'}
          formStyle
          {...register("regAgentName", { pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" }  })}
        />
         <Select
          label={'State of Registered agent'}
          style={{ width: '100%' }}
          formStyle
          error={errors.regAgentState && errors.regAgentState.message}
          disabled={true}
          defaultValue={'Delaware'}
          onSelectOption={(value) => {
            setValue('regAgentState', value)
          }}
          options={[{ label: 'Delaware', value: 'DELAWARE' }, { label: 'Wyoming', value: 'WYOMING' }]}
          {...register("regAgentState", {  })}
        />
        <Input
          label={'Address of the Registered agent'}
          error={errors.regAgentAddress && errors.regAgentAddress.message}
          topSpace
          fullWidth
          disabled={true}
          defaultValue={'16192 Coastal Highway'}
          formStyle
          {...register("regAgentAddress", {  pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" }  })}
        />
      <FormWrapper>
      <Input
            placeholder={'City'}
            error={errors.regAgentCity && errors.regAgentCity.message}
            fullWidth
            topSpace
            formStyle
            disabled={true}
            defaultValue={'Lewes'}
            rightSpace
            {...register("regAgentCity", { pattern: { value : /^[\x00-\x7F]+$/, message : "Please include only English characters" } })}
          />
          <Input
            placeholder={'Zip Code'}
            type="text"
            error={errors.regAgentZipCode && errors.regAgentZipCode.message}
            fullWidth
            formStyle
            defaultValue={'19958'}
            topSpace
            bottomSpace
            disabled={true}
            {...register("regAgentZipCode", { })}
          />
      </FormWrapper>
      <Select
          label={'Should the LLC be allowed to appoint officers, such as a President, Secretary or Treasurer?'}
          style={{ width: '100%' }}
          formStyle
          error={errors.appointOfficer && errors.appointOfficer.message}
          disabled={false}
          onSelectOption={(value) => {
            setValue('appointOfficer', value)
          }}
          options={[{ label: 'Yes (recommended)', value: 'YES' }, { label: 'No', value: 'NO' }]}
          {...register("appointOfficer")}
        />
      </Wrapper>
    </Form>
  );
};
function setValue(arg0: string, value: string) {
  throw new Error("Function not implemented.");
}

