import React from 'react'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Form } from '../Form'
import { Select } from '../Select'

export type FormData = {
  state: string
}

export const StateForm = (props: FormProps<FormData>) => {
  const { register, errors, onSubmit } = useGetFormHook(props)

  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <Select
        name="state"
        label="Choose the state"
        style={{ width: '100%' }}
        formStyle
        error={errors.state && errors.state.message}
        ref={register({ required: 'Required' })}
        options={[{ label: 'Delaware', value: 'DE' }]}
      />
    </Form>
  )
}
