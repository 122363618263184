import React from 'react'
import styled from '../lib/styled'
import { Body, BodySize } from '../components/Typography/Body'
import { Button } from '../components/Button'

const Wrapper = styled.div`
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 6px;
`
const GlobalStartIconWrapper = styled.div`
  background-color: ${props => props.theme.color.primary004};
  padding: 8px 12px;
  margin-right: 24px;
  border-radius: 6px;
`
const ButtonWrap = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    @media (max-width: 768px){
      flex-wrap: wrap;
    gap: 1em;
    }
`

export const BrandLogoUpload = (props: { logoSrc: string }) => (
  <Wrapper>
    <ButtonWrap>
      <GlobalStartIconWrapper>
        <img src={props.logoSrc} height={30} alt="" />
      </GlobalStartIconWrapper>
      <Button outline variant="primary" small style={{ height: 'fit-content', marginRight: 12 }}>
        REPLACE
      </Button>
      <Button light variant="error" small style={{ height: 'fit-content' }}>
        REMOVE
      </Button>
    </ButtonWrap>
    <Body size={BodySize.xs} style={{ maxWidth: 360 }}>
      Upload a .png image for better visibility, the size of the photo should be atleast 167x48px.
    </Body>
  </Wrapper>
)
