import React from 'react'
import styled from '../lib/styled'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from './Icon'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
`
const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

interface HeaderDropdownMenuItem {
  icon: IconType
  label: string
}

export const HeaderDropdownMenuItem = (props: HeaderDropdownMenuItem) => (
  <Wrapper>
    <IconWrapper>
      <Icon type={props.icon} />
    </IconWrapper>
    <Body dark size={BodySize.sm} style={{ marginLeft: 8 }}>
      {props.label}
    </Body>
  </Wrapper>
)
