import styled from '../../lib/styled'

export enum BodySize {
  xs = 12,
  sm = 14,
  md = 16,
  lg = 20,
  xl = 24,
}

interface BodyProps {
  size?: BodySize
  light?: boolean
  dark?: boolean
  bold?: boolean
  color?: string
  textAlign?: boolean
}

export const Body = styled.div<BodyProps>`
  font-size: ${props => props.size || BodySize.md}px;
  font-weight: ${props => (props.bold ? 700 : 400)};
  text-align: ${props => props.textAlign && 'end'};
  color: ${props =>
    props.color ||
    (props.light
      ? props.theme.color.white
      : props.dark
      ? props.theme.color.text.primary
      : props.theme.color.text.secondary)};
`
