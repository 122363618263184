import React from 'react'
import styled from '../lib/styled'
import { Heading } from './Typography/Heading'
import { Body } from './Typography/Body'
import { Surface } from './Surface'
import { TextButton } from './TextButton'

const Wrapper = styled(Surface)`
  padding: 24px;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`
const Description = styled(Body)`
  margin-top: 12px;
  margin-bottom: 32px;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  height: 80px;
`

interface PerkCardProps {
  title: string
  body: string
  image: string
  style?: any
}

export const PerkCard = (props: PerkCardProps) => (
  <Wrapper style={props.style}>
    <div style={{ height: '100%' }}>
      <LogoWrapper>
        <img src={props.image} alt="" />
      </LogoWrapper>
      <Heading>{props.title}</Heading>
      <Description>{props.body}</Description>
    </div>
    <TextButton>REDEEM</TextButton>
  </Wrapper>
)
