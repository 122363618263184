import React, { createContext, useContext, useState } from 'react'
import { FormData as EmailFormData } from './StartPages/EnterEmail'
import { FormData as BusinessDetailsFormData } from './Forms/BusinessDetailsForm'
import { FormData as AboutCompanyFormData } from './Forms/AboutCompanyForm'
import { FormData as BoardOfDirectorsFormData } from './Forms/BoardOfDirectorsForm'
import { FormData as OfficersAndRolesFormData } from './Forms/OfficersAndRolesForm'
import { FormData as PersonalDetailsExtendedFormData } from './Forms/PersonalDetailsExtendedForm'
import { FormData as MailingAddressFormData } from './Forms/MailingAddressForm'

export type CompanyType = 'llc' | 'corporation'
export type StateType = 'delaware' | 'wyoming'

const INIT_DATA = {
  currentPage: 0,
  email: {} as EmailFormData,
  companyType: '' as CompanyType,
  stateType: '' as StateType,
  businessDetails: {} as BusinessDetailsFormData,
  aboutCompany: {} as AboutCompanyFormData,
  boardOfDirectors: {} as BoardOfDirectorsFormData,
  officersAndRoles: {} as OfficersAndRolesFormData,
  aboutYou: {} as PersonalDetailsExtendedFormData,
  mailingAddress: {} as MailingAddressFormData,
}

const StartFormContext = createContext({
  ...INIT_DATA,
  setCurrentPage: (value: number) => {},
  setEmail: (values: EmailFormData) => {},
  setCompanyType: (type: CompanyType) => {},
  setStateType: (type: StateType) => {},
  setBusinessDetails: (value: BusinessDetailsFormData) => {},
  setAboutCompany: (value: AboutCompanyFormData) => {},
  setBoardOfDirectors: (value: BoardOfDirectorsFormData) => {},
  setOfficersAndRoles: (value: OfficersAndRolesFormData) => {},
  setAboutYou: (value: PersonalDetailsExtendedFormData) => {},
  setMailingAddress: (value: MailingAddressFormData) => {},
})

export const useStartForm = () => useContext(StartFormContext)

export const StartFormProvider = (props: { children: JSX.Element }) => {
  const [formData, setFormData] = useState(INIT_DATA)
  const setCurrentPage = (value: number) => setFormData({ ...formData, currentPage: value })
  const setEmail = (values: EmailFormData) => setFormData({ ...formData, email: values })
  const setCompanyType = (type: CompanyType) => setFormData({ ...formData, companyType: type })
  const setStateType = (type: StateType) => setFormData({ ...formData, stateType: type })
  const setBusinessDetails = (value: BusinessDetailsFormData) =>
    setFormData({ ...formData, businessDetails: value })
  const setAboutCompany = (value: AboutCompanyFormData) =>
    setFormData({ ...formData, aboutCompany: value })
  const setBoardOfDirectors = (value: BoardOfDirectorsFormData) =>
    setFormData({ ...formData, boardOfDirectors: value })
  const setOfficersAndRoles = (value: OfficersAndRolesFormData) =>
    setFormData({ ...formData, officersAndRoles: value })
  const setAboutYou = (value: PersonalDetailsExtendedFormData) =>
    setFormData({ ...formData, aboutYou: value })
  const setMailingAddress = (value: MailingAddressFormData) =>
    setFormData({ ...formData, mailingAddress: value })

  return (
    <StartFormContext.Provider
      value={{
        ...formData,
        setCurrentPage,
        setEmail,
        setCompanyType,
        setStateType,
        setBusinessDetails,
        setAboutCompany,
        setBoardOfDirectors,
        setOfficersAndRoles,
        setAboutYou,
        setMailingAddress,
      }}
    >
      {props.children}
    </StartFormContext.Provider>
  )
}
