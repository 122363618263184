import React from 'react'
import MediaQuery from 'react-responsive'
import { PageLayout } from '../components/PageLayout'
import { ReferFriendsHeader } from '../components/ReferFriendsHeader'
import { ReferFriendsTable } from '../components/ReferFriendsTable'
import { ReferFriendsTableSM } from '../components/ReferFriendsTableSM'
import { Surface } from '../components/Surface'
import { Body, BodySize } from '../components/Typography/Body'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import styled from '../lib/styled'

const SurfaceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  @media(max-width:728px){
    flex-direction: column;
    gap: 2em;
  }
`

const SurfaceBox = styled(Surface)`
  padding: 24px;
  position: relative;
  width: 100%;
  @media(min-width:728px){
    max-width: 55%;
    margin-right: 16px;
  }
  margin-bottom: 0;
`
const TotalWrapper = styled.div`
  background-color: var(--alice-blue);
  border-radius: 12px;
  width: 100%;
  max-width: 50%;
  margin-right: 23px;
  padding: 24px 20px 16px 20px;
`
const PaidWrapper = styled.div`
  border: 1px solid var(--default-border);
  border-radius: 12px;
  width: 100%;
  max-width: 50%;
  padding: 24px 20px 16px 20px;
`
const WrapperPay = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`
const BodyTotal = styled(Body)`
  letter-spacing: 1.31px;
  line-height: 17px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.7;
  margin-bottom: 5px;
`
const HeadingPay = styled(Heading)`
  line-height: 38px;
  span {
    opacity: 0.7;
  }
`
const BodyPayOut = styled(Body)`
  line-height: 24px;
  margin-bottom: 24px;
  span {
    color: var(--independence);
  }
`
const InvitesWrapper = styled.div`
  background-color: var(--secondary-light);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
`
const Box = styled.div`
  background-color: var(--secondary);
  border-radius: 8px;
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
`
const ReferIcon = styled.img`
  width: 32px;
  height: 32px;
`
const ReferIconLeft = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`
const ReferIconRight = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`
const FormWrapper = styled.div`
  width: 80%;
`
const Form = styled.div`
  margin-top: 20px;
  background-color: var(--honeydew);
  border-radius: 6px;
  height: 12px;
  display: flex;
  align-items: center;
`
const FormIn = styled.div`
  background-color: var(--secondary);
  border-radius: 6px;
  width: 160px;
  height: 8px;
`
const ReferFriendsItems = [
  {
    transactionsName: 'alicedoe@example.com',
    date: 'Jul 2, 2021',
    status: 'Started process',
  },
  {
    transactionsName: 'xyz@example.com',
    date: 'May 20, 2021',
    status: 'Pending payout',
    light: true,
  },
  {
    transactionsName: 'thomas@example.com',
    date: 'May 16, 2021',
    status: 'Eligible for $25',
    secondary: true,
  },
  {
    transactionsName: 'alex@email.com',
    date: 'Mar 12, 2021',
    status: 'Started process',
    background: true,
  },
]
export const ReferFriendsStep2 = () => (
  <PageLayout title="Refferal">
    <SurfaceWrapper>
      <SurfaceBox>
        <WrapperPay>
          <TotalWrapper>
            <BodyTotal size={BodySize.sm}>total earnings</BodyTotal>
            <HeadingPay size={HeadingSize.xl}>
              $400<span>.00</span>
            </HeadingPay>
          </TotalWrapper>
          <PaidWrapper>
            <BodyTotal size={BodySize.sm}>total earnings</BodyTotal>
            <HeadingPay size={HeadingSize.xl}>
              $300<span>.00</span>
            </HeadingPay>
          </PaidWrapper>
        </WrapperPay>
        <BodyPayOut size={BodySize.md}>
          Your next payout will be on <span>Jul 24, 2021</span>
        </BodyPayOut>
        <InvitesWrapper>
          <Box>
            <ReferIcon src="/images/refer-friends1.svg" alt="" />
            <ReferIconLeft src="/images/refer-icon-left.svg" alt="" />
            <ReferIconRight src="/images/refer-icon-right.svg" alt="" />
          </Box>
          <FormWrapper>
            <Heading size={HeadingSize.sm}>5 Invites remaining for your next offer</Heading>
            <Form>
              <FormIn></FormIn>
            </Form>
          </FormWrapper>
        </InvitesWrapper>
      </SurfaceBox>
      <ReferFriendsHeader />
    </SurfaceWrapper>
    <Heading>Invitees</Heading>
    <Body style={{ marginTop: 12, marginBottom: 32 }}>List of all people you’ve invited</Body>
    <MediaQuery query='(max-width:727px)'>
      <ReferFriendsTableSM items={ReferFriendsItems} />
    </MediaQuery>
    <MediaQuery query='(min-width:727px)'>
      <ReferFriendsTable items={ReferFriendsItems} />
    </MediaQuery>
  </PageLayout>
)
