import React, { forwardRef, useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { css } from '@emotion/core'
import moment from 'moment'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import styled from '../lib/styled'
import { Body } from './Typography/Body'
import { InfoToolTip } from './InfoToolTip'
import { Icon, IconType } from './Icon'

const DatePickerWrapper = styled.div`
  display: flex;
  position: relative;

  select {
    display: none;
  }
`

const Wrapper = styled.div<{
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  width?: string
}>`
  z-index: 999;
  position: relative;
  ${props => css`
    ${props.width ? `width: ${props.width};` : props.fullWidth ? 'width: 100%;' : ''}
    ${props.leftSpace ? 'margin-left: 12px;' : ''}
    ${props.rightSpace ? 'margin-right: 12px;' : ''}
    ${props.topSpace ? 'margin-top: 26px;' : ''}
    ${props.bottomSpace ? 'margin-bottom: 26px;' : ''}
  `}
`
const AddonStart = styled.span<{ open: boolean }>`
  background-color: ${props => props.theme.color.background};
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 3px 0 0 3px;
  height: 40px;
  padding: 0px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.open ? `border-color: ${props.theme.color.primary};` : '')}
`
const SelectField = styled.div<{
  dark?: boolean
  formStyle?: boolean
  addonStart: boolean
  open: boolean
}>`
  height: 40px;
  border: 1px solid;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;

  ${({ theme: { color, shadow }, dark, addonStart, formStyle }) => css`
      ${formStyle ? '' : `box-shadow: ${shadow.default};`}
      background-color: ${dark ? color.backgroundDark : color.white};
      border-color: ${color.defaultBorder};
      color: ${color.text.primary};
      border-radius: ${formStyle ? 3 : 6}px;

      ${
        addonStart
          ? css`
              padding-left: 7px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              border-left: none;
            `
          : ''
      }
    `}

  ${props => (props.open ? `border-color: ${props.theme.color.primary};` : '')}

  ::placeholder {
    opacity: 0.5;
  }
`
const LabelWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`
const ErrorMessage = styled(Body)`
  color: ${props => props.theme.color.error};
  margin-top: 6px;
`
const ArrowDown = styled(Icon)`
  position: absolute;
  top: 8px;
  right: 2px;
  padding: 9px 14px;
  border-left: 1px solid ${props => props.theme.color.defaultBorder};
  pointer-events: none;
  user-select: none;
`

interface DatePickerProps {
  label?: string
  dark?: boolean
  info?: string
  error?: string
  leftSpace?: boolean
  rightSpace?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  fullWidth?: boolean
  formStyle?: boolean
  disabled?: boolean
  width?: string
  value?: Date
  placeholder?: string
  onChange?: any
  addonStart?: {
    content: any
    style?: any
  }
  wrapperStyle?: any
  calendarWrapperStyle?: any
}

export const DatePicker = forwardRef((props: DatePickerProps, ref: any) => {
  const wrapperRef = useRef<any>()
  const [calendarOpen, setCalendarOpen] = useState(false)

  useOnClickOutside(wrapperRef, () => setCalendarOpen(false))

  return (
    <Wrapper
      leftSpace={props.leftSpace}
      rightSpace={props.rightSpace}
      topSpace={props.topSpace}
      bottomSpace={props.bottomSpace}
      fullWidth={props.fullWidth}
      width={props.width}
      style={props.wrapperStyle}
      ref={wrapperRef}
    >
      {props.label ? (
        <LabelWrapper>
          <Body dark>{props.label}</Body>
          {props.info ? <InfoToolTip content={props.info} /> : null}
        </LabelWrapper>
      ) : null}
      <DatePickerWrapper style={{ display: 'flex', position: 'relative' }}>
        {props.addonStart ? (
          <AddonStart open={calendarOpen} style={props.addonStart.style}>
            {props.addonStart.content}
          </AddonStart>
        ) : null}
        <SelectField
          dark={props.dark}
          formStyle={props.formStyle}
          addonStart={!!props.addonStart}
          open={calendarOpen}
          onClick={() => (props.disabled ? null : setCalendarOpen(!calendarOpen))}
        >
          {props.placeholder ? (
            <Body style={{ lineHeight: '24px', padding: '8px 0px' }}>{props.placeholder}</Body>
          ) : props.value ? (
            <Body dark style={{ lineHeight: '24px', padding: '8px 0px' }}>
              {moment(props.value).format('MMMM D, YYYY')}
            </Body>
          ) : null}
        </SelectField>
        <ArrowDown type={IconType.chevronDownLarge} />
      </DatePickerWrapper>
      {calendarOpen ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...props.calendarWrapperStyle,
          }}
        >
          <Calendar
            onChange={date => {
              props.onChange(date)
              setCalendarOpen(false)
            }}
            date={props.value as any}
          />
        </div>
      ) : null}
      {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
    </Wrapper>
  )
})
