import styled from '../lib/styled'

export const PopupMenu = styled.div<{ open: boolean }>`
  display: ${props => (props.open ? 'block' : 'none')};
  box-sizing: border-box;
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.primaryBorder};
  border-radius: 2px;
  box-shadow: ${props => props.theme.shadow.elevated};
  z-index: 1;
`
