import React from 'react'
import styled from '../../lib/styled'
import { BodySize } from '../Typography/Body'
import { Button } from '../Button'
import { TextButton } from '../TextButton'
import { CreditCardInput } from '../CreditCardInput'
import { Input } from '../Input'

const BottomInputWrapper = styled.div`
  width: calc(100% - 184px);
  position: relative;
  margin-right: 32px;
  display: inline-block;
`
const CouponInput = styled(Input)`
  width: 100%;
  box-sizing: border-box;
  padding-right: 100px;
  box-shadow: none;
`
const ApplyButton = styled(TextButton)`
  position: absolute;
  right: 24px;
  top: calc(50% - 8px);
`

export const PurchaseForm = () => (
  <form style={{ display: 'inline-block' }}>
    <CreditCardInput onSubmit={() => {}} />
    <div style={{ marginTop: 24, boxSizing: 'border-box' }}>
      <BottomInputWrapper>
        <CouponInput dark placeholder="Coupon Code" />
        <ApplyButton size={BodySize.sm}>APPLY</ApplyButton>
      </BottomInputWrapper>
      <Button style={{ width: 152 }}>
        PAY $499 <img src="/icons/arrow-right.svg" alt="arrow" style={{ marginLeft: 6 }} />
      </Button>
    </div>
  </form>
)
