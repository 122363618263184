import React from 'react'
import styled from '../lib/styled'
import { Body, BodySize } from './Typography/Body'
import { Icon, IconType } from './Icon'

const LOGOS = {
  twitter: '/images/twitter.svg',
  brew: '/images/brew.svg',
}

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.elevated3};
  border-radius: 16px 16px 72px 16px;
  width: 485px;
  max-width: 485px;
  padding: 48px 40px 32px;
  box-sizing: border-box;
  position: absolute;
  bottom: 107px;
  opacity: 0;
`
const Quote = styled.div`
  background-color: ${props => props.theme.color.primary};
  border-radius: 16px 16px 0 0;
  padding: 19px 18px;
  position: absolute;
  top: -30px;
  left: -27px;
  z-index: 1;
`
const CornerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`

export type TestimonialType = 'twitter' | 'brew'

interface TestimonialCardProps {
  content: string
  type: TestimonialType
  name: string
  subtitle: string
}

export const TestimonialCard = (props: TestimonialCardProps) => {
  const wrapperId = `testimonial-card-wrapper--${props.type}`
  const contentId = `testimonial-card-content--${props.type}`
  const contentMetaId = `testimonial-card-content-meta--${props.type}`
  const quoteIconId = `testimonial-card-quote-icon--${props.type}`

  return (
    <Wrapper id={wrapperId}>
      <Quote>
        <Icon id={quoteIconId} type={IconType.quote} />
      </Quote>
      <CornerImage src="/images/left-corner.svg" alt="" />
      <Body id={contentId} size={BodySize.xl} style={{ lineHeight: '36px', marginBottom: 30 }}>
        <i>{props.content}</i>
      </Body>
      <div
        id={contentMetaId}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Body size={BodySize.xl} dark bold style={{ marginBottom: 8 }}>
            {props.name}
          </Body>
          <Body>{props.subtitle}</Body>
        </div>
        <img src={LOGOS[props.type]} alt="" />
      </div>
    </Wrapper>
  )
}
