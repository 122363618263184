import React, { useEffect } from "react";
import anime from 'animejs'
import { useForm } from "react-hook-form";
import styled from "@emotion/styled"
import { StartFormLayout, StartFormProps } from '../StartFormLayout'
import { useStartForm } from '../StartFormProvider'
import { AboutCompany } from "./AboutCompany";
import { OperatingAgreement } from "../OperatingAgreement";

const contentId = 'about-you-content'
const cornerImageId = 'about-you-corner-image'
const cornerImageUserId = 'about-you-corner-image-user'
const cornerImageLine1Id = 'about-you-corner-image-line-1'
const cornerImageLine2Id = 'about-you-corner-image-line-2'

const LineImage = styled.img`
  position: absolute;
  -webkit-transform-origin : 0% 100%;
  -moz-transform-origin : 0% 100%;
  -o-transform-origin : 0% 100%;
  -ms-transform-origin : 0% 100%;
  transform-origin : 0% 100%;
`

export const Agreement = (props: StartFormProps<FormData>) => {
  const startForm = useStartForm()
  const goBack = () => {
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart'
    })
    setTimeout(() => props.goBack(), 500)
  }

  useEffect(() => {
    anime.timeline()
      .add({
        targets: `#${contentId}`,
        translateY: [50, 0],
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutQuart'
      }, 300)
      .add({
        targets: `#${cornerImageId}`,
        opacity: [0, 1],
        duration: 500,
        easing: 'easeOutQuart'
      }, 300)
      .add({
        targets: `#${cornerImageLine1Id}`,
        scaleX: [0, 1],
        duration: 500,
        easing: 'easeOutQuart'
      }, 800)
      .add({
        targets: `#${cornerImageLine2Id}`,
        scaleX: [0, 1],
        duration: 500,
        easing: 'easeOutQuart'
      }, 1200)
      .add({
        targets: `#${cornerImageUserId}`,
        scale: [0, 1],
        duration: 500,
        easing: 'easeOutQuart'
      }, 1200)
    return () => {}
  }, [])

  return (
    <StartFormLayout
      title=''
      description=''
      contentId={contentId}
      onNextClick={goBack}
      onPrevClick={goBack}
      hideHelpOverLay={true}
      hideHeaderContent = {true}
      hidePrevBtn={true}
      hideNextBtn={true}
      
    >
      <OperatingAgreement/>
    </StartFormLayout>
  );  
}