import React from 'react'
import MediaQuery from 'react-responsive'
import { PageLayout } from '../components/PageLayout'
import { ReferFriendsHeader } from '../components/ReferFriendsHeader'
import { Surface } from '../components/Surface'
import { Body, BodySize } from '../components/Typography/Body'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import styled from '../lib/styled'

const SurfaceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  @media(max-width:728px){
    flex-direction: column;
    gap: 2em;
  }
`

const SurfaceBox = styled(Surface)`
  padding: 24px;
  position: relative;
  width: 100%;
  @media(min-width:728px){
    max-width: 55%;
    margin-right: 16px;
  }
  margin-bottom: 0;
`
const CornerImage = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
`
const BodyParagraph = styled(Body)`
  margin-top: 12px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 64%;
  line-height: 24px;
`

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`
const ImageWrapper = styled.div``
const DetailsWrapper = styled.div`
  padding-left: 24px;
`
const HeadingRupay = styled(Heading)`
  font-size: 24px;
  span {
    color: var(--text-secondary);
    opacity: 0.7;
  }
`
const ReferWrapper = styled.div`
  background-color: var(--secondary-light);
  padding: 22px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  img {
    margin-right: 16px;
  }
`
const EmptyWrapper = styled.div`
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`
const RightIcon = styled.img`
  position: absolute;
  top: 24%;
  right: 10%;
`
const LeftIcon = styled.img`
  position: absolute;
  top: 45%;
  left: 15%;
`
const EmptyTitle = styled(Heading)`
  margin-top: 32px;
  margin-bottom: 12px;
  color: var(--independence);
`
const EmptyBody = styled(Body)`
  width: 100%;
  max-width: 40%;
  margin: 0 auto;
  text-align: center;
`

export const ReferFriendsStep1 = () => (
  <PageLayout title="Refferal">
    <SurfaceWrapper>
      <SurfaceBox>
        <MediaQuery query='(min-width:728px)'>
          <CornerImage src="/images/purchase-corner.svg" alt="" />
        </MediaQuery>
        <Heading size={HeadingSize.md} color={'var(--independence)'}>
          Refer friends, get rewards!
        </Heading>
        <BodyParagraph size={BodySize.md}>
          Refer your friends to start a company in US and get exciting bonus and offers.
        </BodyParagraph>
        <AvatarWrapper>
          <ImageWrapper>
            <img src="/images/avatar.png" alt="" />
          </ImageWrapper>
          <DetailsWrapper>
            <Body>Both you and your friend will get,</Body>
            <HeadingRupay>
              $25<span>.00</span>
            </HeadingRupay>
          </DetailsWrapper>
        </AvatarWrapper>
        <ReferWrapper>
          <img src="/icons/Refer-friends.svg" alt="" />
          <Heading size={HeadingSize.sm} color={'var(--independence)'}>
            Refer 10 friends and get 1 year accounting for free!
          </Heading>
        </ReferWrapper>
      </SurfaceBox>
      <ReferFriendsHeader />
    </SurfaceWrapper>
    <Heading>Invitees</Heading>
    <Body style={{ marginTop: 12, marginBottom: 32 }}>List of all people you’ve invited</Body>
    <EmptyWrapper>
      <img src="/images/empty-center-icon.svg" alt="" />
      <RightIcon src="/images/empty-right-icon.svg" alt="" />
      <LeftIcon src="/images/empty-left-icon.svg" alt="" />
      <EmptyTitle size={HeadingSize.md}>It’s empty here..</EmptyTitle>
      <EmptyBody size={BodySize.md}>
        Looks like you haven’t invited anyone yet, start inviting people with their email address to
        get rewards!
      </EmptyBody>
    </EmptyWrapper>
  </PageLayout>
)
