import React, { useState, useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { NavLink } from 'react-router-dom'
import styled from '../lib/styled'
import { Body } from './Typography/Body'
import { IconType } from './Icon'
import { PopupMenu } from './PopupMenu'
import { HeaderDropdownMenuItem } from './HeaderDropdownMenuItem'
import MediaQuery from 'react-responsive'

const Wrapper = styled.div<{ open: boolean }>`
  background-color: ${props => props.theme.color.white};
  border: 1px solid
    ${props => (props.open ? props.theme.color.primaryBorder : props.theme.color.defaultBorder)};
  border-radius: 2px;
  padding: 6px 12px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
`
const InitialsIcon = styled.div`
  background-color: ${props => props.theme.color.primaryLight};
  border-radius: 100%;
  color: ${props => props.theme.color.text.secondary};
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  height: 24px;
`
const MenuWrapper = styled(PopupMenu)`
  position: absolute;
  right: 0px;
  top: 48px;
  width: calc(100% - 16px);
  z-index: 9;
  @media (max-width: 600px){
    min-width: 200px;
  }
`
const Divider = styled.div`
  height: 1px;
  background-color: ${props => props.theme.color.defaultBorder};
  margin: 0px 12px;
  box-sizing: border-box;
`

interface HeaderDropdownMenuProps {
  label: string
}

export const HeaderDropdownMenu = (props: HeaderDropdownMenuProps) => {
  const [open, setOpen] = useState(false)
  const menuRef = useRef<any>()
  const initials = props.label
    .split(' ')
    .map(s => s[0])
    .slice(0, 2)
    .join('')

  useOnClickOutside(menuRef, () => setOpen(false))

  return (
    <div ref={menuRef} style={{ position: 'relative' }}>
      <Wrapper open={open} onClick={() => setOpen(!open)}>
        <InitialsIcon>{initials}</InitialsIcon>
        <MediaQuery query="(min-width:600px)">
            <Body style={{ marginLeft: 6, marginRight: 8 }}>{props.label}</Body>
        </MediaQuery>
        <img src="/icons/chevron-down.svg" alt="" />
      </Wrapper>
      <MenuWrapper open={open}>
        {/*<NavLink to="/settings">
          <HeaderDropdownMenuItem icon={IconType.profile} label="My account" />
  </NavLink>*/}
        <NavLink to="/get-help"> 
          <HeaderDropdownMenuItem icon={IconType.chat} label="Get Help" />
        </NavLink>
        <Divider />
        <HeaderDropdownMenuItem icon={IconType.logout} label="Logout" />
      </MenuWrapper>
    </div>
  )
}
