import styled from '@emotion/styled'
import React from 'react'
import { HomeNotification } from '../components/HomeNotification'
import { PageLayout } from '../components/PageLayout'
import { PaymentsSetup } from '../components/PaymentsSetup'
import { PaymentsSetupCard } from '../components/PaymentsSetupCard'

const items = [
  {
    lefttitle: 'Sign your SS4 application',
    leftbody: 'You need to sign your SS4 application inorder to proceed with the next steps.',
    images: '/images/error-icon.svg',
    dismiss: false,
    button: 'sign now',
    type: 'error',
  },
  {
    lefttitle: 'Your process has started',
    leftbody: 'This is an informational notification.',
    images: '/images/info-outined.svg',
    dismissTwo: true,
    type: 'primary',
  },
  {
    lefttitle: 'Your debit card has been shipped',
    leftbody: 'You can now track your debit card.',
    images: '/images/credit-card.svg',
    dismissOne: true,
    type: 'primary',
    button: 'track now',
  },
]
const payments = [
  {
    lefttitle: 'Setup your payments',
    leftbody: 'Easily manage all your payments with Quickpay!',
    images: '/images/payments-corner.svg',
    button: 'Connect to stripe',
    type: 'blue',
    donetitle: ['Easy Invoicing', 'Custom Pay Link', 'Share Quickly'],
    componentType: 'payments',
  },
]
const compliance = [
  {
    lefttitle: 'Worry-Free Compliance',
    leftbody: 'Never miss any state filing dates or tax reminders.',
    images: '/images/compliance-icon.svg',
    button: 'Subscribe now',
    type: 'primary',
    donetitle: ['WhatsApp reminder', 'Call reminder', 'Third benefit', 'Another advantage'],
  },
]
const book = [
  {
    lefttitle: 'Accounting & Bookkeeping',
    leftbody: 'This will be a short description of accounting and bookkeeping',
    images: '/images/receipt.svg',
    button: 'Go to accounting',
    type: 'primary',
    donetitle: ['Monthly billing', 'Bookkeeping', 'Annual filings'],
    componentType: 'book',
  },
]

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`

export const HomeState = () => {
  return (
    <PageLayout title="Afternoon, John!">
      <Wrapper>
        <HomeNotification items={items} />
        <PaymentsSetup items={payments} />
        <PaymentsSetupCard items={compliance} style={{ paddingLeft: 0, paddingRight: 16 }} />
        <PaymentsSetup items={book} />
      </Wrapper>
    </PageLayout>
  )
}
