import React from 'react'
import { Link } from 'react-router-dom'
import styled, { theme } from '../lib/styled'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { Surface } from '../components/Surface'
import { MoneyAmount } from '../components/MoneyAmount'
import { InfoBox } from '../components/InfoBox'
import { TextButton } from '../components/TextButton'
import { Button } from '../components/Button'
import { Icon, IconType } from './Icon'

const TableWrapper = styled.div`
  margin-top: 32px;
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 4px;
  padding: 16px;
`
const TableRow = styled.div<{ last?: boolean }>`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1.5fr;
  padding: 16px 0px;
  ${props => (props.last ? 'padding-bottom: 0px' : '')}
  ${props => (props.last ? '' : `border-bottom: 1px solid ${props.theme.color.defaultBorder};`)}
`
const Actions = styled.div`
  padding: 24px;
  border-top: 1px solid ${props => props.theme.color.defaultBorder};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`

const ITEMS = [
  { title: 'Legal Advising', quantity: 2, price: 500 },
  { title: 'Legal Advising', quantity: 2, price: 500 },
]

export const InvoicePreview = (props: { close: () => void }) => (
  <Surface style={{ width: 600 }} onClick={e => e.stopPropagation()}>
    <div style={{ padding: '36px 24px 24px 24px' }}>
      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <Heading size={HeadingSize.lg}>
          <span style={{ color: theme.color.text.secondary, opacity: '0.7' }}>#</span>
          AL2545
        </Heading>
        <img src="/images/orginization-brand.svg" width={74} alt="" />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <div>
          <Heading
            size={HeadingSize.sm}
            style={{ marginTop: 8, color: theme.color.text.secondary }}
            color={theme.color.text.secondary}
          >
            LEGAL CONSULTING
          </Heading>
          <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 32 }}>
            <div style={{ marginRight: 56 }}>
              <Body size={BodySize.sm}>Isued on</Body>
              <Heading
                size={HeadingSize.sm}
                style={{ color: theme.color.text.secondary, marginTop: 4 }}
              >
                Oct 25, 2020
              </Heading>
            </div>
            <div>
              <Body size={BodySize.sm}>Due on</Body>
              <Heading
                size={HeadingSize.sm}
                style={{ color: theme.color.text.secondary, marginTop: 4 }}
              >
                Nov 04, 2020
              </Heading>
            </div>
            <div />
          </div>
        </div>
        <div style={{ textAlign: 'end', lineHeight: '22px', marginTop: 16 }}>
          <Body>340 S LEMON AVE #3696</Body>
          <Body>Walnut, California</Body>
          <Body>United States 91789</Body>
        </div>
      </div>
      <div style={{ marginTop: 32 }}>
        <Body size={BodySize.sm}>Invoice for</Body>
        <div style={{ lineHeight: '22px' }}>
          <Heading
            size={HeadingSize.sm}
            style={{ color: theme.color.text.secondary, marginTop: 4 }}
          >
            Alex Parkinson
          </Heading>
          <Body>3897 Hickory St, Salt Lake City</Body>
          <Body>Utah, United States 84104</Body>
        </div>
      </div>
      <TableWrapper>
        <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr 1fr 1.5fr' }}>
          <Body size={BodySize.sm}>Item</Body>
          <Body style={{ textAlign: 'end' }} size={BodySize.sm}>
            Qty
          </Body>
          <Body style={{ textAlign: 'end' }} size={BodySize.sm}>
            Price
          </Body>
          <Body style={{ textAlign: 'end' }} size={BodySize.sm}>
            Total
          </Body>
        </div>
        {ITEMS.map((item, i) => (
          <TableRow last={i === ITEMS.length - 1}>
            <Heading size={HeadingSize.sm}>{item.title}</Heading>
            <Heading style={{ textAlign: 'end' }} size={HeadingSize.sm}>
              {item.quantity}
            </Heading>
            <Heading style={{ textAlign: 'end' }} size={HeadingSize.sm}>
              ${item.price}
            </Heading>
            <Heading style={{ textAlign: 'end' }} size={HeadingSize.sm}>
              ${item.price * item.quantity}
            </Heading>
          </TableRow>
        ))}
      </TableWrapper>
      <div style={{ display: 'grid', gridTemplateColumns: '220px 1fr 1fr', marginTop: 22 }}>
        <Body size={BodySize.xs}>
          Here we can write a additional notes for the client to get a better understanding of this
          invoice.
        </Body>
        <Body size={BodySize.xs} style={{ textAlign: 'end' }}>
          Total Amount
        </Body>
        <MoneyAmount style={{ textAlign: 'end' }} size={HeadingSize.md}>
          $1400.00
        </MoneyAmount>
      </div>
      <InfoBox style={{ marginTop: 48 }}>
        Don't worry, client will be get a hosted payment field here to make payment with any credit
        card, debit card or bank transfer.
      </InfoBox>
    </div>
    <Actions>
      <div>
        <TextButton style={{ fontSize: 14 }}>
          <Icon
            type={IconType.download}
            style={{ position: 'relative', marginRight: 12, top: 1 }}
          />
          DOWNLOAD INVOICE
        </TextButton>
        <Body size={BodySize.xs} style={{ marginTop: 12 }}>
          <span>You can update your logo and brand color in </span>
          <Link to="/payments/settings">
            <span style={{ color: theme.color.primary }}>payment settings</span>
          </Link>
        </Body>
      </div>
      <Button light onClick={props.close}>
        CLOSE
      </Button>
    </Actions>
  </Surface>
)
