import React from 'react'
import styled from '../lib/styled'

const Track = styled.div`
  background-color: ${props => props.theme.color.primaryLight};
  border-radius: 4px;
  width: 100%;
  height: 8px;
`
const Bar = styled.div<{ progress: number }>`
  background-color: ${props => props.theme.color.primary};
  width: ${props => props.progress}%;
  border-radius: 4px;
  height: 100%;
`

export const ProgressBar = (props: { progress: number }) => {
  return (
    <Track>
      <Bar progress={props.progress} />
    </Track>
  )
}
