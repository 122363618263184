import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { CallCard } from '../components/CallCard'
import { PerkCard } from '../components/PerkCard'
import MediaQuery from 'react-responsive'

export const Perks = () => (
  <PageLayout title="Perks">
    <MediaQuery query='(min-width:728px)'>
      <div style={{ display: 'flex' }}>
        <CallCard style={{ width: '100%', marginRight: 36 }} />
        <PerkCard
          title="Moo Business cards"
          body="Get 25% discount on your first order of incredible business cards and other stationaries."
          image="/images/credit-card-perk.svg"
          style={{ width: 'calc(50% - 36px)' }}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <PerkCard
          title="Amazon Web Services"
          body="Get $1000 credits now and upto $100,000 based on your product."
          image="/images/aws-perk.svg"
          style={{ width: '100%' }}
        />
        <PerkCard
          title="Transferwise"
          body="Get free international transaction upto $500."
          image="/images/transferwise-perk.svg"
          style={{ width: '100%', marginLeft: 36, marginRight: 36 }}
        />
        <PerkCard
          title="WeWork"
          body="Get your office in one of the finest locations."
          image="/images/wework-perk.svg"
          style={{ width: '100%' }}
        />
      </div>
    </MediaQuery>

    <MediaQuery query='(max-width:728px)'>
      <div style={{ display: 'flex',flexDirection:'column' }}>
      <CallCard style={{ width: '100%'}} />
        <PerkCard
          title="Moo Business cards"
          body="Get 25% discount on your first order of incredible business cards and other stationaries."
          image="/images/credit-card-perk.svg"
        />
        <PerkCard
          title="Amazon Web Services"
          body="Get $1000 credits now and upto $100,000 based on your product."
          image="/images/aws-perk.svg"
          style={{ width: '100%' }}
        />
        <PerkCard
          title="Transferwise"
          body="Get free international transaction upto $500."
          image="/images/transferwise-perk.svg"
          style={{ width: '100%',}}
        />
        <PerkCard
          title="WeWork"
          body="Get your office in one of the finest locations."
          image="/images/wework-perk.svg"
          style={{ width: '100%' }}
        />
      </div>
    </MediaQuery>
    
  </PageLayout>
)
