import styled, { CreateStyled } from '@emotion/styled'

export type Theme = {
  color: {
    primary: string
    primaryLight: string
    primaryDark: string
    primaryBorder: string
    secondary: string
    secondaryLight: string
    secondaryBorder: string
    error: string
    errorLight: string
    errorBorder: string
    warning: string
    warningLight: string
    background: string
    backgroundDark: string
    defaultBorder: string
    defaultBorderTwo: string
    white: string
    primary00: string
    primary04: string
    primary004: string
    aliceBlue: string
    independence: string
    ghostWhite: string
    text: {
      primary: string
      secondary: string
      lightSecondary: string
      info: string
      disabled: string
    }
  }
  shadow: {
    default: string
    elevated: string
    elevated2: string
    elevated3: string
  }
}

export const theme = {
  color: {
    primary: 'var(--primary)',
    primaryLight: 'var(--primary-light)',
    primaryDark: 'var(--primary-dark)',
    primaryBorder: 'var(--primary-border)',
    secondary: 'var(--secondary)',
    secondaryLight: 'var(--secondary-light)',
    secondaryBorder: 'var(--secondary-border)',
    warning: 'var(--warning)',
    warningLight: 'var(--warning-light)',
    error: 'var(--error)',
    errorLight: 'var(--error-light)',
    errorBorder: 'var(--error-border)',
    background: 'var(--background)',
    backgroundDark: 'var(--background-dark)',
    defaultBorder: 'var(--default-border)',
    defaultBorderTwo: 'var(--default-border-two)',
    ghostWhite: 'var(--ghost-White)',
    white: 'var(--white)',
    primary00: 'var(--primary-00)',
    primary04: 'var(--primary-04)',
    primary004: 'var(--primary-004)',
    aliceBlue: 'var(--alice-blue)',
    independence: 'var(--independence)',
    text: {
      primary: 'var(--text-primary)',
      secondary: 'var(--text-secondary)',
      lightSecondary: 'var(--text-light-secondary)',
      info: 'var(--text-info)',
      disabled: 'var(--text-disabled)',
    },
  },
  shadow: {
    default: '0 3px 5px 0 rgba(0, 0, 0, 0.04)',
    elevated: '0 3px 8px 0 rgba(0, 0, 0, 0.08)',
    elevated2: '0 5px 12px 0 rgba(0, 0, 0, 0.12)',
    elevated3: '0 8px 12px 0 rgba(0, 0, 0, 0.04)',
  },
} as Theme

export default styled as CreateStyled<Theme>
export * from '@emotion/core'
