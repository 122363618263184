import React, { useState, useMemo, useContext, createContext } from 'react'
import { StartFormProvider } from '../components/StartFormProvider'
import { EnterEmail } from '../components/StartPages/EnterEmail'
import { ChooseCompanyType } from '../components/StartPages/ChooseCompanyType'
import { ChooseCompanyState } from '../components/StartPages/ChooseCompanyState'
import { BusinessDetails } from '../components/StartPages/BusinessDetails'
import { AboutCompany } from '../components/StartPages/AboutCompany'
import { BoardOfDirectors } from '../components/StartPages/BoardOfDirectors'
import { OfficersAndRoles } from '../components/StartPages/OfficersAndRoles'
import { AboutYou } from '../components/StartPages/AboutYou'
import { MailingAddress } from '../components/StartPages/MailingAddress'
import { CreateAccount } from '../components/StartPages/CreateAccount'

const StartStepContext = createContext({
  currentPage: 0,
  lastPage: 0,
})

export const useStartStep = () => useContext(StartStepContext)

export const Start = () => {
  const [step, setStep] = useState({
    currentPage: 0,
    lastPage: 0,
  })
  const page = useMemo(() => {
    const onSubmit = () =>
      setStep({
        currentPage: step.currentPage + 1,
        lastPage: step.currentPage,
      })
    const goBack = () =>
      setStep({
        currentPage: step.currentPage - 1,
        lastPage: step.currentPage,
      })
    switch (step.currentPage) {
      case 0:
        return <EnterEmail onSubmit={onSubmit} />
      case 1:
        return <ChooseCompanyType onSubmit={onSubmit} />
      case 2:
        return <ChooseCompanyState onSubmit={onSubmit} />
      case 3:
        return <BusinessDetails onSubmit={onSubmit} goBack={goBack} />
      case 4:
        return <AboutCompany onSubmit={onSubmit} goBack={goBack} />
      case 5:
        return <BoardOfDirectors onSubmit={onSubmit} goBack={goBack} />
      case 6:
        return <OfficersAndRoles onSubmit={onSubmit} goBack={goBack} />
      case 7:
        return <AboutYou onSubmit={onSubmit} goBack={goBack} />
      case 8:
        return <MailingAddress onSubmit={onSubmit} goBack={goBack} />
      case 9:
        return <CreateAccount onSubmit={onSubmit} goBack={goBack} />
      default:
        return <EnterEmail onSubmit={onSubmit} />
    }
  }, [step])

  return (
    <StartFormProvider>
      <StartStepContext.Provider value={step}>{page}</StartStepContext.Provider>
    </StartFormProvider>
  )
}
