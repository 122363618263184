import React from 'react'
import { PageLayout } from '../components/PageLayout'
import styled from '../lib/styled'
import { ChatBox } from '../components/ChatBox'
import { TaxProgressBar } from '../components/TaxProgressBar'

const FederalWrapper = styled.div`
  display: flex;
  width: 105%;
  @media(max-width:728px){
    flex-direction: column;
    width: 100%;
    gap: 2em;
  }
`

export const TaxFederal = () => {
  return (
    <PageLayout title="Federal Tax">
      <FederalWrapper>
        <ChatBox />
        <TaxProgressBar />
      </FederalWrapper>
    </PageLayout>
  )
}
