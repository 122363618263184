import React, { useState } from 'react'
import styled from '../lib/styled'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { RadioButton } from '../components/RadioButton'

const LAWYERS = {
  visa: {
    title: 'Visa xxxx 1657  ',
    pic: '/images/visa.svg',
    description: 'Expires on 16/24',
  },
  'master-card': {
    title: 'Mastercard xxxx 9878',
    fee: 'Free',
    pic: '/images/mastercard.svg',
    description: 'Expires on 20/28',
  },
}

const Wrapper = styled.div<{ active: boolean }>`
  border: 1px solid
    ${props =>
      props.active ? props.theme.color.defaultBorder : props.theme.color.defaultBorderTwo};
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px 16px 12px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
const CardTypeWrapper = styled.div`
  background-color: var(--background);
  border-radius: 8px;
  width: 56px;
  height: 41px;
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CardHeading = styled(Heading)`
  margin-bottom: 4px;
`
const CardBody = styled(Body)`
  font-weight: 600;
`
const Input = styled.input<{ active: boolean }>`
  background-color: var(--ghost-White);
  border: 0.7px solid var(--default-border);
  border-radius: 4px;
  width: 59px;
  height: 32px;
  padding: 0;
  outline: 0;
  text-align: center;
  color: var(--text-secondary);
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 16px;
  margin-left: auto;
  opacity: ${props => (props.active ? 1 : 0)};
`

interface PaymentLegalCardProps {
  active: boolean
  type: 'visa' | 'master-card'
  onClick: () => void
}

export const PaymentLegalCard = (props: PaymentLegalCardProps) => {
  const lawyer = LAWYERS[props.type]
  const [cvc, setCvc] = useState()

  const setInputValue = (e: any) => {
    if (e.target.value.length <= 3) {
      setCvc(e.target.value)
    }
  }
  return (
    <Wrapper active={!!props.active} onClick={props.onClick}>
      <RadioButtonWrapper>
        <RadioButton selected={props.active} />
        <CardTypeWrapper>
          <img src={lawyer.pic} alt={lawyer.title} />
        </CardTypeWrapper>
        <div>
          <CardHeading size={HeadingSize.sx} color={'var(--independence)'}>
            {lawyer.title}
          </CardHeading>
          <CardBody size={BodySize.xs}>{lawyer.description}</CardBody>
        </div>
        <Input
          placeholder="CVC"
          type="number"
          onChange={setInputValue}
          value={cvc}
          active={props.active}
        />
      </RadioButtonWrapper>
    </Wrapper>
  )
}
