import React from 'react'
import styled, { Theme, theme } from '../lib/styled'
import { Body, BodySize } from './Typography/Body'

type ButtonVariants = 'primary' | 'warning' | 'error' | 'aliceBlue'

const Wrapper = styled.button<{
  disabled?: boolean
  light?: boolean
  outline?: boolean
  variant?: ButtonVariants
}>`
  display: inline-block;
  background-color: ${props => getBackgroundColor(props)};
  border-radius: 6px;
  padding: 11px 17px;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${props => getBorderColor(props)};
  white-space: nowrap;
  ${props => (props.disabled ? 'opacity: 0.38;' : '')} :focus {
    outline: none;
  }
  transition: All 0.2s;
  :hover {
    ${props =>
      props.light
        ? `background-color: #fff;
    border-color:#357d45;
    div{
      color:#357d45;
    }
    `
        : `background-color: #2a6437;
    border-color:#2a6437;`}
  }
  ${props =>
    props.variant === 'aliceBlue' &&
    `
    padding: 15px 17px;
    :hover{
      background-color: #2a6437;
      border-color:#2a6437;
      div{
        color:#fff;
      }
    }
    `}
`

const getBackgroundColor = (args: {
  theme: Theme
  light?: boolean
  outline?: boolean
  variant?: ButtonVariants
}) => {
  if (args.outline) {
    return args.theme.color.white
  } else if (args.light) {
    return args.variant === 'warning'
      ? args.theme.color.warningLight
      : args.variant === 'error'
      ? args.theme.color.errorLight
      : args.variant === 'primary'
      ? args.theme.color.primaryLight
      : args.theme.color.white
  } else {
    return args.variant === 'warning'
      ? args.theme.color.warning
      : args.variant === 'error'
      ? args.theme.color.error
      : args.variant === 'aliceBlue'
      ? args.theme.color.aliceBlue
      : args.theme.color.primary
  }
}

const getBorderColor = (args: {
  theme: Theme
  light?: boolean
  outline?: boolean
  variant?: ButtonVariants
}) => {
  if (args.light) {
    return args.variant === 'warning'
      ? args.theme.color.warningLight
      : args.variant === 'error'
      ? args.theme.color.errorLight
      : args.variant === 'primary'
      ? args.theme.color.primaryLight
      : args.theme.color.defaultBorder
  } else {
    return args.variant === 'warning'
      ? args.theme.color.warning
      : args.variant === 'error'
      ? args.theme.color.error
      : args.variant === 'aliceBlue'
      ? args.theme.color.aliceBlue
      : args.theme.color.primary
  }
}
const getColor = (args: { light?: boolean; outline?: boolean; variant?: ButtonVariants }) => {
  if (args.outline || args.light) {
    return args.variant === 'warning'
      ? theme.color.warning
      : args.variant === 'error'
      ? theme.color.error
      : args.variant === 'primary'
      ? theme.color.primary
      : undefined
  } else {
    return args.variant === 'aliceBlue' ? theme.color.primary : theme.color.white
  }
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  light?: boolean
  outline?: boolean
  small?: boolean
  variant?: ButtonVariants
}

export const Button = (props: ButtonProps) => (
  <Wrapper
    style={props.style}
    disabled={props.disabled}
    type={props.type}
    light={props.light}
    outline={props.outline}
    onClick={props.onClick}
    variant={props.variant}
  >
    <Body
      bold
      light={!props.light}
      size={props.small ? BodySize.xs : BodySize.sm}
      style={{ userSelect: 'none', letterSpacing: '1.31px' }}
      color={getColor(props)}
    >
      {props.children}
    </Body>
  </Wrapper>
)
