import React, { useEffect } from 'react'
import styled from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Input } from './Input'
import { Icon, IconType } from './Icon'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  body {
    overflow-x: hidden;
  }
`
const Backdrop = styled.div`
  background-color: ${props => props.theme.color.white};
  opacity: 0.8;
  width: 100%;
  height: 100%;
`
const ModalWrapper = styled.div`
  background-color: ${props => props.theme.color.white};
  border-radius: 16px;
  box-shadow: ${props => props.theme.shadow.elevated2};
  width: 500px;
  padding: 48px 40px;
  box-sizing: border-box;
  position: absolute;
  top: 30%;
  left: calc(50% - (500px / 2));
`

interface ShareLinkModalProps {
  open: boolean
  close: () => void
  style?: any
}

export const ShareLinkModal = (props: ShareLinkModalProps) => {
  useEffect(() => {
    if (props.open) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
    return () => {}
  }, [props.open])

  return props.open ? (
    <Wrapper style={props.style}>
      <Backdrop />
      <ModalWrapper>
        <Icon
          type={IconType.close}
          style={{ position: 'absolute', top: 36, right: 36, cursor: 'pointer' }}
          onClick={props.close}
        />
        <Heading size={HeadingSize.lg} style={{ marginBottom: 12 }}>
          Share link generated
        </Heading>
        <Body size={BodySize.lg}>Anyone with this link can view the files.</Body>
        <Input
          value="https://www.startglobal.co/s/ijo2v1t052cu7ox/document"
          fullWidth
          style={{ marginTop: 40, height: 40 }}
        />
        <Body
          bold
          color="var(--primary)"
          style={{ cursor: 'pointer', marginTop: 20, textAlign: 'right' }}
        >
          COPY LINK
        </Body>
      </ModalWrapper>
    </Wrapper>
  ) : null
}
