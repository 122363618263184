import React from 'react'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Input } from '../Input'
import { Select } from '../Select'
import { InfoBox } from '../InfoBox'
import { Form } from '../Form'
import { TextArea } from '../TextArea'

export type FormData = {
  name: string
  type: string
  description: string
}

export const AboutCompanyForm = (props: FormProps<FormData>) => {
  const { register, errors, onSubmit } = useGetFormHook(props)

  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <div style={{ display: 'flex', width: '100%', alignItems: 'flex-end' }}>
        <Input
          name="name"
          label="Company name"
          info="..."
          error={errors.name && errors.name.message}
          rightSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
        <Select
          name="type"
          error={errors.type && errors.type.message}
          leftSpace
          formStyle
          wrapperStyle={{ width: '50%' }}
          style={{ width: '100%' }}
          options={[{ label: 'Inc.', value: 'INC' }]}
          ref={register({ required: 'Required' })}
        />
      </div>
      <InfoBox style={{ marginBottom: 16 }}>
        Inc, Incorporated, Corp or Corporation are mandatory for business names.
      </InfoBox>
      <TextArea
        name="description"
        label="Business description"
        error={errors.name && errors.name.message}
        fullWidth
        rows={4}
        topSpace
        ref={register({ required: 'Required' })}
      />
    </Form>
  )
}
