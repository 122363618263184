import React, { useEffect, useRef, useState } from "react";
import { useGetFormHook, FormProps } from '../../../lib/form'
import { Input } from "../../Input";
import { Form } from '../../Form'
import styled from "@emotion/styled"
import { Heading, HeadingSize } from "../../Typography/Heading";
import { Icon, IconType } from "../../Icon";
import Collapsible from "react-collapsible";
import { Checkbox } from "../../Checkbox";
import { Body, BodySize } from "../../Typography/Body";
import useOnClickOutside from "use-onclickoutside";
import { useFieldArray } from "react-hook-form";
import { useStartForm } from "../../OperatingAgreement/StartFormProvider";

export type FormData = {
  members: Member[]
};

type Member = {
  firstName: string
  lastName: string
  email: string
  address: string
  state : string
  country : string
  city : string
  zip : string
  cashContribution: true
  nonCashContribution: true
  cashContributionAmount: string
  ownershipPercentage: number
  status: string
  contributions: Contribution[]
}

type Contribution = {
  contributionType: string
  contributionAmount: number
}
const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1279px){
    flex-direction: column;
    row-gap: 0.75rem;
  }
`

const FormRowWrapper = styled(FormWrapper)`
  margin-top: 32px;
  @media (max-width: 1279px){
    margin-top: none;
  }
`
const Button = styled.div`
  background: white;
  border: 1px solid #357d45;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 4%);
  border-radius: 0.5em;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 21px;
  color: #357d45;
  margin-bottom: 2em;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
`
const WrapperList = styled.div`
  background-color: white
  border: 1px solid #d4ddeb;
  border-radius: 0.5em;
  padding:18px;
  margin-bottom: 1em;
`
const WraBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
const AddItemWrapper = styled.div`
@media (min-width: 728px) {
  margin-top: 16px;
}
`
const CheckBoxWrapper = styled.div`
display: flex;
flex-direction: column;
@media (min-width: 728px) {
  margin-top: 32px;
}

`

const CollapsibleWrapper = styled.div`
@media (max-width: 728px) {
  margin-top: 1em;
}
`
const CheckboxSpan = styled.div`

@media (max-width: 728px) {
  display: flex;
flex-direction: column;
gap: 1em;
}
@media (min-width: 728px) {
  display: flex;
flex-direction: row;
gap: 2em;
}
`

const BodyWrapper = styled.div`
@media (max-width: 728px) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  gap: 1.5em;
}
`
const CheckboxLabel = styled(Body)`
  font-size: 1em;
  margin-left: 1em;
`

const Label = styled.div`
  font-size: 1.25em;
  line-height: 23px;
  color: #0cc572;
  font-style: normal;
  font-weight: 600;
`

const ItemWrapper = styled.div`
  display: grid;
  grid-column-gap: 24px;
  margin-bottom: 1em;
`
const ItemWrapperContribution = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 4fr 2fr  0.3fr;
  margin-bottom: 1em;

`
export const TextButton = styled(Body)`
  color: #357d45;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 17px;
  cursor: pointer;
  user-select: none;
`;
const ItemMenuButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  .icon-color-part {
    fill: #757a89;
  }
  :hover {
    .icon-color-part {
      fill: #357d45
    }
  }
`
const DeleteButton = styled.div<{ open: boolean }>`
  background-color: white;
  border: 1px solid #d4ddeb;
  border-radius: 6px;
  padding: 0.75em;
  box-shadow: 0 10px 1em 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 0;
  top: 28px;
  display: ${props => (props.open ? 'inherit' : 'none')};
`
const NameWrapper = styled.div`
@media (max-width: 728px) {
  gap: 1.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 728px) {
  gap: 1em;
  display: flex;
flex-direction: row;
}
`
const ItemMenuButton = (props: { onClickRemove: () => void }) => {
  const wrapperRef = useRef<any>()
  const [menuOpen, setMenuOpen] = useState(false)

  useOnClickOutside(wrapperRef, () => setMenuOpen(false))

  return (
    <ItemMenuButtonWrapper onClick={() => setMenuOpen(true)}>
      <svg width="1em" height="4px" viewBox="0 0 16 4" version="1.1" className="menu-item-icon">
        <g id="Clean" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            className="icon-color-part"
            id="Remove-Item"
            transform="translate(-1408.000000, -561.000000)"
          >
            <g id="Group-7" transform="translate(932.000000, 271.000000)">
              <g id="Group-3" transform="translate(476.000000, 290.000000)">
                <circle id="Oval" cx="2" cy="2" r="2"></circle>
                <circle id="Oval" cx="8" cy="2" r="2"></circle>
                <circle id="Oval" cx="14" cy="2" r="2"></circle>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <DeleteButton open={menuOpen} ref={wrapperRef} onClick={props.onClickRemove}>
        <Body color='#f02f2f' style={{ whiteSpace: 'nowrap' }}>
          Remove Item
        </Body>
      </DeleteButton>
    </ItemMenuButtonWrapper>
  )
}

interface  ContributionArrayProps {
  nestIndex : number
}
// export const ContributionArray = ( nestIndex, control, register, errors,setValue, watch ) => {
//   const { fields, remove, append } = useFieldArray({
//     control,
//     name: `members[${nestIndex}].contributions`
//   });
//   useEffect( () => {
//     append({
//       contributionType: "",
//       amount: ""
//     })
//   }, [])
//   const numberFormat = (value : number) =>
//     new Intl.NumberFormat('en-US', {
//       style: 'currency',
//       currency: 'USD'
//     }).format(value);

//     function onchangeAmount(value: string, j: number) {
//       // value =value.replaceAll('$','');
//       // value =value.replaceAll(',','');
//       // value = value.split('.')[0];
//       // value = numberFormat(Number(value));
//       if(value === '$NaN'){
//         //setValue(`members.${nestIndex}.contributions.${j}.contributionAmount`, '$0.00')
//       }else{
//         //setValue(`members.${nestIndex}.contributions.${j}.contributionAmount`, value)
//       }
//     }
  
//   return (
//     <AddItemWrapper>
//       {fields.map((item, j) => {
//         return (
//           <ItemWrapperContribution>
//             <Input
//               // error={
//               //   errors.members[nestIndex]?.contributions[j] &&
//               //   errors.members[nestIndex]?.contributions[j] &&
//               //   errors.members[nestIndex]?.contributions[j].contributionType &&
//               //   errors.members[nestIndex]?.contributions[j].contributionType?.message
//               // }
//               fullWidth
//               formStyle
//               placeholder="Contribution Type"
//               //{...register(`members.${nestIndex}.contributions.${j}.contributionType`, {  })}
//             />
//             <Input
//               type="text"
//               fullWidth
//               formStyle
//               placeholder="$0.00"
//               // error={
//               //   errors.members[nestIndex]?.contributions[j] &&
//               //   errors.members[nestIndex]?.contributions[j] &&
//               //   errors.members[nestIndex]?.contributions[j].contributionAmount &&
//               //   errors.members[nestIndex]?.contributions[j].contributionAmount?.message
//               // }
//               //{...register(`members.${nestIndex}.contributions.${j}.contributionAmount`, )}
//               //onBlur={() => onchangeAmount(watch(`members.${nestIndex}.contributions.${j}.contributionAmount`), j)}
//             />
//             <ItemMenuButton onClickRemove={() => remove(j)} />
//           </ItemWrapperContribution>
//         )
//       })}
//       <ItemWrapper>
//         <TextButton
//           style={{ fontSize: 14 }}
//           onClick={() =>
//             append({
//               contributionType: "",
//               amount: ""
//             })
//           }
//         >
//           + ADD CONTRIBUTION
//         </TextButton>
//         <div />
//         <div />
//       </ItemWrapper>
//     </AddItemWrapper>
//   );
// }
export const MemberForm = (props: FormProps<FormData>) => {
  const startForm = useStartForm()
  const { register, errors, onSubmit, control, watch, setValue } = useGetFormHook(props)
  const { fields, append, remove } = useFieldArray({ control, name: 'members' })
  const [memberNames, setMemberNames] = useState([]);
  const [openType, setOpen] = useState(1);
  const [i, setI] = useState(0);
  const [cashContribution, setCashContribution] = useState(true);
  const [nonCashContribution, setNonCashContribution] = useState(false);
  const addMemeber = (pos: number) => {
  }
  const handleAccordianChange = (type: number) => {
    if (type === openType) {
      setOpen(-1)
    } else {
      setOpen(type)
    }
  }
  function onClickCashContribution(value: boolean, i: number) {
    setCashContribution(value);
    setValue(`members.${i}.cashContribution`, value)
  }
  function formatAmount(value: number, index: number) {
    if (value) {
    }
  }
  const numberFormat = (value : number) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);

  function onClickNonCashContribution(value: boolean, i: number) {
    setNonCashContribution(value);
    setValue(`members.${i}.nonCashContribution`, value)
  }

  function onchangeAmount(value: string, i: number) {
    // value =value.replaceAll('$','');
    // value =value.replaceAll(',','');
    // value = value.split('.')[0];
    // value = numberFormat(Number(value));
    // if(value === '$NaN'){
    //   setValue(`members.${i}.cashContributionAmount`, '$0.00')
    // }else{
    //   setValue(`members.${i}.cashContributionAmount`, value)
    // }
    
  }

  


  function onclickTrash(pos: number) {
    remove(pos);
    memberNames.splice(pos, 1);
  }

  useEffect( () => {
    handleAccordianChange(0);
  }, [])
  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <div style={{ marginTop: 40, background:'white', border:'border: 1px solid #D4DDEB',borderRadius:'8px'}}>
            <ItemWrapper>
              <div >
                <WrapperList>
                  <WraBlock onClick={() => handleAccordianChange(i)}>
                    <div style={{ display: 'flex', gap: '1em' }}>
                      <img src="/icons/member.svg" alt="" />
                      <Heading style={{ fontSize: '18px', color: '#444C66' }}>Jonnathan Doe</Heading>
                    </div>
                    <div style={{ display: 'flex', gap: '1em' }}>
                      {/* {<img onClick={() => onclickTrash(i)} style={{ width: '1em', height: '1em' }} src={ThemeUtil.get("/icons/trash.svg")} />} */}
                      <Icon
                        type={openType === i ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge} />
                    </div>
                  </WraBlock>
                  <Collapsible open={openType === i} easing="ease" trigger={''} transitionTime={150}>
                    <CollapsibleWrapper>
                      <Body 
                        size={BodySize.sm}
                      >
                        <BodyWrapper>
                          {
                            <>
                              <NameWrapper>
                                <Input
                                  label={'First name'}
                                  error={errors.members &&
                                    errors.members[i] &&
                                    errors.members[i].firstName &&
                                    errors.members[i].firstName?.message}
                                  disabled
                                  topSpace
                                  fullWidth
                                  formStyle
                                  defaultValue={'Jonnathan'}
                                  {...register(`members.${i}.firstName`, {
                                    pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                                  })}
                                />
                                <Input
                                  label={'Last name'}
                                  error={errors.members &&
                                    errors.members[i] &&
                                    errors.members[i].lastName &&
                                    errors.members[i].lastName?.message}
                                  topSpace
                                  disabled
                                  fullWidth
                                  formStyle
                                  defaultValue={'Doe'}
                                />
                              </NameWrapper>
                              <Input
                                label={'Email'}
                                error={errors.members &&
                                  errors.members[i] &&
                                  errors.members[i].email &&
                                  errors.members[i].email?.message}
                                topSpace
                                fullWidth
                                formStyle
                                defaultValue={'jonnathan@abcInc.co'}
                                {...register(`members.${i}.email`)} />
                              <Input
                                  label={'Address'}
                                  error={errors.members &&
                                    errors.members[i] &&
                                    errors.members[i].address &&
                                    errors.members[i].address?.message}
                                  topSpace
                                  fullWidth
                                  formStyle
                                  defaultValue={'132, My Street,'}
                                  {...register(`members.${i}.address`, {
                                    pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                                  })}
                                />
                                <NameWrapper>
                                <Input
                                  label={'Country'}
                                  error={errors.members &&
                                    errors.members[i] &&
                                    errors.members[i].country &&
                                    errors.members[i].country?.message}
                                  topSpace
                                  fullWidth
                                  defaultValue={'United States'}
                                  formStyle
                                  {...register(`members.${i}.country`, {
                                    pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                                  })}
                                />
                                <Input
                                  label={'State'}
                                  error={errors.members &&
                                    errors.members[i] &&
                                    errors.members[i].state &&
                                    errors.members[i].state?.message}
                                  topSpace
                                  fullWidth
                                  defaultValue={'Delaware'}
                                  formStyle
                                  {...register(`members.${i}.state`, {
                                    pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                                  })}
                                />
                              </NameWrapper>
                              <NameWrapper>
                                <Input
                                  label={'City'}
                                  error={errors.members &&
                                    errors.members[i] &&
                                    errors.members[i].city &&
                                    errors.members[i].city?.message}
                                  topSpace
                                  fullWidth
                                  formStyle
                                  defaultValue={'Walnut'}
                                  {...register(`members.${i}.city`, {
                                    pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                                  })}
                                />
                                <Input
                                  label={'Zip code'}
                                  error={errors.members &&
                                    errors.members[i] &&
                                    errors.members[i].zip &&
                                    errors.members[i].zip?.message}
                                  topSpace
                                  fullWidth
                                  formStyle
                                  defaultValue={'12345'}
                                  {...register(`members.${i}.zip`, {
                                    pattern: { value: /^[\x00-\x7F]+$/, message: "Please include only English characters" }
                                  })}
                                />
                              </NameWrapper>
                              <CheckBoxWrapper>
                                <div style={{ fontSize: '1em', fontWeight: 400, color: 'var(--text-primary)', marginBottom: '0.75em' }}>
                                  {'Type of initial contribution of Member'}
                                </div>
                                <CheckboxSpan>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox onClick={() => onClickCashContribution(!cashContribution, i)} active={cashContribution} />
                                    <CheckboxLabel dark>{'Cash contribution'}</CheckboxLabel>
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox  active={nonCashContribution} onClick={() => onClickNonCashContribution(!nonCashContribution, i)} />
                                    <CheckboxLabel dark>{'Non-cash contribution'}</CheckboxLabel>
                                  </div>
                                </CheckboxSpan>
                              </CheckBoxWrapper>
                              {cashContribution && 
                              <Input
                                label={'How much cash will the Member initially contribute to the LLC?'}
                                topSpace
                                placeholder='$0.00'
                                error={errors.members &&
                                  errors.members[i] &&
                                  errors.members[i].cashContributionAmount &&
                                  errors.members[i].cashContributionAmount?.message}
                                fullWidth
                                defaultValue={'$500,000,000.00'}
                                formStyle
                                {...register(`members.${i}.cashContributionAmount`,)} 
                                />}
                              {nonCashContribution &&
                                <>
                                    <Label style={{ fontSize: '1rem', fontWeight: 'normal', color: 'var(--text-primary)', marginTop: '2em' }}>{'What initial non-cash contributions will '} {'make to the LLC?'}</Label>
                                    <AddItemWrapper>
                                    {fields.map((item, j) => {
                                      return (
                                        <ItemWrapperContribution>
                                          <Input
                                            fullWidth
                                            formStyle
                                            placeholder="Contribution Type"
                                            {...register(`members.${i-1}.contributions.${j}.contributionType`, {  })}
                                          />
                                          <Input
                                            type="text"
                                            fullWidth
                                            formStyle
                                            placeholder="$0.00"
                                            {...register(`members.${i-1}.contributions.${j}.contributionAmount`, )}
                                          />
                                          <ItemMenuButton onClickRemove={() => remove(j)} />
                                        </ItemWrapperContribution>
                                      )
                                    })}
                                    <ItemWrapper>
                                      <TextButton
                                        style={{ fontSize: 14 }}
                                        onClick={() =>
                                          append({
                                            contributionType: "",
                                            amount: ""
                                          })
                                        }
                                      >
                                        + ADD CONTRIBUTION
                                      </TextButton>
                                      <div />
                                      <div />
                                    </ItemWrapper>
                                  </AddItemWrapper>
                                  {/* <ContributionArray nestIndex={i-1} {...{ control, register, errors , setValue,watch}} /> */}
                                </>}
                              <Input
                                type="number"
                                placeholder='0%'
                                label={'Ownership / percentage'}
                                topSpace
                                error={errors.members &&
                                  errors.members[i] &&
                                  errors.members[i].ownershipPercentage &&
                                  errors.members[i].ownershipPercentage?.message}
                                fullWidth
                                formStyle
                                disabled
                                defaultValue={100}
                                {...register(`members.${i}.ownershipPercentage` )} />
                            </>}
                        </BodyWrapper>
                      </Body>
                    </CollapsibleWrapper>
                  </Collapsible>
                </WrapperList>
              </div>
            </ItemWrapper>
      </div>

    </Form>
  );
};


