import React, { useState, useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import styled from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { StartSidebar } from './StartSidebar'
import { StartFormFooter } from './StartFormFooter'
import { StartStepper } from './StartStepper'
import { HelpOverlay } from './HelpOverlay'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.color.background};
`
const Sidebar = styled(StartSidebar)`
  background-color: ${props => props.theme.color.white};
`
const LogoWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 78px;
`
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-left: 106px;
  padding-right: 106px;
`
const ContentInnerWrapper = styled.div`
  max-width: 650px;
  padding-bottom: 50px;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const HelpButtonWrapper = styled.div`
  position: absolute;
  top: 64px;
  right: 64px;
  cursor: pointer;
`
const ContentInner = styled.div`
  padding-top: 124px;
`
const Description = styled(Body)`
  margin-top: 16px;
  margin-bottom: 52px;
`
const BottomCornerImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
`

export interface StartFormProps<FD> {
  onSubmit: (values: FD) => void
  goBack: () => void
}

interface StartFormLayout {
  title: string
  description: string
  contentId?: string
  logoId?: string
  sidebarContentId?: string
  bottomCornerImageId?: string
  sidebar?: JSX.Element
  children: JSX.Element | JSX.Element[]
  onNextClick?: () => void
  onPrevClick?: () => void
  nextButtonText?: string
  sidebarImage?: JSX.Element
  hideBottomCornerImage?: boolean
  footer?: JSX.Element
}

export const StartFormLayout = (props: StartFormLayout) => {
  const [helpOpen, setHelpOpen] = useState(false)
  const helpOverlayRef = useRef<any>()

  useOnClickOutside(helpOverlayRef, () => setHelpOpen(false))

  return (
    <Wrapper>
      {props.sidebar ? (
        props.sidebar
      ) : (
        <Sidebar>
          <LogoWrapper id={props.logoId}>
            <img src="/images/logo-dark.svg" alt="Start Global" />
          </LogoWrapper>
          <div id={props.sidebarContentId}>
            <StartStepper />
          </div>
          {props.sidebarImage ? props.sidebarImage : null}
          {props.hideBottomCornerImage ? null : (
            <BottomCornerImage
              id={props.bottomCornerImageId}
              src="/images/bottom-left-corner.svg"
              alt=""
            />
          )}
        </Sidebar>
      )}
      <ContentWrapper id={props.contentId}>
        <HelpButtonWrapper onClick={() => setHelpOpen(true)}>
          <Body size={BodySize.lg}>
            Having troubles?{' '}
            <span style={{ color: 'var(--primary)', fontWeight: 700 }}>Get help</span>
          </Body>
        </HelpButtonWrapper>
        <ContentInnerWrapper>
          <ContentInner>
            <Heading size={HeadingSize.xl} style={{ lineHeight: '50px' }}>
              {props.title}
            </Heading>
            <Description size={BodySize.lg}>{props.description}</Description>
            {props.children}
            {!!props.footer && props.onNextClick && props.onPrevClick ? (
              <StartFormFooter
                onNextClick={props.onNextClick}
                onPrevClick={props.onPrevClick}
                nextButtonText={props.nextButtonText}
              />
            ) : null}
          </ContentInner>
          {!props.footer && props.onNextClick && props.onPrevClick ? (
            <StartFormFooter
              onNextClick={props.onNextClick}
              onPrevClick={props.onPrevClick}
              nextButtonText={props.nextButtonText}
            />
          ) : null}
          {props.footer ? props.footer : null}
        </ContentInnerWrapper>
      </ContentWrapper>
      <HelpOverlay ref={helpOverlayRef} open={helpOpen} close={() => setHelpOpen(false)} />
    </Wrapper>
  )
}
