import React from 'react'
import styled from '../lib/styled'
import { Heading } from './Typography/Heading'
import { Body } from './Typography/Body'
import { Surface } from './Surface'
import { Button } from './Button'
import MediaQuery from 'react-responsive'

const Wrapper = styled(Surface)`
  position: relative;
  padding: 32px;
  min-height: 256px;
`
const Description = styled(Body)`
  margin-top: 12px;
`
const CornerImage = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
`
const CallImage = styled.img`
  position: absolute;
  right: 66px;
  top: 47px;
`
const ContentWrapper = styled.div`
  height: 100%;
  @media(min-width:728px){
    padding-right: 218px;
  }
  @media(max-width:728px){
    gap:2em;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

export const CallCard = (props: { style: any }) => (
  <Wrapper style={props.style}>
    <CornerImage src="/images/purchase-corner.svg" alt="" />
    <MediaQuery query='(min-width:728px)' >
       <CallImage src="/images/tax-call.svg" alt="" />
    </MediaQuery>
    <ContentWrapper>
      <div>
        <Heading style={{ marginTop: 24 }}>Tax advisory call</Heading>
        <Description>
        Onboarding for new services, and brief calls for questions, reviews, and resolution strategy.
        </Description>
      </div>
      <Button>BOOK CALL</Button>
    </ContentWrapper>
  </Wrapper>
)
