import React, { useState, useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import styled from '../lib/styled'
import { PopupMenu } from './PopupMenu'
import { NotificationMenuItem, NotificationType } from './NotificationMenuItem'

const Wrapper = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.color.white};
  border: 1px solid
    ${props => (props.open ? props.theme.color.primaryBorder : props.theme.color.defaultBorder)};
  border-radius: 2px;
  width: 36px;
  height: 36px;
  position: relative;
  cursor: pointer;
`
const UnreadIndicator = styled.div`
  position: absolute;
  top: 5px;
  right: 9px;
  background-color: ${props => props.theme.color.primary};
  border-radius: 100%;
  width: 4px;
  height: 4px;
`
const MenuWrapper = styled(PopupMenu)`
  position: absolute;
  right: 0px;
  top: 48px;
  padding: 12px;
  z-index: 9;
`

interface NotificationsButtonProps {
  unread: boolean
}

export const NotificationsButton = (props: NotificationsButtonProps) => {
  const [open, setOpen] = useState(false)
  const menuRef = useRef<any>()

  useOnClickOutside(menuRef, () => setOpen(false))

  return (
    <div ref={menuRef} style={{ position: 'relative' }}>
      <Wrapper open={open} onClick={() => setOpen(!open)}>
        {props.unread ? <UnreadIndicator /> : null}
        <img src="/icons/bell.svg" alt="bell" />
      </Wrapper>
      <MenuWrapper open={open}>
        <NotificationMenuItem type={NotificationType.success}>
          Your address has been issued successfully!
        </NotificationMenuItem>
        <NotificationMenuItem type={NotificationType.failure} last>
          The company name you chosen is not available for incorporation.
        </NotificationMenuItem>
      </MenuWrapper>
    </div>
  )
}
