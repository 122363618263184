import React, { useState } from 'react'
import styled from '../lib/styled'
import { Icon, IconType } from './Icon'
import { Heading, HeadingSize } from './Typography/Heading'
import { Checkbox } from '../components/Checkbox'
import { Body, BodySize } from './Typography/Body'
import { Button } from '../components/Button'

const SideCardWrapper = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => `rgba(2,8,18,${props.open ? '0.5' : 0})`};
  z-index: 99;
  display: ${props => (props.open ? 'flex' : 'none')};
`

const SideCard = styled.div<{ open: boolean }>`
  background-color: ${props => props.theme.color.white};
  border-radius: 12px;
  box-shadow: 4px 31px 32px 0 rgb(0 0 0 / 40%);
  width: 396px;
  transition: transform 0.2s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(${props => (props.open ? '-50% , -50%' : '0')});
  padding: 36px 32px 32px 32px;
`
const RemoveIconWrapper = styled.div`
  background-color: #ffeded;
  border-radius: 12px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
`
const TrashIcon = styled(Icon)`
  cursor: pointer;
  height: 18px;
  padding: 5px;
`
const BankDetails = styled.h4`
  color: var(--text-secondary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 12px 0 0 0;
`
const BankAccount = styled.h4`
  color: var(--independence);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 32px 0;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
interface RemoveBankAccountModalProps {
  open: boolean
  title: string
  acNumber: string
  close: () => void
}

export const RemoveBankAccountModal = (props: RemoveBankAccountModalProps) => {
  const [agreeToShare, setAgreeToShare] = useState(false)

  return (
    <SideCardWrapper open={props.open} onClick={props.close}>
      <SideCard open={props.open} onClick={e => e.stopPropagation()}>
        <RemoveIconWrapper>
          <TrashIcon type={IconType.removeIcon} />
        </RemoveIconWrapper>
        <Heading size={HeadingSize.md} color={'var(--independence)'}>
          Remove bank account
        </Heading>
        <BankDetails>Are you sure you want to delete the bank account</BankDetails>
        <BankAccount>
          {props.title}
          <span>{props.acNumber}</span>
        </BankAccount>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
          <Checkbox active={agreeToShare} onClick={() => setAgreeToShare(!agreeToShare)} />
          <Body size={BodySize.sm} style={{ marginLeft: 12 }}>
            Also delete all the transactions with this account
          </Body>
        </div>
        <ButtonWrapper>
          <Button small light style={{ marginRight: 16, textTransform: 'uppercase' }}>
            cancel
          </Button>
          <Button
            className="remove"
            small
            variant={'error'}
            style={{ textTransform: 'uppercase', width: 132 }}
          >
            Remove
          </Button>
        </ButtonWrapper>
      </SideCard>
    </SideCardWrapper>
  )
}
