import React, { useState } from 'react'
import styled from '../lib/styled'
import { Input } from './Input'
import { Icon, IconType } from './Icon'

const CCInputWrapper = styled.div`
  border: 1px solid;
  border-radius: 6px;
  border-color: ${props => props.theme.color.defaultBorder};
  background-color: ${props => props.theme.color.backgroundDark};
  display: inline-flex;
  width: 100%;
`
const CCInputField = styled(Input)<{ width: string }>`
  width: ${props => props.width};
  background-color: transparent;
  border: none;
  box-shadow: none;
  box-sizing: content-box;
`
const CCIcon = styled(Icon)`
  position: relative;
  left: 12px;
  margin-right: 12px;
`
const CCWrapper = styled.div`
  margin-right: auto;
`

interface CreditCardInputProps {
  onSubmit: (cc: { number: string; expiration: string; cvv: string }) => void
}

export const CreditCardInput = (props: CreditCardInputProps) => {
  const [ccNumber, setCCNumber] = useState('')
  const [ccExpiration, setCCExpiration] = useState('')
  const [cvv, setCVV] = useState('')
  const handleCCNumber = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCCNumber(
      e.target.value
        .replace(/[^\d0-9]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .substring(0, 19)
        .trim()
    )
  const handleCCExpiration = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCCExpiration(
      e.target.value
        .replace(/[^\d0-9]/g, '')
        .replace(/(.{2})/g, '$1 / ')
        .substring(0, 7)
        .trim()
    )
  const handleCCCVV = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCVV(
      e.target.value
        .replace(/[^\d0-9]/g, '')
        .substring(0, 3)
        .trim()
    )
  return (
    <CCInputWrapper>
      <CCIcon type={IconType.creditCard} />
      <CCWrapper>
        <CCInputField
          value={ccNumber}
          onChange={handleCCNumber}
          width="175px"
          placeholder="Card number"
          inputMode="numeric"
          pattern="[0-9\s]{13,19}"
          autoComplete="cc-number"
          maxLength={19}
        />
      </CCWrapper>
      <CCInputField
        value={ccExpiration}
        onChange={handleCCExpiration}
        width="75px"
        inputMode="numeric"
        placeholder="MM / YY"
        maxLength={7}
      />
      <CCInputField
        value={cvv}
        onChange={handleCCCVV}
        width="40px"
        inputMode="numeric"
        placeholder="CVV"
        maxLength={3}
      />
    </CCInputWrapper>
  )
}
