import React, { useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside';
import styled, { css } from '../lib/styled'
import { Sidebar } from "./Sidebar";


/**
 * New component for mobile devices.
 */
const OverlayWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position:absolute;
    overflow:hidden;
    z-index: 999;
    top:0;
    left:0;
`;
const Wrapper = styled.div<{ open: boolean }>`
  min-height: 100%;
  position :fixed;
  min-width:60vw;
  background-color: var(--primary); 
`;

const StyledBurger = styled.button<{ open: boolean }>`
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    ${({ theme: {  }, open }) =>
    open
        ? css`
      left: calc(292px - 3rem);
      z-index: 9999;
    `
        : ''}
    &:focus {
        outline: none;
    }

    div {
        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        transition: all 0.3s linear;
        background: ${({ theme, open }) => open ? theme.color.text.disabled : theme.color.primaryDark};
        position: relative;
        transform-origin: 1px;
        z-index: 9999;
        :first-child {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`;

export   const BurgerMenu = () => {
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef<any>()

    useOnClickOutside(wrapperRef, () => setOpen(false))
    return (
        <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
            </StyledBurger>
            {open && <OverlayWrapper>
                <Wrapper open={open} ref={wrapperRef}>
                    <Sidebar />
                </Wrapper>
            </OverlayWrapper>}
        </>
    )
}