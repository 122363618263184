import styled from '../lib/styled'
import { Body } from '../components/Typography/Body'

export const TextButton = styled(Body)`
  color: ${props => props.theme.color.primary};
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 17px;
  cursor: pointer;
  user-select: none;
`
