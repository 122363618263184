import React from 'react'
import styled, { css } from '../lib/styled'
import { HeadingSize } from './Typography/Heading'
import { Body } from './Typography/Body'
import { TransactionsAmount } from './TransactionsAmount'
import { TransactionsBalance } from './TransactionsBalance'

const ROW_COLS = '3fr 2fr 2fr 2fr 2fr'
const TransactionsTableWrapper = styled.div`
  width: 100%;
`
const TableRow = styled.div`
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.default};
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.white};
  display: grid;
  padding: 16px 0px;
  grid-template-columns: ${ROW_COLS};
  margin-bottom: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  .copy-link-button {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    right: -42px;
    top: 8px;
    padding: 12px;
  }

  :hover {
    border: 1px solid ${props => props.theme.color.defaultBorder};
    box-shadow: ${props => props.theme.shadow.elevated};
  }
`
const TableCell = styled.div<{ textBold?: boolean; flexEnd?: boolean }>`
  border-color: #fff;
  color: ${props => props.theme.color.independence};
  padding: 0px 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${props =>
    props.textBold &&
    css`
      background: var(--white-color);
      color: var(--gray-color);
      border-color: var(--gray-border-color);
    `}
  ${props =>
    props.flexEnd &&
    css`
      justify-content: flex-end;
    `}
`
const TableCellBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const TableCellBox = styled.div``
const TableCellBoxTwo = styled.div`
  font-size: 14px;
  color: ${props => props.theme.color.text.secondary};
  margin-top: 8px;
`
const TableHeadWrapper = styled.div`
  display: grid;
  grid-template-columns: ${ROW_COLS};
  margin-bottom: 12px;
`
const TableHead = (props: { children: string; style?: any; textAlign?: boolean }) => (
  <Body
    style={{ fontWeight: 'bold', padding: '0px 20px', ...props.style }}
    textAlign={props.textAlign}
  >
    {props.children}
  </Body>
)
const EmtyNotes = styled.span`
  color: ${props => props.theme.color.text.secondary};
  font-size: 16px;
  font-weight: 700;
`
const Notes = styled.div`
  font-size: 16px;
  font-weight: 700;
`
interface TransactionsTableProps {
  textBold?: boolean
  flexEnd?: boolean
  items: Array<{
    transactionsName: string
    acDetails: string
    issuedOn: string
    amount: { price: number }
    balance: Array<{ price: number }>
    notes: string
  }>
}

export const TransactionsTable = (props: TransactionsTableProps) => {
  return (
    <TransactionsTableWrapper>
      <TableHeadWrapper>
        <TableHead>Description</TableHead>
        <TableHead>Date</TableHead>
        <TableHead textAlign>Amount</TableHead>
        <TableHead textAlign>Balance</TableHead>
        <TableHead textAlign>Notes</TableHead>
      </TableHeadWrapper>
      {props?.items?.map(item => {
        return (
          <TableRow>
            <TableCell textBold>
              <TableCellBoxWrapper>
                <TableCellBox>{item.transactionsName}</TableCellBox>
                <TableCellBoxTwo>{item.acDetails}</TableCellBoxTwo>
              </TableCellBoxWrapper>
            </TableCell>
            <TableCell textBold>{item?.issuedOn}</TableCell>
            <TableCell textBold flexEnd>
              <TransactionsAmount
                size={HeadingSize.md}
                style={{
                  color: Math.sign(item?.amount.price) === 1 ? 'var(--secondary)' : 'var(--error)',
                }}
              >
                {(Math.sign(item?.amount.price) === 1 ? '+' : '-') +
                  '$' +
                  Number(Math.abs(item?.amount.price)).toFixed(2)}
              </TransactionsAmount>
            </TableCell>
            <TableCell flexEnd>
              <TransactionsBalance size={HeadingSize.md}>
                {`$${item?.balance.reduce((total, i) => (total += i.price || 0), 0).toFixed(2)}`}
              </TransactionsBalance>
            </TableCell>
            <TableCell flexEnd>
              {item?.notes ? (
                <Notes>{item.notes}</Notes>
              ) : (
                <div>
                  <EmtyNotes>Add Notes</EmtyNotes>
                </div>
              )}
            </TableCell>
          </TableRow>
        )
      })}
    </TransactionsTableWrapper>
  )
}
