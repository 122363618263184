import React from 'react'
import styled from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body } from './Typography/Body'
import { FeatureItem, ItemDetail } from './FeatureItem'

const DetailsWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.backgroundDark};
  border-radius: 0 0 16px 16px;
  margin-top: 32px;
  padding-top: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  box-sizing: border-box;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
`
const Dollar = styled.img`
  position: absolute;
  top: 5px;
  left: -10px;
`

export const ProductDetails = () => (
  <DetailsWrapper>
    <Column style={{ position: 'relative', bottom: 14 }}>
      <Heading size={HeadingSize.xxl} style={{ position: 'relative', marginBottom: 8 }}>
        <Dollar src="/icons/dollar.svg" alt="dollars" />
        499
      </Heading>
      <Body bold style={{ letterSpacing: '1.5px', marginBottom: 24 }}>
        ALL INCLUDED.
      </Body>
      <ItemDetail style={{ maxWidth: 188, lineHeight: '24px' }}>
        We are a complete solution to kickstart your business in the US.
      </ItemDetail>
    </Column>
    <Column>
      <FeatureItem heading="Incorporation">
        <ItemDetail>Name check and clearance</ItemDetail>
        <ItemDetail>Formation</ItemDetail>
        <ItemDetail>Tax ID and EIN</ItemDetail>
        <ItemDetail>Same day e-filing</ItemDetail>
      </FeatureItem>
      <FeatureItem heading="Communication">
        <ItemDetail>US address</ItemDetail>
        <ItemDetail>Mail forwarding</ItemDetail>
        <ItemDetail>Mail scanning</ItemDetail>
        <ItemDetail>US Phone number</ItemDetail>
      </FeatureItem>
    </Column>
    <Column>
      <FeatureItem heading="Banking">
        <ItemDetail>US Bank account</ItemDetail>
        <ItemDetail>Debit card</ItemDetail>
        <ItemDetail>No in person visit</ItemDetail>
        <ItemDetail>Operate fully online</ItemDetail>
      </FeatureItem>
      <FeatureItem heading="Offers">
        <ItemDetail>Legal counsel</ItemDetail>
        <ItemDetail>Business card printing</ItemDetail>
        <ItemDetail>CPA Consulting</ItemDetail>
        <ItemDetail>Coworking spaces</ItemDetail>
      </FeatureItem>
    </Column>
  </DetailsWrapper>
)
