import React, { useState, useMemo, useContext, createContext } from "react";
import { StartFormProvider, useStartForm } from '../components/OperatingAgreement/StartFormProvider'
import { AboutCompany } from '../components/OperatingAgreement/StartPages/AboutCompany'
import {  Agreement } from '../components/OperatingAgreement/StartPages/Agreement'
import { AboutCompanyMoreDetails } from "../components/OperatingAgreement/StartPages/AboutCompanyMoreDetails";
import { Members } from "../components/OperatingAgreement/StartPages/Members";
import { MemberSettings } from "../components/OperatingAgreement/StartPages/MemberSettings";
import { DecisionMaking } from "../components/OperatingAgreement/StartPages/DecisionMaking";
import { Changes } from "../components/OperatingAgreement/StartPages/Changes";


const StartStepContext = createContext({
  currentPage: 1,
  lastPage: 0
})



export const useStartStep = () => useContext(StartStepContext)

export const StartOA = () => {
  const startForm = useStartForm();
  const [step, setStep] = useState({
    currentPage: 1,
    lastPage: 0
  })
  const onSubmit = (values:any) => {
    let nextPage = Math.trunc(step.currentPage + 1);
    return setStep({
      currentPage: nextPage,
      lastPage: step.currentPage,
    }); 
  }
  const goBack = () => { 
    let nextPage = Math.trunc(step.currentPage - 1);
    return setStep({
      currentPage: nextPage,
      lastPage: step.currentPage,
    }); 
  }

  const page = useMemo(() => {
    switch(step.currentPage) {
      case 1:
        return <AboutCompany onSubmit={onSubmit} goBack={goBack} />
      case 2:
        return <AboutCompanyMoreDetails onSubmit={onSubmit} goBack={goBack} />
      case 3:
        return <Members onSubmit={onSubmit} goBack={goBack} />
      case 4:
        return <MemberSettings onSubmit={onSubmit} goBack={goBack} />
      case 5:
        return <DecisionMaking onSubmit={onSubmit} goBack={goBack} />
      case 6:
        return <Changes onSubmit={onSubmit} goBack={goBack} />
      case 7:
       return <Agreement onSubmit={onSubmit} goBack={goBack}/>
      default:
        return <AboutCompany onSubmit={onSubmit} goBack={goBack} />
    }
  }, [step])

  return (
    <StartFormProvider>
      <StartStepContext.Provider value={step}>
        {page}
      </StartStepContext.Provider>
    </StartFormProvider>
  )
}