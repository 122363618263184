import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import * as CRS from 'react-country-region-selector'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Body, BodySize } from '../Typography/Body'
import { Form } from '../Form'
import { Input } from '../Input'
import { Select } from '../Select'
import { InputSelect } from '../InputSelect'
import { InfoBox } from '../InfoBox'
import { DatePicker } from '../DatePicker'

const countryRegionData = (CRS as any).CountryRegionData as Array<string[]>
const countryDataOptions = countryRegionData.map(data => ({ label: data[0], value: data[1] }))
const countryRegionDataMap = countryRegionData.reduce((obj, data) => {
  obj[data[1]] = data[2].split('|').map(region => {
    const regionData = region.split('~')
    return {
      label: regionData[0],
      value: regionData[1],
    }
  })
  return obj
}, {} as any)

export type FormData = {
  firstName: string
  lastName: string
  email: string
  country: string
  state: string
  address: string
  city: string
  code: string
  shares: number
  shareAmountType: 'percentage' | 'shares'
  cliff: '1y'
  vesting: '1/48'
  vestingStart: Date
  accelaration: 'double trigger'
}

export const ShareholderForm = (props: FormProps<FormData>) => {
  const { register, errors, watch, control, setValue, onSubmit } = useGetFormHook(props)
  const [regionOptions, setRegionOptions] = useState([])

  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps} fullWidthButton>
      <Body bold dark size={BodySize.sm} style={{ marginTop: 32, marginBottom: 24 }}>
        BASIC DETAILS
      </Body>
      <div style={{ display: 'flex', width: '100%' }}>
        <Input
          name="firstName"
          label="First name"
          error={errors.firstName && errors.firstName.message}
          rightSpace
          bottomSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
        <Input
          name="lastName"
          label="Last name"
          error={errors.lastName && errors.lastName.message}
          leftSpace
          bottomSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
      </div>
      <Input
        name="email"
        label="Email address"
        error={errors.email && errors.email.message}
        bottomSpace
        fullWidth
        formStyle
        ref={register({ required: 'Required' })}
      />
      <div style={{ display: 'flex', width: '100%' }}>
        <Input
          name="address"
          label="Street Address"
          error={errors.address && errors.address.message}
          rightSpace
          bottomSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
        <InputSelect
          name="state"
          label="State"
          placeholder="State"
          value={watch('state')}
          error={errors.state && errors.state.message}
          leftSpace
          bottomSpace
          fullWidth
          formStyle
          onSelectOption={({ label }) => setValue('state', label)}
          disabled={!regionOptions.length}
          options={regionOptions}
          ref={register({ required: 'Required' })}
        />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <InputSelect
          name="country"
          label="Country"
          placeholder="Country"
          value={watch('country')}
          error={errors.country && errors.country.message}
          rightSpace
          fullWidth
          formStyle
          onSelectOption={({ label, value }) => {
            setValue('country', label)
            setRegionOptions(countryRegionDataMap[value])
          }}
          options={countryDataOptions}
          ref={register({ required: 'Required' })}
        />
        <Input
          name="code"
          label="Zip Code"
          error={errors.code && errors.code.message}
          fullWidth
          leftSpace
          formStyle
          ref={register({ required: 'Required' })}
        />
      </div>
      <Body bold dark size={BodySize.sm} style={{ marginTop: 40 }}>
        BUSINESS DETAILS
      </Body>
      <Body size={BodySize.sm} style={{ marginTop: 8, marginBottom: 24 }}>
        These are generally accepted terms by major Silicon Valley VCs
      </Body>
      <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
        <Input
          name="shares"
          label="Shares"
          error={errors.shares && errors.shares.message}
          rightSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
        <Select
          name="shareAmountType"
          error={errors.shareAmountType && errors.shareAmountType.message}
          leftSpace
          formStyle
          width="220px"
          wrapperStyle={{ marginTop: 31 }}
          options={[
            { label: 'Percentage', value: 'percentage' },
            { label: 'Shares', value: 'shares' },
          ]}
          ref={register({ required: 'Required' })}
        />
      </div>
      <InfoBox>
        These are the standard values for number of shares and per value in many top tier companies
        choose to get started.
      </InfoBox>
      <div style={{ display: 'flex', width: '100%' }}>
        <Select
          name="cliff"
          label="Cliff period"
          error={errors.cliff && errors.cliff.message}
          rightSpace
          topSpace
          bottomSpace
          fullWidth
          formStyle
          options={[{ label: '1 year', value: '1y' }]}
          ref={register({ required: 'Required' })}
        />
        <Select
          name="vesting"
          label="Vesting period"
          error={errors.vesting && errors.vesting.message}
          leftSpace
          topSpace
          bottomSpace
          formStyle
          fullWidth
          options={[{ label: '1/48th per month', value: '1/48' }]}
          ref={register({ required: 'Required' })}
        />
      </div>
      <Controller
        name="vestingStart"
        control={control}
        as={
          <DatePicker
            label="Vesting period start"
            error={errors.vestingStart && errors.vestingStart.message}
            bottomSpace
            formStyle
            fullWidth
          />
        }
      />
      <Select
        name="accelaration"
        label="Accelaration"
        error={errors.accelaration && errors.accelaration.message}
        formStyle
        fullWidth
        options={[{ label: 'Double trigger', value: 'double trigger' }]}
        ref={register({ required: 'Required' })}
      />
    </Form>
  )
}
