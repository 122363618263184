import React, { useEffect } from 'react'
import anime from 'animejs'
import styled from '../../lib/styled'
import { CardButton, CardButtonType } from '../CardButton'
import { StartFormLayout } from '../StartFormLayout'
import { useStartForm, StateType } from '../StartFormProvider'

const logoId = 'choose-company-state-logo'
const sidebarContentId = 'choose-company-state-sidebar-content'
const contentId = 'choose-company-state-content'
const cornerImageId = 'choose-company-state-corner-image'
const cornerImageBottomId = 'choose-company-state-corner-image-bottom'
const cornerImageRightId = 'choose-company-state-corner-image-right'
const cornerImagePinId = 'choose-company-state-corner-image-pin'

const PinImage = styled.img`
  position: absolute;
  bottom: 46px;
  left: 3px;

  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
`

export const ChooseCompanyState = (props: { onSubmit: (state: StateType) => void }) => {
  const startForm = useStartForm()
  const onSubmit = (type: StateType) => {
    startForm.setStateType(type)
    anime({
      targets: `#${contentId}, #${cornerImageId}`,
      opacity: [1, 0],
      duration: 500,
      easing: 'easeOutQuart',
    })
    setTimeout(() => props.onSubmit(type), 700)
  }

  useEffect(() => {
    anime
      .timeline()
      .add(
        {
          targets: `#${logoId}, #${sidebarContentId}`,
          opacity: [0, 1],
          easing: 'easeOutQuart',
          delay: anime.stagger(150),
          duration: 300,
        },
        300
      )
      .add(
        {
          targets: `#${contentId}`,
          translateY: [50, 0],
          opacity: [0, 1],
          duration: 800,
          easing: 'easeOutQuart',
        },
        600
      )
      .add(
        {
          targets: `#${cornerImageId}`,
          opacity: [0, 1],
          duration: 800,
          easing: 'easeOutQuart',
        },
        600
      )
      .add(
        {
          targets: `#${cornerImageRightId}`,
          translateX: ['40px', '0px'],
          duration: 800,
          easing: 'easeOutQuart',
        },
        600
      )
      .add(
        {
          targets: `#${cornerImageBottomId}`,
          translateY: ['40px', '0px'],
          duration: 800,
          easing: 'easeOutQuart',
        },
        600
      )
      .add(
        {
          targets: `#${cornerImagePinId}`,
          scale: [0, 1],
          duration: 500,
          easing: 'easeOutQuart',
        },
        '-=100'
      )
    return () => {}
  }, [])

  return (
    <StartFormLayout
      title="Choose the state"
      description="Select the state in which you want to incorporate your new company."
      logoId={logoId}
      sidebarContentId={sidebarContentId}
      contentId={contentId}
      sidebarImage={
        <div
          id={cornerImageId}
          style={{ position: 'absolute', left: 70, bottom: 100, zIndex: 1, opacity: 0 }}
        >
          <PinImage id={cornerImagePinId} src="/images/choose-state-corner/pin.svg" alt="" />
          <img src="/images/choose-state-corner/top.svg" alt="" />
          <img
            id={cornerImageRightId}
            src="/images/choose-state-corner/right.svg"
            alt=""
            style={{ position: 'absolute', right: -23, bottom: 2 }}
          />
          <img
            id={cornerImageBottomId}
            src="/images/choose-state-corner/bottom.svg"
            alt=""
            style={{ position: 'absolute', bottom: -7, left: 9 }}
          />
        </div>
      }
    >
      <div style={{ display: 'flex' }}>
        <CardButton
          type={CardButtonType.delaware}
          vertical
          style={{ marginRight: 16 }}
          onClick={() => onSubmit(CardButtonType.delaware as StateType)}
        />
        <CardButton
          type={CardButtonType.wyoming}
          vertical
          style={{ marginLeft: 16 }}
          onClick={() => onSubmit(CardButtonType.wyoming as StateType)}
        />
      </div>
    </StartFormLayout>
  )
}
