import React from 'react'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Form } from '../Form'
import { Input } from '../Input'
import { Select } from '../Select'
import { InfoBox } from '../InfoBox'
import { Icon, IconType } from '../Icon'

export type FormData = {
  type: string
  shareAmount: number
  shareValue: number
}

export const BusinessDetailsForm = (props: FormProps<FormData>) => {
  const { register, errors, onSubmit } = useGetFormHook(props)

  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <Select
        name="type"
        label="Type of corporation"
        info="..."
        error={errors.type && errors.type.message}
        fullWidth
        formStyle
        options={[
          { label: 'S - Corporation', value: 'S' },
          { label: 'C - Corporation', value: 'C' },
        ]}
        ref={register({ required: 'Required' })}
      />
      <InfoBox>Only for us citizen, SSN mandatory for S-Corporations</InfoBox>
      <div style={{ display: 'flex', marginTop: 26, width: '100%' }}>
        <Input
          name="shareAmount"
          label="Number of shares"
          type="number"
          error={errors.shareAmount && errors.shareAmount.message}
          rightSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
        <Input
          name="shareValue"
          label="Per value"
          type="number"
          error={errors.shareValue && errors.shareValue.message}
          leftSpace
          fullWidth
          formStyle
          addonStart={{ content: <Icon type={IconType.dollar} /> }}
          ref={register({ required: 'Required' })}
        />
      </div>
      <InfoBox>
        These are the standard values for number of shares and per value in many top tier companies
        choose to get started.
      </InfoBox>
    </Form>
  )
}
