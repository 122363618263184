import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { Icon, IconType } from '../components/Icon'
import { TextButton } from '../components/TextButton'
import { Body, BodySize } from '../components/Typography/Body'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import Collapsible from 'react-collapsible'
import styled from '../lib/styled'
import { Button } from '../components/Button'
const ProgressWrapper = styled.div`
  width: 100%;
  max-width: 385px;
  padding-left: 20px;
  position: relative;
  z-index: 0;
`
const ProgressBlcokWrap = styled.div`
  display: flex;
`
const ProgressList = styled.div`
  width: 100%;
`
const ProgressItem = styled.div<{ status?: string }>`
  display: flex;
  align-items: baseline;
  z-index: 2;
  position: relative;
  ::before {
    position: absolute;
    content: '';
    border: 1px solid ${props => props.theme.color.defaultBorder};
    height: 100%;
    top: 3px;
    left: 16px;
    z-index: 1;
  }

  ${props =>
    props.status === 'success'
      ? `
  ::before {
    position: absolute;
    content: '';
    border: 1px solid ${props.theme.color.secondary};
    height: 100%;
    top: 3px;
    left: 16px;
    z-index: 1;
  }`
      : ''}
`
const Lastfour = styled.div`
  display: flex;
  align-items: baseline;
`
const UlProgress = styled.div`
  background: ${props => props.theme.color.background};
  border-radius: 100%;
  padding: 5px;
  margin-left: -5px;
  position: relative;
  z-index: 2;
`
const LiProgress = styled.div<{ status?: string }>`
  border: 1px solid ${props => props.theme.color.defaultBorder};
  background-color: ${props => props.theme.color.background};
  border-radius: 100%;
  color: ${props => props.theme.color.text.secondary};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;

  ${props =>
    props.status === 'success'
      ? `
  border: 1px solid ${props.theme.color.secondary};
  background-color: ${props.theme.color.secondary};
  background-image:url(/icons/right-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  color: transparent;
  `
      : ''}

  ${props =>
    props.status === 'progress'
      ? `
  border: 2px dotted ${props.theme.color.primary};
  background-color: ${props.theme.color.background};
  border-radius: 100%;
  color: ${props.theme.color.text.primary};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
`
      : ''}
`
const SideInfoBox = styled.div`
  background-color: ${props => props.theme.color.primaryLight};
  border: 1px solid ${props => props.theme.color.primaryBorder};
  border-radius: 8px;
  padding: 12px 16px;
`
const DropZoneFile = styled.div`
  background-color: ${props => props.theme.color.white};
  border-radius: 8px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04);
  padding: 8px 16px 8px 8px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const DropFile = styled.div`
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 8px;
  width: 34px;
  height: 34px;
  margin-right: 12px;
`
const ConsultantImages = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`
const WrapperList = styled.div`
  border-radius: 12px;
  margin-bottom: 16px;
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.default};
  padding: 17px 16px;
  width: 100%;
  margin-left: 10px;
  button {
    div {
      font-size: 12px;
    }
  }
`
const WraBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
const CardWrapper = styled.div`
  border: 0.7px solid ${props => props.theme.color.defaultBorder};
  border-radius: 4px;
  padding: 11px 14px 9px 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CardBlockLeft = styled.div`
  display: flex;
  align-items: center;
  input {
    border: 0px;
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
const CardBlockRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: -18px;
  input {
    border: 0px;
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
const ButtonItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  button {
    margin-bottom: 10px;
    div {
      font-size: 12px;
    }
  }
`
const MoneyAmountText = styled.h2`
  span {
    color: ${props => props.theme.color.text.primary};
    opacity: 0.7;
  }
  color: #444c66;
  font-size: 24px;
  letter-spacing: 0.95px;
  line-height: 29px;
  margin: 0;
`
const DropBox = styled.div`
  display: inline-block;
  cursor: pointer;
`

export const TaxProgressBar = () => {
  const onDrop = async (isDragActive: File[]) => {}
  const [openType, setOpen] = useState(0)
  const handleAccordianChange = (type: number) => {
    console.log('type', type)
    if (type === openType) {
      setOpen(0)
    } else {
      setOpen(type)
    }
  }
  return (
    <ProgressWrapper>
      <Heading size={HeadingSize.sm} style={{ color: '#444C66', marginBottom: 20 }}>
        Progress
      </Heading>
      <ProgressBlcokWrap>
        <ProgressList>
          <ProgressItem status="success">
            <UlProgress>
              <LiProgress status="success">1</LiProgress>
            </UlProgress>
            <WrapperList>
              <WraBlock onClick={() => handleAccordianChange(1)}>
                <Heading
                  size={HeadingSize.sx}
                  style={{ textTransform: 'uppercase', color: '#444C66' }}
                >
                  {' '}
                  Upload statements{' '}
                </Heading>
                <Icon
                  type={openType === 1 ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge}
                />
              </WraBlock>
              <Collapsible open={openType === 1} easing="ease" trigger={''} transitionTime={150}>
                <div>
                  <Body
                    size={BodySize.xs}
                    style={{ marginTop: 8, lineHeight: '20px', marginBottom: 16 }}
                  >
                    Upload all your bank statement with transactions done throughout the financial
                    year 2020-21 to estimate the amount for tax filing.
                  </Body>
                  <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button style={{ textTransform: 'uppercase', fontSize: '12px' }}>
                          {' '}
                          Upload{' '}
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </Collapsible>
            </WrapperList>
          </ProgressItem>
          <ProgressItem>
            <UlProgress>
              <LiProgress>2</LiProgress>
            </UlProgress>
            <WrapperList>
              <WraBlock onClick={() => handleAccordianChange(2)}>
                <Heading
                  size={HeadingSize.sx}
                  style={{ textTransform: 'uppercase', color: '#444C66' }}
                >
                  Get estimate
                </Heading>
                <Icon
                  type={openType === 2 ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge}
                />
              </WraBlock>
              <Collapsible open={openType === 2} easing="ease" trigger={''} transitionTime={150}>
                <div>
                  <Body
                    size={BodySize.xs}
                    style={{ marginTop: 12, lineHeight: '20px', marginBottom: 12 }}
                  >
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore. Lorem ipsum dolor sit, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore et dolore, consectetur
                    adipiscing elit, sed do eiusmod.
                  </Body>
                  <Body
                    size={BodySize.xs}
                    style={{ marginTop: 12, lineHeight: '20px', marginBottom: 12 }}
                  >
                    The estimated tax amount is,
                  </Body>
                  <div style={{ marginBottom: 15 }}>
                    <MoneyAmountText>
                      <span>$</span>869
                    </MoneyAmountText>
                  </div>
                  <ButtonItems>
                    <Button style={{ textTransform: 'uppercase', marginRight: '13px' }}>
                      Accept & sign
                    </Button>
                    <Button light style={{ textTransform: 'uppercase' }}>
                      Browse cpa
                    </Button>
                  </ButtonItems>
                </div>
              </Collapsible>
            </WrapperList>
          </ProgressItem>
          <ProgressItem>
            <UlProgress>
              <LiProgress status="progress">3</LiProgress>
            </UlProgress>
            <WrapperList>
              <WraBlock onClick={() => handleAccordianChange(3)}>
                <Heading
                  size={HeadingSize.sx}
                  style={{ textTransform: 'uppercase', color: '#444C66' }}
                >
                  Payment
                </Heading>
                <Icon
                  type={openType === 3 ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge}
                />
              </WraBlock>
              <Collapsible open={openType === 3} easing="ease" trigger={''} transitionTime={150}>
                <div>
                  <Body
                    size={BodySize.xs}
                    style={{ marginTop: 8, lineHeight: '20px', marginBottom: 12 }}
                  >
                    You need to pay,
                  </Body>
                  <div style={{ marginBottom: 15 }}>
                    <MoneyAmountText>
                      <span>$</span>869
                    </MoneyAmountText>
                  </div>
                  <CardWrapper>
                    <CardBlockLeft>
                      <Icon type={IconType.creditCard} />
                      <input
                        type="number"
                        style={{ marginLeft: 10, color: '#444C66' }}
                        placeholder="Card number"
                      ></input>
                    </CardBlockLeft>
                    <CardBlockRight>
                      <input
                        type="number"
                        style={{ color: '#444C66', width: '52px', marginRight: '2px' }}
                        placeholder="MM / YY"
                      ></input>
                      <input
                        type="number"
                        style={{ color: '#444C66', width: '32px' }}
                        placeholder="CVC"
                      ></input>
                    </CardBlockRight>
                  </CardWrapper>
                  <Button style={{ textTransform: 'uppercase', fontSize: '12px' }}>
                    Make payment
                  </Button>
                </div>
              </Collapsible>
            </WrapperList>
          </ProgressItem>
          <Lastfour>
            <UlProgress>
              <LiProgress>4</LiProgress>
            </UlProgress>
            <WrapperList>
              <WraBlock onClick={() => handleAccordianChange(4)}>
                <Heading
                  size={HeadingSize.sx}
                  style={{ textTransform: 'uppercase', color: '#444C66' }}
                >
                  Tax filing
                </Heading>
                <Icon
                  type={openType === 4 ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge}
                />
              </WraBlock>
              <Collapsible open={openType === 4} easing="ease" trigger={''} transitionTime={150}>
                <div>
                  <Body
                    size={BodySize.xs}
                    style={{ marginTop: 16, lineHeight: '20px', marginBottom: 16 }}
                  >
                    Estimated time remaining,
                  </Body>
                  <Heading style={{ marginBottom: '16px', fontSize: '24px' }}>
                    8 <span style={{ color: '#757A89' }}>days</span>
                  </Heading>
                  <Body
                    size={BodySize.xs}
                    style={{ marginTop: 16, lineHeight: '20px', marginBottom: 16 }}
                  >
                    You’ll be able to download all the files after the CPA file taxes and done with
                    the process.
                  </Body>
                  <Body
                    size={BodySize.xs}
                    style={{ marginTop: 16, lineHeight: '20px', marginBottom: 16 }}
                  >
                    Your tax has been successfully filed.
                  </Body>
                  <Button style={{ textTransform: 'uppercase', fontSize: '12px' }}>
                    Download files
                  </Button>
                </div>
              </Collapsible>
            </WrapperList>
          </Lastfour>
        </ProgressList>
      </ProgressBlcokWrap>
      <div>
       {/* <Heading size={HeadingSize.sm} style={{ color: '#444C66', marginBottom: 20 }}>
          Documents
                    </Heading>*/}
                    <div>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <SideInfoBox>
                  <Heading size={HeadingSize.sm} style={{ color: '#444C66', marginBottom: 6 }}>
                    Documents
                  </Heading>
                  <Body size={BodySize.sm} style={{ color: '#52649F', lineHeight: '20px' }}>
                  Upload all your bank statement with transactions done throughout the financial year 2020-21 to estimate the amount for tax filing.
                  </Body>
                  <TextButton style={{ fontSize: 13, marginTop: 16, textTransform: 'uppercase' }}>
                    upload
                  </TextButton>
                </SideInfoBox>
              </div>
            )}
          </Dropzone>
          {/*<DropZoneFile>
            <DropFile>
              <ConsultantImages src="/images/adrian-media-min.jpg" alt="profile" />
            </DropFile>
            <Heading size={HeadingSize.sx} style={{ color: '#444C66' }}>
              Bank Statements.png
            </Heading>
            <Icon type={IconType.trash} style={{ marginLeft: 'auto', cursor: 'pointer' }} />
          </DropZoneFile>
          <DropBox>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Heading
                    size={HeadingSize.sx}
                    style={{ color: '#357d45', textTransform: 'uppercase' }}
                  >
                    upload file
                  </Heading>
                </div>
              )}
            </Dropzone>
              </DropBox>*/}
        </div>
      </div>
    </ProgressWrapper>
  )
}
