import React, { useState } from 'react'
import styled from '../lib/styled'
import { PageLayout } from '../components/PageLayout'
import { CollapsableCard } from '../components/CollapsableCard'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { Icon, IconType } from '../components/Icon'
import { ShareholderForm } from '../components/Forms/ShareholderForm'
import { LawyerCard } from '../components/LawyerCard'
import { Button } from '../components/Button'
import { Checkbox } from '../components/Checkbox'

const AddButton = styled.div`
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadow.default};
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 41px;

  color: ${props => props.theme.color.primary};
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`

const SideInfoBox = styled.div`
  background-color: ${props => props.theme.color.primaryLight};
  border: 1px solid ${props => props.theme.color.primaryBorder};
  border-radius: 12px;
  min-width: 307px;
  padding: 20px;
  height: fit-content;
  @media(min-width:728px){
    margin-left: 32px;
  }
`
const ShareHoldersWrapper = styled.div`
    display: flex;
    @media(max-width:728px){
      gap: 2em;
    flex-direction: column;
    }    
`
const shareholders = {
  '0': { name: 'Sanjay Nediyara', email: 'sanjay@startglobal.com' },
  '1': { name: 'Sandeep', email: 'sandeep@startglobal.com' },
  '2': { name: 'Jonnathan Doe', email: 'john@startglobal.com' },
}
type ShareholderKey = keyof typeof shareholders

export const Shareholders = () => {
  const [activeLawyer, setActiveLawyer] = useState('ryan')
  const [agreeToShare, setAgreeToShare] = useState(false)
  return (
    <PageLayout title="Shareholders">
      <Heading size={HeadingSize.lg} style={{ marginBottom: 12, lineHeight: '50px' }}>
        Details about your company shareholders
      </Heading>
      <Body size={BodySize.lg} style={{ lineHeight: '30px', marginBottom: 44 }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        <br />
        tempor incididunt ut labore et dolore.
      </Body>
      <ShareHoldersWrapper>
        <div>
          <>
            {(Object.keys(shareholders) as ShareholderKey[]).map(key => (
              <CollapsableCard
                key={key}
                title={shareholders[key].name}
                icon={IconType.profileLarge}
                onDelete={() => {}}
              >
                <ShareholderForm />
              </CollapsableCard>
            ))}
          </>
          <AddButton>
            <Icon type={IconType.addLarge} style={{ marginRight: 16 }} /> Add shareholder
          </AddButton>
          <Body bold dark style={{ fontSize: 18, marginBottom: 9 }}>
            Two ways to get your post-incorporation documents done.
          </Body>
          <Body>
            You can either choose a standard free option where we have all standard clauses
            integrated with your preferences or you can choose an experienced attorney below to get
            a custom document done.
          </Body>
        </div>
        <SideInfoBox>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
            <Icon type={IconType.infoClip} />
            <Body bold color="var(--primary)" style={{ fontSize: 18, marginLeft: 12 }}>
              Notes
            </Body>
          </div>

          <Body bold dark>
            Cliff and vesting period
          </Body>
          <Body style={{ marginTop: 4, marginBottom: 12 }}>
            Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore.
          </Body>
          <Body
            bold
            size={BodySize.sm}
            color="var(--primary)"
            style={{ marginBottom: 24, cursor: 'pointer' }}
          >
            READ MORE
          </Body>

          <Body bold dark>
            Vesting dates
          </Body>
          <Body style={{ marginTop: 4, marginBottom: 12 }}>
            Ipsum is a dummy text for printing and typesetting industry. This is a dummy description
            for vesting dates.
          </Body>
          <Body
            bold
            size={BodySize.sm}
            color="var(--primary)"
            style={{ marginBottom: 24, cursor: 'pointer' }}
          >
            READ MORE
          </Body>

          <Body bold dark>
            Single and double trigger
          </Body>
          <Body style={{ marginTop: 4, marginBottom: 12 }}>
            Dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
          </Body>
          <Body bold size={BodySize.sm} color="var(--primary)" style={{ cursor: 'pointer' }}>
            READ MORE
          </Body>
        </SideInfoBox>
      </ShareHoldersWrapper>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: 32,
          maxWidth: 950,
          marginTop: 40,
        }}
      >
        <LawyerCard
          type="ryan"
          onClick={() => setActiveLawyer('ryan')}
          active={activeLawyer === 'ryan'}
        />
        <LawyerCard
          type="start-global"
          onClick={() => setActiveLawyer('start-global')}
          active={activeLawyer === 'start-global'}
        />
      </div>

      <Body style={{ marginTop: 16, marginBottom: 29 }}>
        You will be redirected to set up a call with Ryan first, then he will prepare your custom
        documents and upload it here for your signatures. Once Ryan prepares the documents, all
        shareholders will receive respective documents in their emails for signature.
      </Body>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 39 }}>
        <Checkbox active={agreeToShare} onClick={() => setAgreeToShare(!agreeToShare)} />
        <Body style={{ marginLeft: 12 }}>I agree to share all my documents with attorney</Body>
      </div>
      <Button style={{ padding: '21px 40px', marginBottom: 85 }}>GET STARTED</Button>
    </PageLayout>
  )
}
