import React, { useState, useEffect, useContext } from 'react'
import styled from '@emotion/styled'
import { PageLayout } from './PageLayout'
import { CardStatus } from './Card'
import { Surface } from './Surface'
import { Button } from './Button'
import { CardBadge } from './CardBadge'
import { Icon, IconType } from './Icon'
import Collapsible from 'react-collapsible'
import { Body, BodySize } from './Typography/Body'
import { OperatingAgreementSideCard } from './OperatingAgreementSideCard'

const CollapseWrapper = styled.div`
    display: flex;
    background: white;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 4%);
    border-radius: 0.5em;
    flex-direction: column;
    margin-bottom: 0.75em;
    cursor:pointer;
`
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1em;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    padding: 1em 1.5em 1em 1.5em;
    align-items:center;
    @media (max-width: 728px) {
        flex-direction: column;
        align-items:flex-start;
        gap:1em;
    }
`
const Title = styled.div`
`
const TitleHeader = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 1.125em;
    line-height: 21px;
    color: #444c66;
`
const TitleDesc = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 17px;
    color: #757a89;
    padding-top:0.5em;
`
const ButtonSpan = styled.div`
    display: flex;
    gap: 1em;
    align-items: center;
    @media (max-width: 728px) {
        width:100%;
        justify-content: space-between;
    }
`
const StatusCard = styled.div`
`
const MemberRow = styled.div`
    display: flex;
    @media (max-width: 728px) {
        width: 100%;
    }
    width: 50%;
    align-items: center;
    align-content: center;
`
const ResendBtn = styled.div`
    margin-left: 1.25em;
    padding: 0.5em 0.625em;
    align-self: center;
    text-transform: uppercase;
    background-color: #357d45;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 0.875em;
    text-align: center;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    color: white;
    cursor:pointer;
    @media (max-width: 728px) {
        width:100%;
        margin-left: 0em;
    }
`
const SignersWrapperExpanded = styled.div`
    display: flex;
    padding: 0.75em 40px 0px 1.5em;
    justify-content: space-between;
    align-items: center;
`
const MembersInfo= styled.div`
    width:60%;
    @media (max-width: 728px) {
        width:70%;
    }
`
const Name= styled.div`
    font-size: 0.8125rem;
    color: #444c66;
    line-height: 15px;
    font-weight: 600;
`
const Email= styled.div`
    font-size: 0.8125rem;
    color: #757a89;
    line-height: 15px;
    font-weight: normal;
    padding-top: 2px;
`
const MemberStatus= styled.div`
    display: flex;
    align-items: center;
    gap: 0.5em;
    @media (max-width: 728px) {
        flex-direction: column;
    }
`
const CollapseLayout = styled.div`
    margin-bottom: 1em;
`
const Signer = styled.div`
    padding: 0px 25px 0px 1.5em;
    font-size: 0.8125rem;
    color: #757a89;
    line-height: 15px;
    letter-spacing: 1px;
`
const ViewBtn = styled.div`
    border: 1px solid #357d45;
    box-sizing: border-box;
    border-radius: 6px;
    color: #357d45;
    padding: 0.5em 1.5em;
    font-style: normal;
    font-weight: bold;
    font-size: 0.75em;
    line-height: 0.875em;
    text-align: center;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    cursor:pointer;
`

interface OperatingAgreementVersionProps {
    items: {
        instance: string
        lastUpdated: string
        status: CardStatus
        pendingFrom: Number
        oaMemberDTOList: Array<{ firstName: string;lastName:string; email: string, status: CardStatus }>
    }
    onClick :(selectedVersion:any) => void
    currentStatus: string;
    openType : number;
    data : any;
    reload : any;
    id:number;
}

export const OperatingAgreementVersion = (props: OperatingAgreementVersionProps) => {
    const [isDropdownOpen, setisDropdownOpen] = useState(false);
    const [index, setIndex] = useState('');
    const [reload, setReload] = useState(true);
    const [viewAgreement, setViewAgreement] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const onDropdownClicked = () => {
        setisDropdownOpen(!isDropdownOpen);
        props.onClick(props.items);
        setIndex(props.items.instance);
    }

    const onClickView = (version:any) =>{
        setViewAgreement(true);
        setSelectedVersion(version);
    }
    const getDisplayableDate = (dateStr : string) => {
        const d = new Date(dateStr); //yyyy-mm-dd
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return months[d.getMonth()] + " " + d.getDate()+", " + d.getFullYear();
      
      }

    return (
        <><CollapseWrapper id={props.items.instance} >
            <HeaderWrapper onClick={onDropdownClicked}>
                <Title>
                    <TitleHeader>Version {props.items.instance}</TitleHeader>
                    {props.items.status === CardStatus.agreementSigned && <TitleDesc>{'Effective ' + getDisplayableDate(props.items.lastUpdated)}</TitleDesc>}
                    {props.items.status === CardStatus.agreementPending && <TitleDesc>{'Pending ' + getDisplayableDate(props.items.lastUpdated)}</TitleDesc>}
                </Title>
                <ButtonSpan>
                    {props.openType === props.id && <ViewBtn onClick={() => onClickView(props.items)}>View</ViewBtn>}
                    <StatusCard>
                        <CardBadge
                            status={props.items.status}
                            style={{ display: 'inline-flex' }}
                            label={props.items.status === CardStatus.agreementSigned ? 'Completed' : 'Pending from' + props.items.pendingFrom + 'member'} />
                    </StatusCard>
                    <Icon type={props.openType === props.id ? IconType.chevronUpLargePrimary : IconType.chevronDownLarge} onClick={onDropdownClicked} />
                </ButtonSpan>
            </HeaderWrapper>
            <Collapsible open={props.openType === props.id} easing="ease" trigger={''} transitionTime={150}>
                <div>
                    <Body
                        size={BodySize.sm}
                        style={{ marginTop: 12, lineHeight: '1.25em', marginBottom: 12 }}
                    >
                        {<CollapseLayout>
                            <Signer>SIGNERS</Signer>
                            {props.items.oaMemberDTOList.map(item => (
                                <><SignersWrapperExpanded>
                                    <MemberRow>
                                        <MembersInfo>
                                            <Name>{item.firstName + ' ' + item.lastName }</Name>
                                            <Email>{item.email}</Email>
                                        </MembersInfo>
                                            <MemberStatus>
                                                {item.status === CardStatus.agreementSigned && <img style={{ width: '1em' }} src="/icons/done.svg" alt="" />}
                                                {item.status === CardStatus.agreementPending && <img style={{ width: '1em' }} src="/icons/sign-pending.svg" alt="" />}
                                                {item.status === CardStatus.agreementSigned && <text style={{ fontSize: '0.8125rem', color: 'var(--text-secondary)', lineHeight: '15px' }}>Signed</text>}
                                                {item.status === CardStatus.agreementPending && <text style={{ fontSize: '0.8125rem', color: 'var(--text-secondary)', lineHeight: '15px' }}>Pending</text>}
                                                {item.status === CardStatus.agreementPending && <ResendBtn>Resend</ResendBtn>}
                                            </MemberStatus>
                                    </MemberRow>
                                </SignersWrapperExpanded>
                                </>
                            ))}
                        </CollapseLayout>}
                    </Body>
                </div>
            </Collapsible>
        </CollapseWrapper><OperatingAgreementSideCard
                open={viewAgreement}
                selectedAgreement={selectedVersion}
                close={() => {
                    setViewAgreement(false)
                    setReload(!reload)
                } }
                editable={false}
                createMode={false} confirmationSuccess={false} /></>
    );
}