import React from 'react'
import styled from '../lib/styled'
import { Surface } from './Surface'
import { Icon, IconType } from './Icon'
import { Heading } from './Typography/Heading'
import { Body } from './Typography/Body'

const Wrapper = styled(Surface)<{ variant?: 'warning' }>`
  padding: 24px;
  background-color: ${props =>
    props.variant === 'warning' ? props.theme.color.warningLight : props.theme.color.primaryLight};
  border: 1px solid
    ${props =>
      props.variant === 'warning' ? props.theme.color.warning : props.theme.color.primary};
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const ContentWrapper = styled.div`
  padding: 33px 51px 8px 51px;
`
const Subtitle = styled(Body)`
  margin-left: 51px;
  margin-top: 2px;
`

interface InfoCardProps {
  title: string
  subtitle?: string
  children?: JSX.Element
  variant?: 'warning'
}

export const InfoCard = (props: InfoCardProps) => (
  <Wrapper variant={props.variant}>
    <TitleWrapper>
      <Icon type={props.variant === 'warning' ? IconType.alertWarning : IconType.alert} />
      <Heading style={{ marginLeft: 19 }}>{props.title}</Heading>
    </TitleWrapper>
    {props.subtitle ? <Subtitle dark>{props.subtitle}</Subtitle> : null}
    {props.children ? <ContentWrapper>{props.children}</ContentWrapper> : null}
  </Wrapper>
)
