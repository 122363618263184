import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { CollapsableCard, CardStatus } from '../components/CollapsableCard'
import { Heading } from '../components/Typography/Heading'
import { Body } from '../components/Typography/Body'
import { IconType } from '../components/Icon'
import { InfoCard } from '../components/InfoCard'
import { Button } from '../components/Button'
import { SignersList } from '../components/SignersList'

const inReview = false
const callAttorney = false
const shareholders = {
  '0': { name: 'Sanjay Nediyara', email: 'sanjay@startglobal.com' },
  '1': { name: 'Sandeep', email: 'sandeep@startglobal.com' },
  '2': { name: 'Jonnathan Doe', email: 'john@startglobal.com' },
}
type ShareholderKey = keyof typeof shareholders
const postIncorporationDocuments = [
  { title: 'Action of Incorporator', signedBy: [], description: '' },
  {
    title: 'Bylaws',
    signedBy: ['0', '2'],
    description:
      'Bylaws document is a lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
  },
  {
    title: 'Initial Board Consent',
    signedBy: ['0', '1', '2'],
    description: '',
  },
  {
    title: 'Indemnification Agreement',
    signedBy: ['0', '1', '2'],
    description: '',
  },
  {
    title: 'Restricted Stock Purchase Agreements',
    signedBy: [],
    description: '',
  },
]

export const Documents = () => (
  <PageLayout title="Documents">
    <Heading>Incorporation</Heading>
    <Body style={{ marginTop: 12, marginBottom: 24 }}>
      Documents related to your company incorporation
    </Body>
    <CollapsableCard title="Certificate of Incorporation" icon={IconType.file} />
    <CollapsableCard title="EIN" icon={IconType.file} />

    <Heading>Post-incorporation</Heading>
    <Body style={{ marginTop: 12, marginBottom: 24 }}>
      Documents generated after the company incorporation
    </Body>
    <>
      {callAttorney ? (
        <InfoCard
          title="Book a call with attorney"
          subtitle="Please schedule a call with attorney to proceed to the next steps."
          variant="warning"
        >
          <Button variant="warning">BOOK A CALL WITH RYAN</Button>
        </InfoCard>
      ) : null}
      {inReview ? (
        <InfoCard
          title="Review all documents carefully before sending to shareholders"
          subtitle="Please go through all the documents before sending out to your shareholders."
          variant="warning"
        >
          <Button variant="warning">SEND FOR SIGNATURE</Button>
        </InfoCard>
      ) : null}
      {postIncorporationDocuments.map(({ title, description, signedBy }) => {
        let status
        let statusLabel
        const notSigned = [] as ShareholderKey[]
        const signers = (Object.keys(shareholders) as ShareholderKey[]).map(key => {
          const shareholder = shareholders[key]
          const signed = signedBy.indexOf(key) !== -1
          if (!signed) {
            notSigned.push(key)
          }
          return {
            name: shareholder.name,
            email: shareholder.email,
            active: signed,
          }
        })
        if (!inReview && !callAttorney) {
          if (notSigned.length === 0) {
            status = CardStatus.success
          } else if (notSigned.length === 1) {
            status = CardStatus.pending
            statusLabel = `Pending : ${shareholders[notSigned[0]].email}`
          } else {
            status = CardStatus.pending
            statusLabel = `Pending from ${notSigned.length} shareholders`
          }
        }
        return (
          <CollapsableCard
            key={title}
            title={title}
            icon={IconType.file}
            disabled={callAttorney}
            status={status}
            badgeLabel={statusLabel}
            description={description}
          >
            <SignersList title={title} signers={signers} completed={notSigned.length === 0} />
          </CollapsableCard>
        )
      })}
    </>
  </PageLayout>
)
