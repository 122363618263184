
import React from 'react'
import styled  from '@emotion/styled'
import { Button } from './Button'
import { Body } from './Typography/Body'
import { Surface } from './Surface'
import MediaQuery from "react-responsive";

/**
 * Many of our screens have lists, which is okay for a desktop screen
 * But for small screens, we have to do something.
 * This is our first attempt at that.
 * 
 * The only input expected is a Array of rows.
 * Each row is another List of fields.
 * Each field can be of any type CardListItemType
 * 
 */
interface  CardItemProps {
    name? : string,
    id?: string,
    displayName : string,
    type : CardListItemType,
    value : string,
    onClick? : (_:any) => void,
    index : number
    disabled? : boolean
}

export interface CardProps {
    row : CardItemProps[]
}

export enum CardListItemType {
    TEXT,
    BUTTON
}

export interface CardListProps {
    rows : CardProps[]
}
const Surfacebox = styled(Surface)`
  margin: 0;
  //width: 100%;
  //max-width: 50%;
  position: relative;
  height: 412px;
  overflow: auto;
  margin-bottom: 32px;
  ::after {
    position: absolute;
    content: '';
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    width: 100%;
    height: 53px;
    bottom: 0;
    left: 0;
    border-radius: 12px;
    opacity: 0.6399999856948853;
  }
`

const CardItem = styled.div`
  border: 1px solid var(--default-border);
  border-radius: 6px;
  padding: 0.5rem;
  //padding: 12px 20px 16px 12px;
  display: flex;
  margin-bottom: 15px;
  //align-items: center;
  //justify-content: space-between;
`

const Box = styled.div`
  //padding: 24px 24px 8px 24px;
  padding: 1rem;
  overflow: auto;
  //height: calc(100% - 32px);
  padding: 0rem;
`
const CardContent = styled.div`
  //padding-left: 16px;
  width:100%;
  height: fit-content;
  button {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 0 17px;
    margin-top: 10px;
    border-radius: 3px;
    div {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1.13px;
    }
    @media (max-width: 768px){
      padding: 0rem;
      margin: 0rem;
  }
  }
`

const DetailCardItemWrapper = styled(Body)`
  width: 100%;
  line-height: 26px;
  display: flex;
  //justify-content: space-between;

`

const ButtonWrapper = styled.div`
  
  @media (max-width: 768px){
    display: flex;
  align-items: center;
    justify-content: center;
    width: 100%;
    margin:0.6rem;
  }

`

const CardButton = styled(Button)`
  @media (max-width: 768px){
    min-width: 50%;
  }
`
const DetailCardItemRowWrapper = styled.div<{
  index: number
}>`
  width: 100%;
  line-height: 26px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.index %2 ===0 ? '#f7faff' : '#FFF'};
  @media (max-width: 768px){
    justify-content : flex-start;
    & > div {
      padding-left: 0.4rem;
    }
  }

`

const DetailCardItemHeader = styled.div`
    color:"#8f8f8f";
    padding-left: 1rem;

`
const DetailCardItemValue = styled.div`
    color:"##34495e";
    padding-right: 1rem;
`

interface DetailCardItemProps {
  displayName : string,
  value : string
  index :number
}
const DetailCardItem = (props: DetailCardItemProps) => {
  return <DetailCardItemRowWrapper index={props.index}>
          <DetailCardItemHeader>{props.displayName}</DetailCardItemHeader>
          <DetailCardItemValue>{props.value}</DetailCardItemValue></DetailCardItemRowWrapper>;
}


const CardDetail = (props : CardItemProps ) => {
    
    if(props.type === CardListItemType.BUTTON) {
        return <ButtonWrapper>
          <CardButton disabled={props.disabled} onClick={() => props?.onClick? props.onClick({...props}) : void(0) }>{props.displayName}</CardButton>
          </ButtonWrapper>
    } else if(props.type === CardListItemType.TEXT) {
        return <DetailCardItem displayName={props.displayName}  value={props.value} index={props.index}/>
    }
    return null;
}
const Card = (props: CardProps) => {
    return <CardItem><CardContent>
        {props.row.map( (item, index) => {
        return <DetailCardItemWrapper><CardDetail {...item} index={index} /></DetailCardItemWrapper>
        })}
        </CardContent></CardItem>
}

 export const CardList = (props: CardListProps) => {
    return <Surfacebox>
      <Box>
      {props?.rows?.map( (item : CardProps) => <Card  row={item.row}/>) }
      </Box></Surfacebox>
  }

  /**
   * Sample Code at the callers side
  const PaymentsTableSM = (props: PaymentsTableProps) => {
  let rows: any = props?.items?.map(item => {
    let row = [];
    row.push({
      name : 'invoiceNumber',
      displayName : 'Invoice No.',
      value : item.invoiceNumber,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'issuedOn',
      displayName : 'Issued On',
      value : item.issuedOn,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'client',
      displayName : 'Client: ',
      value : item.client,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'amount',
      displayName : 'Amount: ',
      value : `$${item.items
        .reduce((total, i) => (total += (i.price || 0) * (i.quantity || 0)), 0)
      .toFixed(2)}`,
      type : CardListItemType.TEXT
    });
    row.push({
      name : 'status',
      displayName : '',
      value : item.status,
      type : CardListItemType.TEXT
    })
    return {row};
  });
  return <CardList rows={rows}/>
}
   */