import React from 'react'
import styled, { Theme } from '../lib/styled'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body } from './Typography/Body'
import { CardBadge } from './CardBadge'
import { CardStatus } from './Card'

const borderColor = (theme: Theme, status?: CardStatus) => {
  switch (status) {
    case CardStatus.success:
      return theme.color.secondaryBorder
    case CardStatus.failure:
      return theme.color.errorBorder
    default:
      return theme.color.defaultBorder
  }
}

const Wrapper = styled.div<{ status?: CardStatus; noBottomSpace?: boolean }>`
  border: 1px solid;
  border-color: ${props => borderColor(props.theme, props.status)};
  border-radius: 6px;
  box-shadow: ${props => props.theme.shadow.default};
  padding: 16px;
  ${props => (props.noBottomSpace ? '' : 'margin-bottom: 16px;')}
`
const TopWrapper = styled.div<{ alignCenter: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: ${props => (props.alignCenter ? 'center' : 'flex-start')};
  justify-content: space-between;
`
const CardItemDescription = styled(Body)<{ noBottomSpace: boolean }>`
  white-space: pre-line;
  line-height: 24px;
  ${props => (props.noBottomSpace ? '' : 'margin-bottom: 24px;')}
`

interface CardItemProps {
  children?: JSX.Element
  title: string
  description?: string
  status?: CardStatus
  noBottomSpace?: boolean
  badgeLabel?: string
}

export const CardItem = (props: CardItemProps) => (
  <Wrapper status={props.status} noBottomSpace={props.noBottomSpace}>
    <TopWrapper alignCenter={!props.description && !props.children}>
      <div>
        <Heading size={HeadingSize.sm} style={props.description ? { marginBottom: 8 } : undefined}>
          {props.title}
        </Heading>
        {props.description ? (
          <CardItemDescription noBottomSpace={!props.children}>
            {props.description}
          </CardItemDescription>
        ) : null}
      </div>
      {props.status ? <CardBadge status={props.status} label={props.badgeLabel} /> : null}
    </TopWrapper>
    {props.children}
  </Wrapper>
)
