import React, { useState } from 'react'
import * as CRS from 'react-country-region-selector'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Form } from '../Form'
import { Input } from '../Input'
import { InputSelect } from '../InputSelect'
import { Button } from '../Button'

const countryRegionData = (CRS as any).CountryRegionData as Array<string[]>
const countryDataOptions = countryRegionData.map(data => ({ label: data[0], value: data[1] }))
const countryRegionDataMap = countryRegionData.reduce((obj, data) => {
  obj[data[1]] = data[2].split('|').map(region => {
    const regionData = region.split('~')
    return {
      label: regionData[0],
      value: regionData[1],
    }
  })
  return obj
}, {} as any)

export type FormData = {
  firstName: string
  lastName: string
  email: string
  address: string
  country: string
  state: string
  zipCode: string
}

export const CreateClientForm = (
  props: FormProps<FormData> & { onCancel?: () => void; noActions?: boolean }
) => {
  const { register, errors, onSubmit, watch, setValue } = useGetFormHook(props)
  const [regionOptions, setRegionOptions] = useState([])

  return (
    <Form onSubmit={onSubmit} hideSubmit>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            name="firstName"
            label="First name"
            error={errors.firstName && errors.firstName.message}
            topSpace
            rightSpace
            fullWidth
            formStyle
            ref={register({ required: 'Required' })}
          />
          <Input
            name="lastName"
            label="Last name"
            error={errors.lastName && errors.lastName.message}
            topSpace
            leftSpace
            fullWidth
            formStyle
            ref={register({ required: 'Required' })}
          />
        </div>
        <Input
          name="email"
          label="Email address"
          error={errors.email && errors.email.message}
          topSpace
          bottomSpace
          fullWidth
          formStyle
          ref={register({ required: 'Required' })}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            name="address"
            label="Stree address"
            error={errors.address && errors.address.message}
            bottomSpace
            rightSpace
            fullWidth
            formStyle
            ref={register({ required: 'Required' })}
          />
          <InputSelect
            name="state"
            label="State"
            placeholder="State"
            value={watch('state')}
            error={errors.state && errors.state.message}
            leftSpace
            bottomSpace
            fullWidth
            formStyle
            onSelectOption={({ label }) => setValue('state', label)}
            disabled={!regionOptions.length}
            options={regionOptions}
            ref={register({ required: 'Required' })}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputSelect
            name="country"
            label="Country"
            placeholder="Country"
            value={watch('country')}
            error={errors.country && errors.country.message}
            rightSpace
            bottomSpace
            fullWidth
            formStyle
            onSelectOption={({ label, value }) => {
              setValue('country', label)
              setRegionOptions(countryRegionDataMap[value])
            }}
            options={countryDataOptions}
            ref={register({ required: 'Required' })}
          />
          <Input
            name="zipCode"
            label="Zip Code"
            error={errors.zipCode && errors.zipCode.message}
            bottomSpace
            leftSpace
            fullWidth
            formStyle
            ref={register({ required: 'Required' })}
          />
        </div>
        {props.noActions ? null : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button light style={{ marginRight: 16 }} type="button" onClick={props.onCancel}>
              CANCEL
            </Button>
            <Button>CREATE CLIENT</Button>
          </div>
        )}
      </div>
    </Form>
  )
}
