import React, { useState } from 'react'
import styled from '../lib/styled'
import { Surface } from '../components/Surface'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { Icon, IconType } from '../components/Icon'
import { TextButton } from '../components/TextButton'
import { TaxSideCard } from '../components/TaxSideCard'
import { Button } from '../components/Button'

const StripeBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #d4ddeb;
  border-radius: 12px;
  height: 91px;
`
const StripeWrapper = styled.div`
  margin-right: 24px;
`
const StripeWrapperBlock = styled.div``
const ListWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 22px;
`
const WrapperList = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
const Text = styled(Body)`
  margin-left: 16px;
  margin-top: 2px;
  color: ${props => props.theme.color.text.info};
`
const BaseContext = styled.div`
  background-color: #ffeded;
  border-radius: 16px;
  color: #f02f2f;
  font-size: 16px;
  line-height: 19px;
  padding: 7px 12px;
  font-weight: 600;
  display: inline-block;
`
const ButtonWrapper = styled.div`
  margin-top: 32px;
  button {
    display: block;
    width: 100%;
    text-transform: uppercase;
    height: 56px;
  }
`

interface TaxCardItemProps {
  icon: IconType
  title: string
  subtitle: string
  text: string
  options: string[]
  dueDate: string
}

export const TaxCardItem = (props: TaxCardItemProps) => {
  const [newInvoiceOpen, setNewInvoiceOpen] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  return (
    <Surface style={{ padding: '24px', marginBottom: 0 }}>
      <StripeBox>
        <StripeWrapper>
          <Icon type={props.icon} />
        </StripeWrapper>
        <StripeWrapperBlock>
          <Heading
            size={HeadingSize.sm}
            style={{ letterSpacing: '1.33px', lineHeight: '19px', textTransform: 'uppercase' }}
          >
            {props.title}
          </Heading>
          <Body size={BodySize.sm} style={{ marginTop: 8 }}>
            {props.subtitle}
          </Body>
        </StripeWrapperBlock>
      </StripeBox>
      <Body size={BodySize.md} style={{ marginTop: 16, lineHeight: '22px' }}>
        {props.text}
      </Body>
      <TextButton style={{ fontSize: 12, marginTop: 12, letterSpacing: 1 }}>LEARN MORE</TextButton>
      <ListWrapper>
        {props.options &&
          props.options.map((option, index) => {
            return (
              <WrapperList key={`options_${index}`}>
                <Icon type={IconType.listIcon} />
                <Text>{option}</Text>
              </WrapperList>
            )
          })}
      </ListWrapper>
      <BaseContext>Due on {props.dueDate}</BaseContext>
      <ButtonWrapper>
        <Button onClick={() => setNewInvoiceOpen(true)}>Pay state tax</Button>
        <TaxSideCard
          open={newInvoiceOpen}
          selectedInvoice={selectedInvoice}
          onCreateClient={() => {}}
          onPreview={() => {}}
          close={() => {
            setNewInvoiceOpen(false)
            setSelectedInvoice(null)
          }}
        />
      </ButtonWrapper>
    </Surface>
  )
}
