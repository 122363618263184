import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from '../lib/styled'
import { PageLayout } from '../components/PageLayout'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'
import { Icon, IconType } from '../components/Icon'
import { Button } from '../components/Button'
import { ShareForm, FormData } from '../components/Forms/ShareForm'
import { ShareLinkModal } from '../components/ShareLinkModal'

const SHARE_LINKS = [
  {
    docs: ['Bylaws', 'Actions of Incorporator', 'Indemnification agreement'],
    date: 'Feb 27, 2020',
  },
  {
    docs: [
      'Indemnification agreement',
      'Initial Board Consent',
      'Actions of Incorporator',
      'Employee Handbook',
      'Certificate of Incorporation',
      'Bylaws',
      'EIN',
      'Restricted Stock Purchase Agreements',
    ],
    date: 'Feb 19, 2020',
  },
  {
    docs: ['Bylaws', 'Actions of Incorporator', 'Indemnification agreement'],
    date: 'Feb 27, 2020',
  },
]

const FormWrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 6px;
  box-shadow: ${props => props.theme.shadow.default};
`
const SubmitWrapper = styled.div`
  @media(min-width:728px){
    width: 230px;
    min-width: 230px;
    margin-left: 24px;
  }
  
  padding: 24px;
  background-color: ${props => props.theme.color.primaryLight};
  border: 1px solid ${props => props.theme.color.defaultBorder};
  border-radius: 6px;
  box-shadow: ${props => props.theme.shadow.default};
`
const Tag = styled.div`
  border-radius: 13px;
  padding: 7px 12px;
  background-color: ${props => props.theme.color.primaryLight};
  box-shadow: ${props => props.theme.shadow.default};
  color: ${props => props.theme.color.text.secondary};
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
  white-space: nowrap;
`
const TagListWrapper = styled.div`
  overflow: scroll;
  height: 28px;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: linear-gradient(to left, #fff 80%, transparent 100%);
  position: absolute;
  right: 0;
`
const Surface = styled.div`
  margin-bottom: 32px;
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.default};
  box-sizing: border-box;
  @media(max-width:728px){
    flex-direction:column;
    gap:2em;
  }
`

export const Share = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const formHook = useForm<FormData>()
  const onSubmit = formHook.handleSubmit(values => {
    console.log('VALUES', values)
    setModalOpen(true)
  })
  const { watch } = formHook
  const formValues = watch()
  const activeAmount = Object.keys(formValues).filter(key => !!(formValues as any)[key]).length
  return (
    <PageLayout title="Shareholders">
      <Heading size={HeadingSize.lg} style={{ marginBottom: 12, lineHeight: '50px' }}>
        Share your documents with a link
      </Heading>
      <Body size={BodySize.lg} style={{ lineHeight: '30px', marginBottom: 44, maxWidth: 616 }}>
        You can quickly select the documents you want and share it with a link to anyone from this
        page.
      </Body>
      <Surface style={{ padding: 24, display: 'flex', alignItems: 'flex-start', marginBottom: 44 }}>
        <FormWrapper>
          <ShareForm formHookProps={formHook} />
        </FormWrapper>
        <SubmitWrapper>
          <Body dark bold style={{ fontSize: 18, marginBottom: 6, textAlign: 'center' }}>
            Share documents {activeAmount ? `(${activeAmount} items)` : ''}
          </Body>
          <Body style={{ marginBottom: 24 }}>
            Anyone with this link can view or download the documents.
          </Body>
          <Button style={{ width: '100%' }} onClick={onSubmit}>
            GENERATE LINK
          </Button>
        </SubmitWrapper>
      </Surface>
      <Body bold dark style={{ fontSize: 20, marginBottom: 12 }}>
        Previously generated share links
      </Body>
      <Body style={{ marginBottom: 24 }}>
        List of share links you generated before, you can copy these links too.
      </Body>
      <Surface style={{ padding: '8px 0px', display: 'grid' }}>
        {SHARE_LINKS.map((link, i) => (
          <div
            style={{
              padding: '16px 24px',
              overflow: 'hidden',
              borderBottom:
                i === SHARE_LINKS.length - 1 ? undefined : '1px solid var(--default-border)',
            }}
          >
            <Body bold dark>
              {link.docs.length} Documents on {link.date}
            </Body>
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 20, position: 'relative' }}
            >
              <TagListWrapper style={{ marginRight: 120 }}>
                <div style={{ display: 'flex' }}>
                  {link.docs.map(doc => (
                    <Tag>{doc}</Tag>
                  ))}
                </div>
              </TagListWrapper>
              <ActionsWrapper>
                <Body
                  bold
                  color="var(--primary)"
                  style={{ fontSize: 13, whiteSpace: 'nowrap', cursor: 'pointer' }}
                >
                  COPY LINK
                </Body>
                <Icon type={IconType.trash} style={{ marginLeft: 32, cursor: 'pointer' }} />
              </ActionsWrapper>
            </div>
          </div>
        ))}
      </Surface>
      <ShareLinkModal open={modalOpen} close={() => setModalOpen(false)} />
    </PageLayout>
  )
}
