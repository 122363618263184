import React from 'react'
import styled from '../lib/styled'
import { Surface } from './Surface'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { HomeNotification } from './HomeNotification'
import MediaQuery from 'react-responsive'

export enum HeroType {
  start = 'START',
  incorporated = 'INCORPORATED',
}

const HERO_TYPES = {
  [HeroType.start]: {
    image: '/images/hero-start.svg',
    title: 'You are a hero!',
    body: 'People like you build the economy. Your company incorporation process has started.',
  },
  [HeroType.incorporated]: {
    image: '/images/hero-rocket.svg',
    title: 'Your company has Incorporated!',
    body:
      'Lorem ipsum is a dummy text for printing and typesetting industry. This is a dummy description.',
  },
}

const HeroWrapper = styled.div`
  display: flex;
  @media (max-width: 768px){
    flex-direction: column;
    row-gap: 0.75rem;
    margin-bottom: 1rem;
  }
`
const Wrapper = styled(Surface)`
  width: 100%;
  @media (min-width: 768px){
    max-width: 50%;
    margin: 0 0 32px 16px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${props => props.theme.color.primary};
  overflow: hidden;
  height: 412px;
`
const HeroImage = styled.img`
  width: 100%;
  @media (min-width: 768px){
    max-width: 67%;
  }
  margin-bottom: -1px;
`
const HeroHeading = styled(Heading)`
  line-height: 50px;
  margin-bottom: 12px;
`
const HeroBody = styled(Body)`
  opacity: 0.75;
`
const HeroBox = styled.div`
  padding: 40px;
`
const items = [
  {
    lefttitle: 'Sign your SS4 application',
    leftbody: 'You need to sign your SS4 application inorder to proceed with the next steps.',
    images: '/images/error-icon.svg',
    dismiss: false,
    button: 'sign now',
    type: 'error',
  },
  {
    lefttitle: 'Your process has started',
    leftbody: 'This is an informational notification.',
    images: '/images/info-outined.svg',
    dismissTwo: true,
    type: 'primary',
  },
  {
    lefttitle: 'Your debit card has been shipped',
    leftbody: 'You can now track your debit card.',
    images: '/images/credit-card.svg',
    dismissOne: true,
    type: 'primary',
    button: 'track now',
  },
]
export const Hero = (props: { type: HeroType }) => {
  const { image, title, body } = HERO_TYPES[props.type]
  return (
    <HeroWrapper>
      <MediaQuery query="(min-width:728px)">
        <HomeNotification items={items} style={{ width: '50%' }} />
      </MediaQuery>
      <MediaQuery query="(max-width:728px)">
        <HomeNotification items={items} />
      </MediaQuery>
      <Wrapper>
        <HeroBox>
          <HeroHeading light size={HeadingSize.lg}>
            {title}
          </HeroHeading>
          <HeroBody light size={BodySize.md}>
            {body}
          </HeroBody>
        </HeroBox>
        <HeroImage src={image} alt="" />
      </Wrapper>
    </HeroWrapper>
  )
}
