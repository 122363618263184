import React from 'react'
import { useForm } from 'react-hook-form'
import styled from '../lib/styled'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Icon, IconType } from '../components/Icon'
import { Button } from '../components/Button'
import { TextButton } from '../components/TextButton'
import { CardBadge } from '../components/CardBadge'
import { CardStatus } from '../components/Card'
import { CreateInvoiceForm, FormData } from '../components/Forms/CreateInvoiceForm'

const SideCardWrapper = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => `rgba(255,255,255,${props.open ? '0.5' : 0})`};
  ${props => (props.open ? '' : 'pointer-events: none;')};
  transition: background 0.2s ease;
`

const SideCard = styled.div<{ open: boolean }>`
  background-color: ${props => props.theme.color.white};
  box-shadow: -4px -2px 20px 0 rgba(0, 0, 0, 0.06);
  @media (min-width: 728px){
    width: 620px;
  }
  height: 100%;
  float: right;
  transition: transform 0.2s ease;
  transform: translateX(${props => (props.open ? '0px' : '640px')});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const SideCardActions = styled.div`
  display: flex;
  @media (max-width: 768px){
    flex-direction: column;
    align-items: flex-start;
    gap:1em;
  }
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  border-top: 1px solid ${props => props.theme.color.defaultBorder};
`
const SideCardTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  user-select: none;
`
const SideCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.color.defaultBorder};
`
const FormWrapper = styled.div<{ disabled: boolean }>`
  padding: 16px 32px;
  overflow: auto;
  ${props => (props.disabled ? 'opacity: 0.5;' : '')}
`
const ButonWrap =styled.div`
@media (max-width: 768px){
  width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
`

interface PaymentSideCardProps {
  open: boolean
  close: () => void
  selectedInvoice?: any
  onCreateClient: () => void
  onPreview: () => void
}

const PaymentSideCardContent = (props: PaymentSideCardProps) => {
  const formHook = useForm<FormData>({
    defaultValues: {
      email: '',
      project: '',
      isRecurring: false,
      issuedOn: undefined,
      dueOn: undefined,
      notes: '',
      items: [{ title: '', price: undefined, quantity: undefined }],
      ...(props.selectedInvoice || {}),
    },
  })
  const onSubmit = formHook.handleSubmit(values => {
    console.log('VALUES', values)
  })
  return (
    <>
      <FormWrapper disabled={!!props.selectedInvoice}>
        <CreateInvoiceForm
          initialValues={props.selectedInvoice}
          onCreateClient={props.onCreateClient}
          onSubmit={onSubmit}
          formHookProps={formHook}
          disabled={!!props.selectedInvoice}
        />
      </FormWrapper>
      {props.selectedInvoice ? (
        <SideCardActions>
          <TextButton style={{ fontSize: 14 }}>
            <Icon
              type={IconType.download}
              style={{ position: 'relative', marginRight: 12, top: 1 }}
            />
            DOWNLOAD INVOICE
          </TextButton>
          <Button>RESEND COPY TO CLIENT</Button>
        </SideCardActions>
      ) : (
        <SideCardActions>
          <TextButton style={{ fontSize: 14 }} onClick={props.onPreview}>
            PREVIEW
          </TextButton>
          <ButonWrap>
            <Button light style={{ marginRight: 16 }}>
              SAVE AS DRAFT
            </Button>
            <Button onClick={onSubmit}>SEND</Button>
          </ButonWrap>
        </SideCardActions>
      )}
    </>
  )
}

export const PaymentSideCard = (props: PaymentSideCardProps) => {
  const id = '#AL2545'
  return (
    <SideCardWrapper open={props.open} onClick={props.close}>
      <SideCard open={props.open} onClick={e => e.stopPropagation()}>
        <div style={{ padding: 32, paddingBottom: 0 }}>
          <SideCardTop>
            <Heading>Create new invoice</Heading>
            <Icon type={IconType.close} style={{ cursor: 'pointer' }} onClick={props.close} />
          </SideCardTop>
        </div>
        <SideCardHeader>
          {!!props.selectedInvoice ? (
            <div style={{ display: 'flex' }}>
              <Heading size={HeadingSize.lg} style={{ marginRight: 16 }}>
                {props.selectedInvoice.id}
              </Heading>
              <CardBadge
                status={props.selectedInvoice.status}
                style={{ display: 'inline-flex', alignItems: 'center' }}
                label={props.selectedInvoice.status === CardStatus.success ? 'Paid' : undefined}
              />
            </div>
          ) : (
            <>
              <Heading size={HeadingSize.lg}>{props?.selectedInvoice?.id || id}</Heading>
              <TextButton style={{ fontSize: 14 }}>
                <Icon
                  type={IconType.copyLink}
                  style={{ position: 'relative', top: 3, marginRight: 8 }}
                />
                COPY PAYMENT LINK
              </TextButton>
            </>
          )}
        </SideCardHeader>
        {props.open ? <PaymentSideCardContent {...props} /> : null}
      </SideCard>
    </SideCardWrapper>
  )
}
