import React, { useState } from 'react'
import styled, { css } from '../lib/styled'
import { Surface } from './Surface'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Button } from './Button'
import { Icon, IconType } from './Icon'
import { PaymentGateway } from './PaymentGateway'

const Wrapper = styled.div`
  padding-left: 16px;
`
const Surfacebox = styled(Surface)`
  margin: 0 0 32px 0;
  height: fit-content;
  width: 100%;
`
const SurfaceboxSuccess = styled(Surface)`
  margin: 0 0 32px 0;
  height: 566.71px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`
const Box = styled.div`
  padding: 24px;
`
const CardItem = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
`
const ErrorBox = styled.div<{ type?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  border-radius: 16px;
  width: 80px;
  height: 80px;
  ${props =>
    props.type === 'blue' &&
    css`
      background-color: var(--alice-blue);
    `}
  ${props =>
    props.type === 'primary' &&
    css`
      background-color: var(--primary);
    `}
`
const ErrorIcon = styled.img`
  height: 45px;
`
const CardContent = styled.div`
  padding-left: 16px;
  height: fit-content;
`
const CardButton = styled.div`
  button {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 0 17px;
    border-radius: 4px;
    div {
      font-size: 14px;
      line-height: 17px;
    }
  }
`
const BodyStep = styled(Body)`
  line-height: 24px;
  margin-top: 4px;
`
const Ul = styled.div`
  background-color: var(--alice-blue);
  border-radius: 12px;
  padding: 12px 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
const SignedWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  width: 100%;
  max-width: 50%;
`
const EasyBody = styled(Body)`
  font-weight: 700;
  color: var(--independence);
`
const HeadingLink = styled(Heading)`
  color: var(--primary);
  text-transform: uppercase;
`
const HeadingSuccess = styled(Heading)`
  text-align: center;
  margin-bottom: 8px;
`
const BodyRelax = styled(Body)`
  text-align: center;
  width: 100%;
  max-width: 60%;
`
const CornerImage = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
`

interface PaymentsSetupCardProps {
  style?: any
  items: Array<{
    lefttitle?: string
    leftbody?: string
    images?: string
    button?: string
    type?: string
    donetitle?: Array<{}>
  }>
}

export const PaymentsSetupCard = (props: PaymentsSetupCardProps) => {
  const [showData, setShowData] = useState(false)
  return (
    <Wrapper style={props?.style}>
      {!showData ? (
        <Surfacebox>
          <Box>
            {props?.items?.map(item => {
              return (
                <>
                  <CardItem>
                    <ErrorBox type={item?.type}>
                      <ErrorIcon src={item?.images} alt="" />
                    </ErrorBox>
                    <CardContent>
                      <Heading size={HeadingSize.md} color={'var(--independence)'}>
                        {item?.lefttitle}
                      </Heading>
                      <BodyStep size={BodySize.md}>{item?.leftbody}</BodyStep>
                    </CardContent>
                  </CardItem>
                  <Ul>
                    {item?.donetitle?.map(title => (
                      <SignedWrapper>
                        <Icon type={IconType.done} style={{ marginRight: 16 }} />
                        <EasyBody size={BodySize.md}>{title}</EasyBody>
                      </SignedWrapper>
                    ))}
                    <HeadingLink size={HeadingSize.xs}>learn more</HeadingLink>
                  </Ul>
                  <PaymentGateway />
                  <CardButton>
                    {item?.button && (
                      <Button
                        onClick={() => {
                          setShowData(!showData)
                        }}
                      >
                        {item?.button}
                      </Button>
                    )}
                  </CardButton>
                </>
              )
            })}
          </Box>
        </Surfacebox>
      ) : (
        <SurfaceboxSuccess>
          <CornerImage src="/images/purchase-corner.svg" alt="" />
          <Icon type={IconType.done} style={{ marginBottom: 32, width: 50 }} />
          <HeadingSuccess size={HeadingSize.md}>Success</HeadingSuccess>
          <BodyRelax size={BodySize.md}>
            You’ve now subscribed to our Worry-Free Compliance, now sit back and relax.
          </BodyRelax>
        </SurfaceboxSuccess>
      )}
    </Wrapper>
  )
}
