import React from 'react'
import { Body } from '../components/Typography/Body'
import styled from '../lib/styled'
import { Image } from '../components/Icon'
import moment from 'moment'

const LeftBoxBlock = styled.div<{
  avatar?: string
  time?: string
}>`
  display: flex;
  margin-top: 18px;
  padding-left: 32px;
  position: relative;
  ${props => (!props.time || !props.avatar ? 'margin:0px 0 0 0' : '')}
`
const ProfileImage = styled(Image)`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 8px;
  position: absolute;
  top: 0;
  left: 0;
`
const ClientChatBox = styled.div``
const ChatText = styled.div`
  background-color: ${props => props.theme.color.primaryLight};
  border-radius: 8px;
  padding: 7px 12px;
  color: #52649f;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  width: max-content;
  max-width: 320px;
  margin-bottom: 8px;
`
const RightCustomerChatBox = styled.div<{
  time?: string
}>`
  text-align: right;
  margin-bottom: 16px;
  ${props => (!props.time ? 'margin: 0;' : '')}
`
const CustomerChat = styled.div`
  background-color: ${props => props.theme.color.background};
  border-radius: 8px;
  padding: 7px 12px;
  color: #444c66;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  width: fit-content;
  margin-left: auto;
`

const NextDayChat = styled.div`
  color: ${props => props.theme.color.text.secondary};
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  position: relative;
  margin-top: 15px;
  margin-bottom: 25px;
  ::before {
    position: absolute;
    content: '';
    border: 1px solid ${props => props.theme.color.defaultBorder};
    width: 100%;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
  }
`
const NextDate = styled.span`
  z-index: 2;
  position: relative;
  background: ${props => props.theme.color.white};
  padding: 0 8px;
`
interface TaxReplayItemProps {
  item: {
    avatar?: string
    message: string
    time?: string
    isMyMsg: boolean
    date?: Date
  }
}
export const ChatReplayBox = (props: TaxReplayItemProps) => {
  const { item } = props
  return (
    <>
      {!item.isMyMsg ? (
        <LeftBoxBlock time={item.time} avatar={item.avatar}>
          {!!item.avatar && <ProfileImage src={item.avatar} />}
          <ClientChatBox>
            <ChatText>{item.message}</ChatText>
            {!!item.time && (
              <Body style={{ fontSize: 11, opacity: 0.64, marginBottom: '16px' }}>{item.time}</Body>
            )}
          </ClientChatBox>
        </LeftBoxBlock>
      ) : (
        <RightCustomerChatBox time={item.time}>
          <CustomerChat> {item.message}</CustomerChat>
          {!!item.time && <Body style={{ fontSize: 11, opacity: 0.64 }}>{item.time}</Body>}
        </RightCustomerChatBox>
      )}
      {!!item.date && (
        <NextDayChat>
          <NextDate>{moment(item.date).format('DD MMM YYYY')}</NextDate>
        </NextDayChat>
      )}
    </>
  )
}
