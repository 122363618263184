import React from 'react'
import { Controller, Control } from 'react-hook-form'
import styled from '../../lib/styled'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Icon, IconType } from '../Icon'
import { Body } from '../Typography/Body'
import { Form } from '../Form'
import { Checkbox } from '../Checkbox'

const FormItemWrapper = styled.div<{ active: boolean }>`
  display: flex;
  padding: 10px 16px;
  ${props => (props.active ? `background-color: ${props.theme.color.primaryLight};` : undefined)}
`
const Label = styled(Body)`
  font-size: 18px;
  margin-left: 16px;
`

export type FormData = {
  certificateOfIncorporation: boolean
  ein: boolean
  actionOfIncorporator: boolean
  bylaws: boolean
  boardConsent: boolean
  indemnification: boolean
  restrictedStockPurchase: boolean
  stockIssuance: boolean
  efInstructions: boolean
  agreements: boolean
}

const FormItem = (props: {
  id: string
  label: string
  control: Control<FormData>
  watch: any
  style?: any
}) => {
  const active = props.watch(props.id)
  return (
    <FormItemWrapper active={active} style={props.style}>
      <Controller
        as={Checkbox}
        name={props.id}
        control={props.control}
        valueName="active"
        defaultValue={false}
        onChangeName="onClick"
        onChange={() => !active}
      />
      <Label dark color={active ? 'var(--primary)' : undefined}>
        {props.label}
      </Label>
    </FormItemWrapper>
  )
}

export const ShareForm = (props: FormProps<FormData>) => {
  const { control, watch, onSubmit } = useGetFormHook(props)
  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <div style={{ display: 'flex', padding: '24px 16px' }}>
        <Icon type={IconType.file} />
        <Label dark bold>
          Incorporation documents
        </Label>
      </div>
      <FormItem
        id="certificateOfIncorporation"
        label="Certificate of Incorporation"
        control={control}
        watch={watch}
      />
      <FormItem id="ein" label="EIN" control={control} watch={watch} />
      <div
        style={{
          display: 'flex',
          padding: '24px 16px',
          borderTop: '1px solid var(--default-border)',
          marginTop: 14,
        }}
      >
        <Icon type={IconType.file} />
        <Label dark bold>
          Post-incorporation documents
        </Label>
      </div>
      <FormItem
        id="actionOfIncorporator"
        label="Action of Incorporator"
        control={control}
        watch={watch}
      />
      <FormItem id="bylaws" label="Bylaws" control={control} watch={watch} />
      <FormItem id="boardConsent" label="Initial Board Consent" control={control} watch={watch} />
      <FormItem
        id="indemnification"
        label="Indemnification agreement"
        control={control}
        watch={watch}
      />
      <FormItem
        id="restrictedStockPurchase"
        label="Restricted Stock Purchase Agreements"
        control={control}
        watch={watch}
      />
      <FormItem
        id="stockIssuance"
        label="Notices of Stock Issuance"
        control={control}
        watch={watch}
      />
      <FormItem
        id="efInstructions"
        label="Pre-filled 83(b) Election and Filing Instructions"
        control={control}
        watch={watch}
      />
      <FormItem
        id="agreements"
        label="Confidential Information & Invention Assignment Agreements"
        control={control}
        watch={watch}
        style={{ marginBottom: 14 }}
      />
    </Form>
  )
}
