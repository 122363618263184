import React from 'react'
import styled from '../lib/styled'
import { PageLayout } from '../components/PageLayout'
import { Surface } from '../components/Surface'
import { Button } from '../components/Button'
import { Heading, HeadingSize } from '../components/Typography/Heading'
import { Body, BodySize } from '../components/Typography/Body'

const Description = styled(Body)`
  margin-top: 12px;
  margin-bottom: 32px;
  max-width: 650px;
`
const DetailsGrid = styled.div<{ last?: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 24px;
  border: 1px solid ${props => props.theme.color.defaultBorder};
  box-shadow: ${props => props.theme.shadow.default};
  border-radius: 6px;
  padding: 16px;
  ${props => (props.last ? '' : 'margin-bottom: 24px;')}
`
const DetailItem = (props: { title: string; description: string; style?: any }) => (
  <div style={props.style}>
    <Body bold dark style={{ marginBottom: 2, lineHeight: '24px' }}>
      {props.title}
    </Body>
    <Body style={{ lineHeight: '24px' }}>{props.description}</Body>
  </div>
)

export const ConfirmDetails = () => (
  <PageLayout title="Home">
    <Heading size={HeadingSize.lg}>Confirm your company details</Heading>
    <Description size={BodySize.lg}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore.
    </Description>
    <Surface style={{ padding: 24 }}>
      <DetailsGrid>
        <DetailItem title="State" description="Delaware" />
        <DetailItem title="Type of Corporation" description="S-Corporation" />
        <DetailItem title="No: of shares" description="10,000" />
        <DetailItem title="Per Value" description="$25" />
        <DetailItem
          title="Company Name"
          description="ABC Programs Inc."
          style={{ gridColumn: '1 / span 2' }}
        />
        <DetailItem
          title="Description"
          description="Lorem ipsum is a dummy text for printing and typesetting industry. This is a sample description."
          style={{ gridColumn: '1 / span 2' }}
        />
      </DetailsGrid>
      <DetailsGrid>
        <DetailItem
          title="Board of Directors"
          description="Jonnathan Doe, Alexander Paulson"
          style={{ gridColumn: '1 / span 4' }}
        />
        <DetailItem title="Chief Executive Officer" description="Jonnathan Doe" />
        <DetailItem title="President" description="Alexander Paulson" />
        <DetailItem title="Chief Financial Officer" description="Harvy Spector" />
        <DetailItem title="Secretary" description="Thara Thompson" />
      </DetailsGrid>
      <DetailsGrid last>
        <DetailItem title="Your name" description="Jonnathan Doe" />
        <DetailItem title="Phone" description="+91 8888888888" />
        <DetailItem title="SSN number" description="888 88 88888" />
        <DetailItem title="Country" description="India" style={{ gridColumn: '1' }} />
        <DetailItem title="State" description="Kerala" />
        <DetailItem title="State" description="Kerala" />
        <DetailItem title="City" description="Ernakulam" />
        <DetailItem title="Zip code" description="888888" />
        <DetailItem
          title="Street address"
          description="Lorem ipsum street, #56, Vansk"
          style={{ gridColumn: '1 / span 2' }}
        />
      </DetailsGrid>
    </Surface>
    <div style={{ textAlign: 'right', marginBottom: 55 }}>
      <Button light style={{ marginRight: 32 }}>
        EDIT DETAILS
      </Button>
      <Button>
        CONTINUE
        <img src="/icons/arrow-right.svg" alt="arrow" style={{ marginLeft: 13 }} />
      </Button>
    </div>
  </PageLayout>
)
