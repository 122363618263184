import React from 'react'
import styled from '../lib/styled'

const Wrapper = styled.div<{ selected: boolean }>`
  background-color: ${props => props.theme.color.white};
  border: 1px solid
    ${props => (props.selected ? props.theme.color.primary : props.theme.color.text.secondary)};
  border-radius: 100%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const RadioSelectedInner = styled.div`
  background-color: ${props => props.theme.color.primary};
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 100%;
  width: 10px;
  height: 10px;
`

export const RadioButton = (props: { selected: boolean }) => (
  <Wrapper selected={props.selected}>{props.selected ? <RadioSelectedInner /> : null}</Wrapper>
)
