import React, { useState } from 'react'
import * as CRS from 'react-country-region-selector'
import { useGetFormHook, FormProps } from '../../lib/form'
import { Form } from '../Form'
import { Input } from '../Input'
import { InputSelect } from '../InputSelect'
import { InfoBox } from '../InfoBox'

export type FormData = {
  country: string
  state: string
  address: string
  city: string
  code: string
}

const countryRegionData = (CRS as any).CountryRegionData as Array<string[]>
const countryDataOptions = countryRegionData.map(data => ({ label: data[0], value: data[1] }))
const countryRegionDataMap = countryRegionData.reduce((obj, data) => {
  obj[data[1]] = data[2].split('|').map(region => {
    const regionData = region.split('~')
    return {
      label: regionData[0],
      value: regionData[1],
    }
  })
  return obj
}, {} as any)

export const MailingAddressForm = (props: FormProps<FormData>) => {
  const { register, errors, watch, setValue, onSubmit } = useGetFormHook(props)
  const [regionOptions, setRegionOptions] = useState([])

  return (
    <Form onSubmit={onSubmit} hideSubmit={!!props.formHookProps}>
      <div style={{ display: 'flex', width: '100%' }}>
        <InputSelect
          name="country"
          label="Country"
          placeholder="Country"
          value={watch('country')}
          error={errors.country && errors.country.message}
          rightSpace
          bottomSpace
          fullWidth
          formStyle
          onSelectOption={({ label, value }) => {
            setValue('country', label)
            setRegionOptions(countryRegionDataMap[value])
          }}
          options={countryDataOptions}
          ref={register({ required: 'Required' })}
        />
        <InputSelect
          name="state"
          label="State"
          placeholder="State"
          value={watch('state')}
          error={errors.state && errors.state.message}
          leftSpace
          bottomSpace
          fullWidth
          formStyle
          onSelectOption={({ label }) => setValue('state', label)}
          disabled={!regionOptions.length}
          options={regionOptions}
          ref={register({ required: 'Required' })}
        />
      </div>
      <Input
        name="address"
        label="Street Address"
        error={errors.address && errors.address.message}
        fullWidth
        bottomSpace
        formStyle
        ref={register({ required: 'Required' })}
      />
      <div style={{ display: 'flex', width: '100%' }}>
        <Input
          name="city"
          label="City"
          error={errors.city && errors.city.message}
          fullWidth
          rightSpace
          bottomSpace
          formStyle
          ref={register({ required: 'Required' })}
        />
        <Input
          name="code"
          label="Zip Code"
          error={errors.code && errors.code.message}
          fullWidth
          leftSpace
          bottomSpace
          formStyle
          ref={register({ required: 'Required' })}
        />
      </div>
      <InfoBox>You can enter any address you want and this doesn’t have to be in US.</InfoBox>
    </Form>
  )
}
