import React from 'react'
import styled from '../lib/styled'
import { Heading } from '../components/Typography/Heading'
import { Icon, IconType } from '../components/Icon'
import { ReportableTransactionForm } from './Forms/ReportableTransactionForm'

const SideCardWrapper = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => `rgba(255,255,255,${props.open ? '0.5' : 0})`};
  ${props => (props.open ? '' : 'pointer-events: none;')};
  transition: background 0.2s ease;
`

const SideCard = styled.div<{ open: boolean }>`
  background-color: ${props => props.theme.color.white};
  box-shadow: -4px -2px 20px 0 rgba(0, 0, 0, 0.06);
  @media(min-width:728px){
    width: 620px;
  }
  height: 100%;
  float: right;
  transition: transform 0.2s ease;
  transform: translateX(${props => (props.open ? '0px' : '640px')});
  display: flex;
  flex-direction: column;
`
const SideCardTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  user-select: none;
`

interface TaxSideCardProps {
  open: boolean
  close: () => void
  selectedInvoice?: any
  onCreateClient: () => void
  onPreview: () => void
}

export const TaxSideCard = (props: TaxSideCardProps) => {
  return (
    <SideCardWrapper open={props.open} onClick={props.close}>
      <SideCard open={props.open} onClick={e => e.stopPropagation()}>
        <div style={{ padding: 32, paddingBottom: 0 }}>
          <SideCardTop>
            <Heading>Reportable transaction</Heading>
            <Icon type={IconType.close} style={{ cursor: 'pointer' }} onClick={props.close} />
          </SideCardTop>
        </div>
        <div>
          <ReportableTransactionForm />
        </div>
      </SideCard>
    </SideCardWrapper>
  )
}
