import React from 'react'
import styled, { css } from '../lib/styled'
import { Surface } from './Surface'
import { Heading, HeadingSize } from './Typography/Heading'
import { Body, BodySize } from './Typography/Body'
import { Button } from './Button'

const Wrapper = styled.div`
  @media (min-width: 768px){
    padding-right: 16px;
  }
`
const Surfacebox = styled(Surface)`
  margin: 0;
  position: relative;
  height: 412px;
  overflow: hidden;
  margin-bottom: 32px;
  ::after {
    position: absolute;
    content: '';
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    width: 100%;
    height: 53px;
    bottom: 0;
    left: 0;
    border-radius: 12px;
    opacity: 0.6399999856948853;
  }
`
const Box = styled.div`
  padding: 24px 24px 8px 24px;
  overflow: auto;
  height: calc(100% - 32px);
`
const BodyCenter = styled(Body)`
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.13px;
  line-height: 14px;
  color: var(--text-secondary);
  opacity: 0.72;
  margin-bottom: 16px;
`
const CardItem = styled.div`
  border: 1px solid var(--default-border);
  border-radius: 6px;
  padding: 12px 20px 16px 12px;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`
const ErrorBox = styled.div<{ type?: string }>`
  border-radius: 8px;
  min-width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  ${props =>
    props.type === 'error' &&
    css`
      background-color: var(--error-light);
    `}
  ${props =>
    props.type === 'primary' &&
    css`
      background-color: var(--alice-blue);
    `}
`
const ErrorIcon = styled.img`
  height: 24px;
`
const CardContent = styled.div`
  padding-left: 16px;
  height: fit-content;
  button {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 0 17px;
    margin-top: 10px;
    border-radius: 3px;
    div {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1.13px;
    }
  }
`
const BodyStep = styled(Body)`
  line-height: 20px;
  margin-top: 4px;
`
const Link = styled(Body)`
  margin-left: auto;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
const LinkTwo = styled(Body)`
  margin-top: 10px;
  margin-left: 24px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
`

interface HeroProps {
  style?: any
  items: Array<{
    lefttitle?: string
    leftbody?: string
    images?: string
    button?: string
    dismissTwo?: boolean
    dismissOne?: boolean
    type?: string
  }>
}

export const HomeNotification = (props: HeroProps) => {
  return (
    <Wrapper style={props?.style}>
      <Surfacebox>
        <Box>
          <BodyCenter size={BodySize.xs}>notification center</BodyCenter>
          {props?.items?.map(item => {
            return (
              <>
                <CardItem>
                  <ErrorBox type={item?.type}>
                    <ErrorIcon src={item?.images} alt="" />
                  </ErrorBox>
                  <CardContent>
                    <Heading size={HeadingSize.sm} color={'var(--independence)'}>
                      {item?.lefttitle}
                    </Heading>
                    <BodyStep size={BodySize.sm}>{item?.leftbody}</BodyStep>
                    <ButtonWrapper>
                      {item?.button && <Button>{item?.button}</Button>}
                      {item.dismissOne && <LinkTwo size={BodySize.xs}>Dismiss</LinkTwo>}
                    </ButtonWrapper>
                  </CardContent>
                  {item.dismissTwo && <Link size={BodySize.xs}>Dismiss</Link>}
                </CardItem>
              </>
            )
          })}
        </Box>
      </Surfacebox>
    </Wrapper>
  )
}
