import React, { useEffect, useState } from "react";
import styled from "../lib/styled";

import { PageLayout } from '../components/PageLayout'
import { Heading } from '../components/Typography/Heading'
import { Body } from '../components/Typography/Body'
import { CreateClientForm } from '../components/Forms/CreateClientForm'
import { Icon, IconType } from '../components/Icon'
import { Button } from '../components/Button'
import { ClientsTable } from '../components/ClientsTable'
import { Input } from '../components/Input'
import { Select } from '../components/Select'
import { ClientSideCard } from '../components/ClientSideCard'
import { Surface } from '../components/Surface'

import { Card } from "../components/Card";

interface TaxProps {
}

export const VirtualPhone = (props: TaxProps) => {
  const [number, setNumber] = useState({} as any);

  


  return <PageLayout title="Virtual Phone Details">
    <Surface style={{ borderRadius: 16, position: 'relative' }}>
    


  

    <Card title="Communication" >
      
    </Card>
    </Surface>
  </PageLayout>
}

