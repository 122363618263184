import React from 'react'
import styled from '../../lib/styled'
import { Button } from '../Button'
import { Input } from '../Input'
import { TextArea } from '../TextArea'

const Form = styled.form``
const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: '100%';
  padding: 32px;
`
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`
const InputBlock = styled.div`
  padding-right: 24px;
  width: 100%;
`
const TextAreaWrapper = styled.div`
  textarea {
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    resize: none;
    overflow: auto;
  }
`
const ButtonWrapper = styled.div`
  button {
    width: auto;
    margin-left: auto;
    height: 48px;
  }
`

export const ReportableTransactionForm = () => {
  return (
    <Form>
      <FormDiv>
        <Input label="Loan to business" bottomSpace fullWidth formStyle />
        <Input
          label="License (Registration, Patents, Trademarks etc)"
          bottomSpace
          fullWidth
          formStyle
        />
        <InputWrapper>
          <InputBlock>
            <Input label="Loan from business" bottomSpace fullWidth formStyle />
          </InputBlock>
          <Input label="Rent recieved from business" bottomSpace fullWidth formStyle />
        </InputWrapper>
        <TextAreaWrapper>
          <TextArea name="notes" label="Additional Notes" bottomSpace fullWidth formStyle />
        </TextAreaWrapper>
        <ButtonWrapper>
          <Button>Continue</Button>
        </ButtonWrapper>
      </FormDiv>
    </Form>
  )
}
